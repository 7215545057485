import type { RouterConfig } from "nuxt/schema";
import {createMemoryHistory, createWebHashHistory, createWebHistory} from 'vue-router';
import autoLogin from "~/client/middleware/autoLogin";
import eventPageLayout from "~/client/middleware/eventPageLayout";
import setIntro from "~/client/middleware/setIntro";
import { UserAuthGroup, UserType } from "~/enums";
import { useUserStore } from "~/store/userStore";


export default <RouterConfig>{
  mode:'history',
  history: createWebHistory,
  routes:(_routes) => {
    const userStore = useUserStore();

    const userType = userStore.getUserType;
    const userIsAdmin = userStore.getUserAdmin;
    const userAuthGroup = userStore.getUserAuthType;

    console.log(userStore);

    const baseRoute = [
      {name: 'page', path: '/', component: () => import('@/client/pages/index.vue'), meta:{middleware: [autoLogin]}, children:[
        /** SWAGGER */
        {name: 'swagger', path: 'swagger-ui', component: () => import("@/client/pages/swagger-ui.vue")},
        /** MOBILE */
        {name: 'mobile', path: 'mobile', meta: {layout: 'mobile'}, component: () => import('@/client/pages/mobile.vue'), children: [
          {name: 'mobile-user', path: 'user', meta: {layout: 'non-header-footer'}, component: () => import(`@/client/pages/mobile/user.vue`), children: [
            {name: 'mobile-user-id-index', path: ':id/index', component: () => import('@/client/pages/mobile/user/[id]/index.vue')},
            {name: 'mobile-user-id', path: ':id', component: () => import('@/client/pages/mobile/user/[id].vue'), children: [
              {name: 'mobile-user-id-logout', path: 'logout', component: () => import('@/client/pages/mobile/user/logout.vue')},
              {name: 'mobile-user-id-equipMap', path: 'equipMap',meta:{layout:'non-header-footer'}, component: () => import('@/client/pages/mobile/user/[id]/equipMap.vue')},
              {name: 'mobile-user-id-equipList', path: 'equipList',meta:{layout:'non-header-footer'}, component: () => import('@/client/pages/mobile/user/[id]/equipList.vue')},
              {name: 'mobile-user-id-views', path: 'views', meta: {layout: 'mobile'}, component: () => import('@/client/pages/mobile/user/[id]/views.vue'), children: [
                {name: 'mobile-user-id-views-monitoring', path: 'monitoring', component: () => import('@/client/pages/mobile/user/[id]/views/monitoring.vue'), children: [
                  {name: 'mobile-user-id-views-monitoring-pv', path: 'pv', meta:{keepalive: true}, component: () => import('@/client/pages/mobile/user/[id]/views/monitoring/pv.vue')},
                  {name: 'mobile-user-id-views-monitoring-solarheat', path: 'solarheat', component: () => import('@/client/pages/mobile/user/[id]/views/monitoring/solarheat.vue')},
                  {name: 'mobile-user-id-views-monitoring-geo', path: 'geo', component: () => import('@/client/pages/mobile/user/[id]/views/monitoring/geo.vue')},
                  {name: 'mobile-user-id-views-monitoring-wf', path: 'wf', component: () => import('@/client/pages/mobile/user/[id]/views/monitoring/wf.vue')},
                  {name: 'mobile-user-id-views-monitoring-fuelcell', path: 'fuelcell', component: () => import('@/client/pages/mobile/user/[id]/views/monitoring/fuelcell.vue')},
                  {name: 'mobile-user-id-views-monitoring-ess', path: 'ess', component: () => import('@/client/pages/mobile/user/[id]/views/monitoring/ess.vue')}
                ]},
                {name: 'mobile-user-id-views-equipInfo', path: 'equipInfo', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo.vue'), children: [
                  {name: 'mobile-user-id-views-equipInfo-owner-pv', path: 'owner/pv', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/owner/pv.vue'), children: [
                    {name: 'mobile-user-id-views-equipInfo-owner-pv-instruinfo', path: 'InstruInfo', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstruInfo.vue')},
                    {name: 'mobile-user-id-views-equipInfo-owner-pv-installinfo', path: 'InstallInfo', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstallInfo.vue')}
                  ]},
                  {name: 'mobile-user-id-views-equipInfo-owner-solarheat', path: 'owner/solarheat', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat.vue'), children: [
                    {name: 'mobile-user-id-views-equipInfo-owner-solarheat-instruinfo', path: 'InstruInfo', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo.vue')},
                    {name: 'mobile-user-id-views-equipInfo-owner-solarheat-installinfo', path: 'InstallInfo', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstallInfo.vue')}
                  ]},
                  {name: 'mobile-user-id-views-equipInfo-owner-geo', path: 'owner/geo', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/owner/geo.vue'),children: [
                    {name: 'mobile-user-id-views-equipInfo-owner-geo-instruinfo', path: 'InstruInfo', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstruInfo.vue')},
                    {name: 'mobile-user-id-views-equipInfo-owner-geo-installinfo', path: 'InstallInfo', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstallInfo.vue')}
                  ]},
                  {name: 'mobile-user-id-views-equipInfo-owner-wf', path: 'owner/wf', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/owner/wf.vue')},
                  {name: 'mobile-user-id-views-equipInfo-owner-fuelcell', path: 'owner/fuelcell', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell.vue')},
                  {name: 'mobile-user-id-views-equipInfo-owner-ess', path: 'owner/ess', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/owner/ess.vue')},
                  
                  {name: 'mobile-user-id-views-equipInfo-company-pv', path: 'company/pv', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/company/pv.vue'), children: [
                    {name: 'mobile-user-id-views-equipInfo-company-pv-instruinfo', path: 'InstruInfo', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstruInfo.vue')},
                    {name: 'mobile-user-id-views-equipInfo-company-pv-installinfo', path: 'InstallInfo', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstallInfo.vue')},
                    {name: 'mobile-user-id-views-equipInfo-company-pv-specinfo', path: 'SpecInfo', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/company/pv/SpecInfo.vue')},
                  ]},
                  {name: 'mobile-user-id-views-equipInfo-company-solarheat', path: 'company/solarheat', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat.vue'), children: [
                    {name: 'mobile-user-id-views-equipInfo-company-solarheat-instruinfo', path: 'InstruInfo', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstruInfo.vue')},
                    {name: 'mobile-user-id-views-equipInfo-company-solarheat-specinfo', path: 'SpecInfo', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/SpecInfo.vue')},
                    {name: 'mobile-user-id-views-equipInfo-company-solarheat-installinfo', path: 'InstallInfo', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstallInfo.vue')},
                  ]},
                  {name: 'mobile-user-id-views-equipInfo-company-geo', path: 'company/geo', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/company/geo.vue'),children: [
                    {name: 'mobile-user-id-views-equipInfo-company-geo-instruinfo', path: 'InstruInfo', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstruInfo.vue')},
                    {name: 'mobile-user-id-views-equipInfo-company-geo-installinfo', path: 'InstallInfo', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstallInfo.vue')},
                    {name: 'mobile-user-id-views-equipInfo-company-geo-specinfo', path: 'SpecInfo', component: () => import('@/client/pages/mobile/user/[id]/views/equipInfo/company/geo/SpecInfo.vue')},
                  ]},
                ]},
                {name: 'mobile-user-id-views-powerGeneration', path: 'powerGeneration', component: () => import('@/client/pages/mobile/user/[id]/views/powerGeneration.vue'), children: [
                  {name: 'mobile-user-id-views-powerGeneration-pv', path: 'pv', component: () => import('@/client/pages/mobile/user/[id]/views/powerGeneration/pv.vue')},
                  {name: 'mobile-user-id-views-powerGeneration-solarheat', path: 'solarheat', component: () => import('@/client/pages/mobile/user/[id]/views/powerGeneration/solarheat.vue')},
                  {name: 'mobile-user-id-views-powerGeneration-geo', path: 'geo', component: () => import('@/client/pages/mobile/user/[id]/views/powerGeneration/geo.vue')},
                ]},
                {name: 'mobile-user-id-views-report', path: 'report', component: () => import('@/client/pages/mobile/user/[id]/views/report.vue'), children: [
                  {name: 'mobile-user-id-views-report-pv', path: 'pv', component: () => import('@/client/pages/mobile/user/[id]/views/report/pv.vue')},
                  {name: 'mobile-user-id-views-report-geo', path: 'geo', component: () => import('@/client/pages/mobile/user/[id]/views/report/geo.vue')},
                  {name: 'mobile-user-id-views-report-solarheat', path: 'solarheat', component: () => import('@/client/pages/mobile/user/[id]/views/report/solarheat.vue')},
                ]},
                {name: 'mobile-user-id-views-more', path: 'more', meta:{layout: 'non-header-footer'}, component: () => import('@/client/pages/mobile/user/[id]/views/more.vue'), children: [
                  {name: 'mobile-user-id-views-more-installinfo', path: 'InstallInfo', component: () => import('@/client/pages/mobile/user/[id]/views/more/InstallInfo.vue')},
                  {name: 'mobile-user-id-views-more-menu', path: 'menu', component: () => import('@/client/pages/mobile/user/[id]/views/more/menu.vue')},
                  {name: 'mobile-user-id-views-more-ownerinfo', path: 'ownerInfo', component: () => import('@/client/pages/mobile/user/[id]/views/more/ownerInfo.vue')},
                  {name: 'mobile-user-id-views-more-predictedPower', path: 'predictedPower', component: () => import('@/client/pages/mobile/user/[id]/views/more/predictedPower.vue')},
                  {name: 'mobile-user-id-views-more-menuToNotice-noticeid', path: 'menuToNotice/:noticeid', component: () => import('@/client/pages/mobile/user/[id]/views/more/menuToNotice/[noticeid].vue')},
                  {name: 'mobile-user-id-views-more-installer-confirm', path:'installer-confirm', component: () => import('@/client/pages/mobile/user/[id]/views/more/installerConfirm/index.vue'), children: [
                    {name: 'mobile-user-id-views-more-installer-confirm-list', path:'list', component: () => import('@/client/pages/mobile/user/[id]/views/more/installerConfirm/installerConfirm.vue')},
                    {name: 'mobile-user-id-views-more-installer-confirm-detail', path:'detail', component: () => import('@/client/pages/mobile/user/[id]/views/more/installerConfirm/installerConfirmDetail.vue')}
                  ]}
                ]},
                {name: 'mobile-user-id-views-event', path: 'event', meta:{middleware: [eventPageLayout]}, component: () => import('@/client/pages/mobile/user/[id]/views/event.vue'), children: [
                  {name: 'mobile-user-id-views-event-all', path: 'all', component: () => import('@/client/pages/mobile/user/[id]/views/event/all.vue')},
                  {name: 'mobile-user-id-views-event-rtuevent', path: 'rtuEvent', component: () => import('@/client/pages/mobile/user/[id]/views/event/rtuEvent.vue')},
                  {name: 'mobile-user-id-views-event-eventHistory', path: 'eventHistory', component: () => import('@/client/pages/mobile/user/[id]/views/event/eventHistory.vue')}
                ]},
  
                {name: 'mobile-user-id-views-status', path: 'status', meta:{layout: 'non-header-footer'}, component: () => import('@/client/pages/mobile/user/[id]/views/status.vue')}
              ]},
              {name: 'mobile-user-id-etc', path: 'etc', meta:{layout: 'non-header-footer'}, component: () => import('@/client/pages/mobile/user/[id]/etc.vue'), children: [
                {name: 'mobile-user-id-etc-notice', path: 'notice', component: () => import('@/client/pages/mobile/user/[id]/etc/notice.vue'), children: [
                  {name: 'mobile-user-id-etc-notice-index', path: 'index', meta:{name: '공지사항'}, component: () => import('@/client/pages/mobile/user/[id]/etc/notice/index.vue')},
                  {name: 'mobile-user-id-etc-notice-noticeid', path: ":noticeid", meta:{name: '공지사항'}, component: () => import('@/client/pages/mobile/user/[id]/etc/notice/[noticeid].vue')}
                ]},
                {name: 'mobile-user-id-etc-alarm', path: 'alarm', component: () => import('@/client/pages/mobile/user/[id]/etc/alarm.vue'), children: [
                  {name: 'mobile-user-id-etc-alarm-all', path: 'all', meta:{name:'알람'}, component: () => import('@/client/pages/mobile/user/[id]/etc/alarm/all.vue')},
                  {name: 'mobile-user-id-etc-alarm-alarmRTU', path: 'alarmRTU', meta:{name:'알람'},component: () => import('@/client/pages/mobile/user/[id]/etc/alarm/alarmRTU.vue')},
                  {name: 'mobile-user-id-etc-alarm-alarmETC', path: 'alarmETC', meta:{name:'알람'},component: () => import('@/client/pages/mobile/user/[id]/etc/alarm/alarmETC.vue')}
                ]}
              ]},
              {name: 'mobile-user-id-cs', path: 'cs', meta:{layout: 'non-header-footer'}, component: () => import('@/client/pages/mobile/user/[id]/cs.vue'), children: [
                {name: 'mobile-user-id-main', path: 'main', component: () => import('@/client/pages/mobile/user/[id]/cs/main.vue')},
                {name: 'mobile-user-id-question', path: 'question', component: () => import('@/client/pages/mobile/user/[id]/cs/question.vue')},
                {name: 'mobile-user-id-useTerms', path: 'useTerms', component: () => import('@/client/pages/mobile/user/[id]/cs/useTerms.vue')},
                {name: 'mobile-user-id-personTerms', path: 'personTerms', component: () => import('@/client/pages/mobile/user/[id]/cs/personTerms.vue')},
                {name: 'mobile-user-id-locationTerms', path: 'locationTerms', component: () => import('@/client/pages/mobile/user/[id]/cs/locationTerms.vue')},
                {name: 'mobile-user-id-oldTerms', path: 'oldTerms', component: () => import('@/client/pages/mobile/user/[id]/cs/oldTerms.vue'), children: [
                  {name: 'mobile-user-id-oldTerms-useTerms', path: 'useTerms', component: () => import('@/client/pages/mobile/user/[id]/cs/oldTerms/useTerms.vue'), children: [
                    {name: 'mobile-user-id-oldTerms-useTerms-250314', path: '250314', meta:{timeString: '2023년 12월  1일 ~ 2025년 3월 14일 적용'}, component: () => import('@/client/pages/mobile/user/[id]/cs/oldTerms/useTerms/250314.vue')}
                  ]},
                  {name: 'mobile-user-id-oldTerms-personTerms', path: 'personTerms', component: () => import('@/client/pages/mobile/user/[id]/cs/oldTerms/personTerms.vue'), children: [
                    {name: 'mobile-user-id-oldTerms-personTerms-250314', path: '250314', meta:{timeString: '2023년 12월  1일 ~ 2025년 3월 14일 적용'}, component: () => import('@/client/pages/mobile/user/[id]/cs/oldTerms/personTerms/250314.vue')}
                  ]},
                  {name: 'mobile-user-id-oldTerms-locationTerms', path: 'locationTerms', component: () => import('@/client/pages/mobile/user/[id]/cs/oldTerms/locationTerms.vue'), children: [
                  
                  ]}
                ]},
                {name: 'mobile-user-id-openSource', path: 'openSource', component: () => import('@/client/pages/mobile/user/[id]/cs/openSource.vue')},
                {name: 'mobile-user-id-guide', path: 'guide', children: [
                  {name: 'mobile-user-id-guide-index', path: 'index', component: () => import('@/client/pages/mobile/user/[id]/cs/guide/index.vue')},
                  // owner
                  {name: 'mobile-user-id-guide-owner-1_serviceOverview', path: 'owner/1_serviceOverview', component: () => import('@/client/pages/mobile/user/[id]/cs/guide/owner/1_serviceOverview.vue')},
                  {name: 'mobile-user-id-guide-owner-2_status', path: 'owner/2_status', component: () => import('@/client/pages/mobile/user/[id]/cs/guide/owner/2_status.vue')},
                  {name: 'mobile-user-id-guide-owner-3_monitoring', path: 'owner/3_monitoring', component: () => import('@/client/pages/mobile/user/[id]/cs/guide/owner/3_monitoring.vue')},
                  {name: 'mobile-user-id-guide-owner-4_equipInfo', path: 'owner/4_equipInfo', component: () => import('@/client/pages/mobile/user/[id]/cs/guide/owner/4_equipInfo.vue')},
                  {name: 'mobile-user-id-guide-owner-5_powerGeneration', path: 'owner/5_powerGeneration', component: () => import('@/client/pages/mobile/user/[id]/cs/guide/owner/5_powerGeneration.vue')},
                  {name: 'mobile-user-id-guide-owner-6_report', path: 'owner/6_report', component: () => import('@/client/pages/mobile/user/[id]/cs/guide/owner/6_report.vue')},
                  {name: 'mobile-user-id-guide-owner-7_event', path: 'owner/7_event', component: () => import('@/client/pages/mobile/user/[id]/cs/guide/owner/7_event.vue')},
                  {name: 'mobile-user-id-guide-owner-8_ownerInfo', path: 'owner/8_ownerInfo', component: () => import('@/client/pages/mobile/user/[id]/cs/guide/owner/8_ownerInfo.vue')},
                  {name: 'mobile-user-id-guide-owner-9_question', path: 'owner/9_question', component: () => import('@/client/pages/mobile/user/[id]/cs/guide/owner/9_question.vue')},
                  // company
                  {name: 'mobile-user-id-guide-company-1_serviceOverview', path: 'company/1_serviceOverview', component: () => import('@/client/pages/mobile/user/[id]/cs/guide/company/1_serviceOverview.vue')},
                  {name: 'mobile-user-id-guide-company-2_status', path: 'company/2_status', component: () => import('@/client/pages/mobile/user/[id]/cs/guide/company/2_status.vue')},
                  {name: 'mobile-user-id-guide-company-3_equipSelect', path: 'company/3_equipSelect', component: () => import('@/client/pages/mobile/user/[id]/cs/guide/company/3_equipSelect.vue')},
                  {name: 'mobile-user-id-guide-company-4_instru', path: 'company/4_instru', component: () => import('@/client/pages/mobile/user/[id]/cs/guide/company/4_instru.vue')},
                  {name: 'mobile-user-id-guide-company-5_equipInfo', path: 'company/5_equipInfo', component: () => import('@/client/pages/mobile/user/[id]/cs/guide/company/5_equipInfo.vue')},
                  {name: 'mobile-user-id-guide-company-6_event', path: 'company/6_event', component: () => import('@/client/pages/mobile/user/[id]/cs/guide/company/6_event.vue')},
                  {name: 'mobile-user-id-guide-company-7_powerGeneration', path: 'company/7_powerGeneration', component: () => import('@/client/pages/mobile/user/[id]/cs/guide/company/7_powerGeneration.vue')},
                  {name: 'mobile-user-id-guide-company-8_installInfo', path: 'company/8_installInfo', component: () => import('@/client/pages/mobile/user/[id]/cs/guide/company/8_installInfo.vue')},
                  {name: 'mobile-user-id-guide-company-9_ownerInfo', path: 'company/9_ownerInfo', component: () => import('@/client/pages/mobile/user/[id]/cs/guide/company/9_ownerInfo.vue')},
                  {name: 'mobile-user-id-guide-company-10_question', path: 'company/10_question', component: () => import('@/client/pages/mobile/user/[id]/cs/guide/company/10_question.vue')},
                ]},
              ]},
              {name: 'mobile-user-id-info', path: 'info', meta:{name: '회원정보',layout: 'non-header-footer'}, component: () => import('@/client/pages/mobile/user/[id]/info.vue'), children: [
                {name: 'mobile-user-id-info-main', path: 'main', meta:{name: '회원정보', layout:'non-header-footer'}, component: () => import('@/client/pages/mobile/user/[id]/info/main.vue')},
                {name: 'mobile-user-id-info-pw-change', path: 'pw-change', meta:{name:'비밀번호 변경'}, component: () => import('@/client/pages/mobile/user/[id]/info/pw-change.vue')},
                {name: 'mobile-user-id-info-contact-change', path: 'contact-change', meta:{name:'연락처 변경'},  component: () => import('@/client/pages/mobile/user/[id]/info/contact-change.vue')},
                {name: 'mobile-user-id-info-user-delete', path: 'user-delete',  meta:{name:'회원 탈퇴'}, component: () => import('@/client/pages/mobile/user/[id]/info/user-delete.vue')},
              ]},
              {name: 'mobile-user-id-setting', path: 'setting', meta:{name: '설정', layout: 'non-header-footer'}, component: () => import('@/client/pages/mobile/user/[id]/setting.vue')}
            ]},
            {name: 'mobile-user-finduser', path: 'finduser', component: () => import(`@/client/pages/mobile/user/finduser.vue`), children: [
              {name: 'mobile-user-finduser-findid', path: 'findid', component: () => import(`@/client/pages/mobile/user/finduser/findid.vue`)},
              {name: 'mobile-user-finduser-findpass', path: 'findpass', component: () => import(`@/client/pages/mobile/user/finduser/findpass.vue`)},
              {name: 'mobile-user-finduser-doneFindid', path: 'doneFindid', component: () => import(`@/client/pages/mobile/user/finduser/doneFindid.vue`)},
              {name: 'mobile-user-finduser-doneFindpass', path: 'doneFindpass', component: () => import(`@/client/pages/mobile/user/finduser/doneFindpass.vue`)},  
            ]},
            {name: 'signin', path: 'signin', component: () => import(`@/client/pages/mobile/user/signin.vue`), children: [
  
            ]},
            {name: 'intro', path: 'intro', component: () => import(`@/client/pages/mobile/user/intro.vue`)},
            {name: 'logout', path: 'logout', component: () => import(`@/client/pages/mobile/user/logout.vue`)},
            {name: 'mobile-user-signin', path: 'signin', component: () => import('@/client/pages/mobile/user/signin.vue'), children: [
              {name: 'mobile-user-signin-term', path: 'term', component: () => import('@/client/pages/mobile/user/signin/term.vue')},
              {name: 'mobile-user-signin-useTerms', path: 'useTerms', component: () => import('@/client/pages/mobile/user/signin/useTerms.vue')},
              {name: 'mobile-user-signin-personTerms', path: 'personTerms', component: () => import('@/client/pages/mobile/user/signin/personTerms.vue')}

            ]},
            {name: 'mobile-user-login', path: 'login', component: () => import(`@/client/pages/mobile/user/login.vue`), meta: {auth: false, layout: "non-header-footer", middleware: ['set-intro']}},
            
          ]},
          {name: 'mobile-installer', path: 'installer', meta: {layout: 'non-header-footer', titleName: '현장설치업무'}, component: () => import('@/client/pages/mobile/installer.vue'), children: [
           
            {name: 'mobile-installer-policy', path:'policy', meta:{titleName: '현장설치', barName: '작업 전 숙지사항'}, component: () => import('@/client/pages/mobile/installer/policy/index.vue'), children: [
              {name: 'mobile-installer-policy-allpolicy', path:'all', meta:{titleName: '현장설치'}, component: () => import('@/client/pages/mobile/installer/policy/all-policy.vue')},
              {name: 'mobile-installer-policy-rtuinstall', path:'rtu-install', meta:{titleName: '현장설치'}, component: () => import('@/client/pages/mobile/installer/policy/install-policy.vue')}
            ]},
            {name: 'mobile-installer-ownerwork', path:'owners', meta:{titleName: '현장설치업무', barName: '설치자/업무선택'}, component: () => import('@/client/pages/mobile/installer/ownerwork/index.vue'), children: [
              {name: 'mobile-installer-ownerwork-list', path:'list', meta:{titleName: '현장설치업무'}, component:() => import('@/client/pages/mobile/installer/ownerwork/ownerlist.vue')},
              {name: 'mobile-installer-ownerwork-history-list', path:'history-list', meta:{titleName: '현장설치내역'}, component:() => import('@/client/pages/mobile/installer/ownerwork/historylist.vue')},
            
              {name: 'mobile-installer-ownerwork-checkowner', path: 'checkowner', meta:{title:'현장설치업무'}, component:() => import('@/client/pages/mobile/installer/ownerwork/check-owner.vue')},
              {name: 'mobile-installer-ownerwork-checkownermap', path: 'checkowner-map', meta:{title: '현장설치업무'}, component: () => import('@/client/pages/mobile/installer/ownerwork/check-owner-map.vue')},
              {name: 'mobile-installer-ownerwork-checkownermapkakao', path: 'checkowner-map-kakao', meta:{title: '현장설치업무'}, component: () => import('@/client/pages/mobile/installer/ownerwork/check-owner-map-kakao.vue')}
            
            ]},
            {name: 'mobile-installer-rtuinstall', path:'rtu-install', meta:{titleName: '현장설치', barName: 'RTU 연결'}, component: () => import('@/client/pages/mobile/installer/rtu-install/index.vue'), children: [
              {name: 'mobile-installer-rtuinstall-install', path:'install-connect', meta:{titleName: '현장설치'}, component: () => import('@/client/pages/mobile/installer/rtu-install/install.vue')},
              {name: 'mobile-installer-rtuinstall-uninstall', path:'uninstall-connect', meta:{titleName: 'RTU 연결해제'}, component: () => import('@/client/pages/mobile/installer/rtu-install/uninstall.vue')},
              {name: 'mobile-installer-rtuinstall-qrreader', path:'rtu-qr', meta:{titleName: '현장설치'}, component: () => import('@/client/pages/mobile/installer/rtu-install/qr-reader.vue')},
            ]},
            {name: 'mobile-installer-rtustatuscheck', path:'rtu-status', meta:{titleName: '현장설치', barName: 'RTU 연결상태확인'}, component: () => import('@/client/pages/mobile/installer/rtu-connect/index.vue'), children: [
              {name: 'mobile-installer-rtustatuscheck-oncheck', path:'rtu-on-check', meta:{titleName: '현장설치'}, component:() => import('@/client/pages/mobile/installer/rtu-connect/rtu-ison.vue')},
              {name: 'mobile-installer-rtustatuscheck-connect', path:'connect-check', meta:{titleName: '현장설치'}, component:() => import('@/client/pages/mobile/installer/rtu-connect/connect.vue')},
              {name: 'mobile-installer-rtustatuscheck-rtucheck', path:'rtu-check', meta:{titleName: '현장설치'}, component:() => import('@/client/pages/mobile/installer/rtu-connect/check.vue')},
            ]},
            {name: 'mobile-installer-rtuworkhistory', path:'work-history', meta:{titleName: '현장설치', barName: '작업내역제출'}, component: () => import('@/client/pages/mobile/installer/workhistory/index.vue'), children: [
              {name: 'mobile-installer-rtuworkhistory-workinfo', path: 'work-info', meta: {titleName: '현장설치'}, component: () => import('@/client/pages/mobile/installer/workhistory/workinfo.vue')}
            ]},
          ]},
          {name: 'mobile-install-guide', path: 'install/guide', meta: {layout:'non-header-footer', titleName: '현장설치'}, component: () => import("@/client/pages/mobile/install-guide.vue")},
          {name: 'usage-policy', path: 'usage-policy', component: () => import(`@/client/pages/mobile/usage-policy.vue`)},
          {name: 'privacy-policy', path: 'privacy-policy', meta:{layout:'non-header-footer'}, component: () => import(`@/client/pages/mobile/privacy-policy.vue`)},
          {name: 'privacy-policy-old', path: 'privacy-policy-old', meta:{layout:'non-header-footer'}, component: () => import(`@/client/pages/mobile/old-privacy-policy.vue`), children: [
            {name: 'privacy-policy-old-id', path: ':id', meta:{layout:'non-header-footer'}, component: () => import(`@/client/pages/mobile/old-privacy-policy/250208.vue`)}
          ]},
          {name: 'none-contents-page', path: 'none-contents-page', component: () => import(`@/client/pages/mobile/none-contents-page.vue`)},
          {name: 'error-page', path: 'error-page', meta:{layout:'non-header-footer'}, component: () => import(`@/client/pages/mobile/error-page.vue`)}
        ]},
  
        /** TOC */
        {name: "toc", path: '/toc', meta: {layout: 'toc', middleware: 'toc-route-middleware'}, component: () => import('@/client/pages/toc.vue'), children: [
          {name: 'toc-monitoring', meta:{name: '모니터링', owner: true, manager: true, admin: true}, path: 'monitoring', icon:"/assets/images/nav-icon1.svg", component: () => import('@/client/pages/toc/monitoring.vue'), children: [
            {name: 'toc-monitoring-newDashboard', meta:{name:'대시보드', userAuthGroup: [4], }, path: 'newDashboard', component: () => import('@/client/pages/toc/monitoring/newDashboard.vue'), children: [
              {name: 'toc-monitoring-newDashboard-eventdetail-eventId',meta:{name:'이벤트 상세정보'}, path: 'event-detail/:eventId', component: () => import('@/client/pages/toc/event/event-list/[eventId].vue'), children: [
                {name: 'toc-monitoring-newDashboard-eventdetail-eventid-equipmap', meta:{name:'설비설치정보'}, path:'equip-map', component: () => import('@/client/pages/toc/equip/[pathinfo]/equip-map.vue'), children: [
                  {name: 'toc-monitoring-newDashboard-eventdetail-eventid-equipmap-equipdetail-cid-pv', meta:{name:'설비상세정보'}, path: 'equip-detail/:cid/pv', component: () => import(`@/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/pv.vue`)},
                  {name: 'toc-monitoring-newDashboard-eventdetail-eventid-equipmap-equipdetail-cid-geo', meta:{name:'설비상세정보'}, path: 'equip-detail/:cid/geo', component: () => import(`@/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo.vue`)},
                  {name: 'toc-monitoring-newDashboard-eventdetail-eventid-equipmap-equipdetail-cid-solarheat', meta:{name:'설비상세정보'}, path: 'equip-detail/:cid/solarheat', component: () => import(`@/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/solarheat.vue`)},
                ]}
              ]}
            ]},
            {name: 'toc-monitoring-equipDashboard', meta:{name:'대시보드', userAuthGroup: [1,2,3,5,6,7]}, path: 'equipDashboard', component: () => import('@/client/pages/toc/monitoring/equip_dashboard.vue'), children: [
              {name: 'toc-monitoring-equipDashboard-eventdetail-eventId',meta:{name:'이벤트 상세정보'}, path: 'event-detail/:eventId', component: () => import('@/client/pages/toc/event/event-list/[eventId].vue'), children: [
                {name: 'toc-monitoring-equipDashboard-eventdetail-eventid-equipmap', meta:{name:'설비설치정보'}, path:'equip-map', component: () => import('@/client/pages/toc/equip/[pathinfo]/equip-map.vue'), children: [
                  {name: 'toc-monitoring-equipDashboard-eventdetail-eventid-equipmap-equipdetail-cid-pv', meta:{name:'설비상세정보'}, path: 'equip-detail/:cid/pv', component: () => import(`@/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/pv.vue`)},
                  {name: 'toc-monitoring-equipDashboard-eventdetail-eventid-equipmap-equipdetail-cid-geo', meta:{name:'설비상세정보'}, path: 'equip-detail/:cid/geo', component: () => import(`@/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo.vue`)},
                  {name: 'toc-monitoring-equipDashboard-eventdetail-eventid-equipmap-equipdetail-cid-solarheat', meta:{name:'설비상세정보'}, path: 'equip-detail/:cid/solarheat', component: () => import(`@/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/solarheat.vue`)},
                ]}
              ]}
            ]}
          ]},
          {name: 'toc-equip-install', path:"equip/install", icon: "/assets/images/nav-icon2.svg", meta:{name:'설비정보', owner:true, manager: true, admin: true}, children: [
            {name: 'toc-equip-install-equipmap', path: 'equip-map',meta:{name:'발전설비현황'}, component: () => import('@/client/pages/toc/equip/[pathinfo]/equip-map.vue'), children: [
              {name: 'toc-equip-install-equipmap-equipdetail-cid-pv', meta:{name:'설비상세정보'}, path: 'equip-detail/:cid/pv', component: () => import(`@/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/pv.vue`)},
              {name: 'toc-equip-install-equipmap-equipdetail-cid-geo', meta:{name:'설비상세정보'}, path: 'equip-detail/:cid/geo', component: () => import(`@/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo.vue`)},
              {name: 'toc-equip-install-equipmap-equipdetail-cid-solarheat', meta:{name:'설비상세정보'}, path: 'equip-detail/:cid/solarheat', component: () => import(`@/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/solarheat.vue`)},
            ]},
          ]},
          {name: 'toc-equip-operate', path: 'equip/operate', icon:"/assets/images/nav-icon3.svg",meta:{name:'운영정보', owner: true, manager: true, admin: true}, component: () => import('@/client/pages/toc/equip.vue'), children: [
            {name: 'toc-equip-operate-equiplist', path: 'equip-list', meta:{name:'발전설비정보'}, component: () => import('@/client/pages/toc/equip/[pathinfo]/equip-list.vue'), children: [
              {name: 'toc-equip-operate-equiplist-equipdetail-cid-pv', meta:{name: '설비상세정보'}, path: 'equip-detail/:cid/pv', component: () => import(`@/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/pv.vue`)},
              {name: 'toc-equip-operate-equiplist-equipdetail-cid-geo',meta:{name: '설비상세정보'}, path: 'equip-detail/:cid/geo', component: () => import(`@/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo.vue`)},
              {name: 'toc-equip-operate-equiplist-equipdetail-cid-solarheat',meta:{name: '설비상세정보'}, path: 'equip-detail/:cid/solarheat', component: () => import(`@/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/solarheat.vue`)},
            ]},
            {name: 'toc-equip-operate-sites', path: 'sites', meta:{name:'발전사이트정보'}, component: () => import('@/client/pages/toc/equip/[pathinfo]/site/index.vue'), children: [
              // {name: 'toc-equip-operate-sites-site-detail-siteName', meta:{name:'발전사이트 상세정보'}, path: 'site-detail/:siteName', component: () => import('@/client/pages/toc/equip/[pathinfo]/site/[sitename].vue'), children: [
              //   {name: 'toc-equip-operate-sites-site-detail-siteName-pv',  path: 'pv', component: () => import('@/client/pages/toc/equip/[pathinfo]/site/[sitename]/pv.vue')},
              //   {name: 'toc-equip-operate-sites-site-detail-siteName-geo', path: 'geo', component: () => import('@/client/pages/toc/equip/[pathinfo]/site/[sitename]/geo.vue')},
              //   {name: 'toc-equip-operate-sites-site-detail-siteName-solarheat', path: 'solarheat', component: () => import('@/client/pages/toc/equip/[pathinfo]/site/[sitename]/solarheat.vue')},
              // ]},
              {name: 'toc-equip-operate-sites-site-details-newsitename', meta:{name:'발전사이트 상세정보'}, path: 'site-details/:newsitename', component: () => import('@/client/pages/toc/equip/[pathinfo]/site/test/[newsitename].vue'), children: [
                {name: 'toc-equip-operate-sites-site-detail-newsitename-pv',  path: 'pv', component: () => import('@/client/pages/toc/equip/[pathinfo]/site/[newsitename]/pv.vue')},
                {name: 'toc-equip-operate-sites-site-detail-newsitename-geo', path: 'geo', component: () => import('@/client/pages/toc/equip/[pathinfo]/site/[newsitename]/geo.vue')},
                {name: 'toc-equip-operate-sites-site-detail-newsitename-solarheat', path: 'solarheat', component: () => import('@/client/pages/toc/equip/[pathinfo]/site/[newsitename]/solarheat.vue')},
              ]}
            ]},
            {name: 'toc-equip-operate-powergenerate', path: 'power-generate',meta:{name:'발전량정보'}, component: () => import('@/client/pages/toc/equip/[pathinfo]/power-generate/index.vue')},
          ]},
          {name: 'toc-analysis', meta:{name: '통계분석',owner: false, manager: true, admin: true}, icon: "/assets/images/nav-icon5.svg", path: 'analysis', component: () => import('@/client/pages/toc/analysis.vue'), children: [
            {name: 'toc-analysis-owner', meta:{name:'설치자별 생산량'}, path: 'owner', component: () => import('@/client/pages/toc/analysis/owner.vue')},
            {name: 'toc-analysis-compare-owner', meta:{name:'설치자간 비교'}, path: 'compare-owner', component: () => import('@/client/pages/toc/analysis/compare-owner.vue')},
            {name: 'toc-analysis-compare-region', meta:{name:'운영지역간 비교'}, path: 'compare-region', component: () => import('@/client/pages/toc/analysis/compare-region.vue')}
            
          ]},
          {name: 'toc-user', meta:{owner: false, manager: false, admin: false}, path: 'user', component: () => import('@/client/pages/toc/user/index.vue'), children: [
            {name: 'toc-user-login', path: 'login', meta: {layout: 'toc-non-side'}, component: () => import("@/client/pages/toc/user/login.vue")},
            {name: 'toc-user-find-id', path: 'find-id', meta: {layout: 'toc-non-side'}, component: () => import("@/client/pages/toc/user/find-id.vue")}
          ]},
          {name: 'toc-event', meta:{name:'운영 관리', owner: false, manager: true, admin: true}, path: 'event', icon: "/assets/images/nav-icon4.svg", component: () => import('@/client/pages/toc/event/index.vue'), children: [
            {name: 'toc-user-service-user-as-request', meta:{name:'AS 신청', companyAdmin: '1'}, path: 'user-as-request', component: () => import('@/client/pages/toc/user-service/user-as-request/index.vue'), children: [
              {name: 'toc-user-service-user-as-request-info-id', meta:{name:'AS 신청 내용 작성', companyAdmin: '1'}, path: 'info/:id', component: () => import('@/client/pages/toc/user-service/user-as-request/info.vue')}
            ]},
            {name: 'toc-user-service-user-as-management', meta:{name:'AS 신청목록', companyAdmin: '1'}, path: 'user-as-management', component: () => import('@/client/pages/toc/user-service/user-as-management/index.vue'), children: [
              {name: 'toc-user-service-user-as-management-info-id', meta:{name:'AS 신청 상세정보', companyAdmin: '1'}, path: 'info/:id', component: () => import('@/client/pages/toc/user-service/user-as-management/info.vue')}

            ]},
            {name: 'toc-event-eventlist', meta:{name: '이벤트 관리'}, path: 'event-list', component: () => import('@/client/pages/toc/event/event-list.vue'), children: [
              {name: 'toc-event-eventlist-eventdetail-eventid', meta:{name:'이벤트 상세정보'}, path: "event-detail/:eventId", component: () => import('@/client/pages/toc/event/event-list/[eventId].vue'), children: [
                {name: 'toc-event-eventlist-eventdetail-eventid-equipmap', meta:{name:'설비설치정보'}, path:'equip-map', component: () => import('@/client/pages/toc/equip/[pathinfo]/equip-map.vue'), children: [
                  {name: 'toc-event-eventlist-eventdetail-eventid-equipmap-equipdetail-cid-pv', meta:{name:'설비상세정보'}, path: 'equip-detail/:cid/pv', component: () => import(`@/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/pv.vue`)},
                  {name: 'toc-event-eventlist-eventdetail-eventid-equipmap-equipdetail-cid-geo', meta:{name:'설비상세정보'}, path: 'equip-detail/:cid/geo', component: () => import(`@/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo.vue`)},
                  {name: 'toc-event-eventlist-eventdetail-eventid-equipmap-equipdetail-cid-solarheat', meta:{name:'설비상세정보'}, path: 'equip-detail/:cid/solarheat', component: () => import(`@/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/solarheat.vue`)},
                ]}
              ]}
            ]}
          ]},
          {name: 'toc-customer', meta:{name: '고객센터',owner: false, manager: true, admin: true}, path: 'customer', icon: "/assets/images/nav-icon7.svg", component: () => import('@/client/pages/toc/customer.vue'), children: [
            {name: 'toc-customer-notice',meta:{name:'공지사항'}, path: 'notice', component: () => import('@/client/pages/toc/customer/notice.vue'), children: [
              {name: 'toc-customer-notice-index', path: '', component: () => import('@/client/pages/toc/customer/notice/index.vue')},
              {name: 'toc-customer-notice-id', meta:{name:'공지사항 상세'}, path: ':id', component: () => import('@/client/pages/toc/customer/notice/[id].vue')}
            ]},
            // {name: 'toc-customer-faq', meta:{name:'FAQ'}, path: 'faq', component: () => import('@/client/pages/toc/customer/faq/index.vue'), children:[
            //   {name: 'toc-customer-faq-id', meta:{name:'FAQ 상세'}, path: ':id', component: () => import('@/client/pages/toc/customer/faq/[id].vue')}
            // ]},
            // {name: 'toc-customer-reference', meta:{name:'자료실'}, path: 'reference', component: () => import('@/client/pages/toc/customer/reference/index.vue'), children:[
            //   {name: 'toc-customer-reference-id', meta:{name:'자료실 상세'}, path:':id', component: () => import('@/client/pages/toc/customer/reference/[id].vue')}
            // ]},
            {name: 'toc-customer-qna', meta:{name:'이용문의(Q&A)'}, path: 'qna', component: () => import('@/client/pages/toc/customer/qna.vue'), children: [
              {name: 'toc-customer-qna-index', path: '', component: () => import('@/client/pages/toc/customer/qna/index.vue')},
              {name: 'toc-customer-qna-id', meta:{name: '이용문의(Q&A) 상세'}, path: ':id', component: () => import('@/client/pages/toc/customer/qna/[id].vue')},
              {name: 'toc-customer-qna-add', meta:{name: '이용문의(Q&A) 등록'}, path: 'add', component: () => import('@/client/pages/toc/customer/qna/add.vue')}
            ]},
            {name: 'toc-customer-terms', meta:{name:'정책 및 약관'}, path: 'terms', component: () => import('@/client/pages/toc/customer/terms.vue')}
          ]},
          {name: 'toc-user-service', meta:{name:'회원서비스',owner: false, manager: true, admin: true}, icon: "/assets/images/nav-icon8.svg", path: 'user-service', component: () => import('@/client/pages/toc/user-service.vue'), children: [
            {name: 'toc-user-service-user-info', meta:{name:'회원정보관리'}, path: 'user-info', component: () => import('@/client/pages/toc/user-service/user-info.vue')},
            {name: 'toc-user-service-manager-info', meta:{name:'담당자관리', companyAdmin: '1'}, path: 'manager-info', component: () => import('@/client/pages/toc/user-service/manager-info.vue')},
            {name: 'toc-user-service-set-manager', meta:{name:'설비관리자지정', companyAdmin: '1'}, path: 'set-manager', component: () => import('@/client/pages/toc/user-service/set-manager.vue')},
            {name: 'toc-user-service-user-setting', meta:{name:'사용자설정관리', companyAdmin: '1'}, path: 'user-setting', component: () => import('@/client/pages/toc/user-service/user-setting.vue')},
        
          ]},
          // {name: 'toc-management', meta:{name: '관리', owner: false, manager:false, admin: true}, icon: "/assets/images/nav-icon9.svg", path: 'management', component: () => import('@/client/pages/toc/management/management.vue'), children: [
          //   {name: 'toc-managerment-owner', meta: {name: '설치자 회원 관리'}, path: 'owner', component: () => import('@/client/pages/toc/management/owner.vue'), children: [
          //     {name: 'toc-management-owner-index', path: '', component: () => import('@/client/pages/toc/management/owner/index.vue')},
          //     {name: 'toc-management-owner-add', meta: {name: '설치자 회원 등록'}, path: 'add', component: () => import('@/client/pages/toc/management/owner/add.vue')}
          //   ]},
          //   {name: 'toc-management-equip', meta: {name: '설비 관리'}, path: 'equip',  component: () => import('@/client/pages/toc/management/equip.vue'), children: [
          //     {name: 'toc-management-equip-index', path: '', component: () => import('@/client/pages/toc/management/equip/index.vue')},
          //     {name: 'toc-management-equip-add', meta: {name: '설비 추가'}, path: 'add', component: () => import('@/client/pages/toc/management/equip/add.vue'), children: [
          //       {name: 'toc-management-equip-add-pv', meta: {name: '태양광'}, path: 'pv', component: () => import('@/client/pages/toc/management/equip/add/pv-spec.vue')},
          //       // {name: 'toc-management-equip-add-geo', meta: {name: '지열'}, path: 'geo', component: () => import('@/client/pages/toc/management/equip/add/geo-spec.vue')}
          //     ]}
          //   ]},
          //   {name: 'toc-management-menu-permission', meta:{name:'메뉴 권한 관리'}, path:'permit', component: () => import('@/client/pages/toc/management/permit.vue'), children: [e
          //     {name: 'toc-management-menu-permission', path: '', component: () => import('@/client/pages/toc/management/permit/index.vue')}
          //   ]}
            
          
          //   // {name: 'toc-managerment-company', meta: {name: '업체 관리'}, path: 'company', component: () => import('@/client/pages/toc/management/company.vue'), children: [
          //   //   {name: 'toc-management-company-index', path: '', component: () => import('@/client/pages/toc/management/company/index.vue')},
          //   //   {name: 'toc-management-company-add', meta: {name: '업체 회원 등록'}, path: 'add', component: () => import('@/client/pages/toc/management/company/add.vue'), props: true}
          //   // ]},
          //   // {name: 'toc-management-equip-company-list', meta: {name: '회사목록'}, path: 'list', component: () => import('@/client/pages/toc/inventory/equip-company/product.vue')},
          //   // {name: 'toc-management-equip-company-add', meta: {name: '장비등록'}, path: 'add', component: () => import('@/client/pages/toc/inventory/equip-company/add.vue')}
          //   // {name: 'toc-management-abc',meta:{name:'aaaa'}, path: 'abc', component: () => import('@/client/pages/toc/member/owner-member/owner-search.vue'), children: [
          //     // {name: 'toc-management-abc-1', path: '1', component: () => import('@/client/pages/toc/member/owner-member/owner-search.vue')}
          //   // ]}

          // ]},
          {name: 'toc-not-found', meta:{name: '경로 오류',owner: false, manager: false, admin: false}, path: 'not-found', component: () => import('@/client/pages/toc/not-found.vue')},
          {name: 'toc-error', meta:{owner:false, manager:false, admin: false}, path: 'error', component: () => import('@/client/pages/toc/error.vue')},
          {name: 'toc-pathMatch', meta:{owner:false, manager:false, admin:false}, path:'/:pathMatch(.*)*', redirect: '/toc/not-found'},
          {name: 'test', path: '/test',  meta:{owner:false, manager:false, admin:false}, component: () => import('@/client/pages/toc/test.vue')}
        ]},
        {name: 'main-test', path: '/test/main', component: () => import('@/client/pages/test/main.vue')},
        // {name: 'test-qr', path: '/test/qr', component: () => import('@/client/pages/test/qrcode.vue')},
        {name: 'test-qrtest', path: '/test/qrtest', component: () => import('@/client/pages/test/qrtest.vue')},
        {name: 'test-geo', path: '/test/geo', component: () => import('@/client/pages/test/geo.vue')}
      ]},
    ]

    return baseRoute;
  }
}