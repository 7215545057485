import plugin_vue3_nO85tdiEE8 from "/home/kbet/rems/install/kie-rems-mweb/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.9.0_vue@3.4.27/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/kbet/rems/install/kie-rems-mweb/.nuxt/components.plugin.mjs";
import unhead_jGzvgmX4F7 from "/home/kbet/rems/install/kie-rems-mweb/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.19.3_rollup@4.9.0_sass@1.69.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_Tdjdtpu5Iq from "/home/kbet/rems/install/kie-rems-mweb/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.19.3_rollup@4.9.0_sass@1.69.5/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_1RtbERPIAW from "/home/kbet/rems/install/kie-rems-mweb/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.19.3_rollup@4.9.0_sass@1.69.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_7niuKKqbrP from "/home/kbet/rems/install/kie-rems-mweb/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.19.3_rollup@4.9.0_sass@1.69.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_KV8w00Za1s from "/home/kbet/rems/install/kie-rems-mweb/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.9.0_vue@3.4.27/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_T1zTZq80Jl from "/home/kbet/rems/install/kie-rems-mweb/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.9.0_vue@3.4.27/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_gWl0Ed24Do from "/home/kbet/rems/install/kie-rems-mweb/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.9.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_A4J26Ulybk from "/home/kbet/rems/install/kie-rems-mweb/node_modules/.pnpm/@sidebase+nuxt-auth@0.5.0_next-auth@4.21.1_rollup@4.9.0/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import debug_Lo3a8j7lwx from "/home/kbet/rems/install/kie-rems-mweb/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.19.3_rollup@4.9.0_sass@1.69.5/node_modules/nuxt/dist/app/plugins/debug.js";
import chunk_reload_client_DwqetwSQTp from "/home/kbet/rems/install/kie-rems-mweb/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.19.3_rollup@4.9.0_sass@1.69.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import v_calendar_reOvuQUMeW from "/home/kbet/rems/install/kie-rems-mweb/src/client/plugins/v-calendar.ts";
import apexcharts_client_OApXKpYeF4 from "/home/kbet/rems/install/kie-rems-mweb/src/client/plugins/apexcharts.client.ts";
import lifecycle_hook_wrym1KhHIU from "/home/kbet/rems/install/kie-rems-mweb/src/client/plugins/lifecycle-hook.ts";
import useBootstrap_client_yySSoVS0QR from "/home/kbet/rems/install/kie-rems-mweb/src/client/plugins/useBootstrap.client.ts";
import vee_validate_sykkdhZOFm from "/home/kbet/rems/install/kie-rems-mweb/src/client/plugins/vee-validate.ts";
import plugin_ZLg0zoRrl8 from "/home/kbet/rems/install/kie-rems-mweb/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.4.11_pinia@2.1.7_rollup@4.9.0/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import error_handle_Lhl0DV9Yxv from "/home/kbet/rems/install/kie-rems-mweb/src/client/plugins/error-handle.ts";
import i18n_2y6Rk9zdYC from "/home/kbet/rems/install/kie-rems-mweb/src/client/plugins/i18n.ts";
import mobile_handle_YDdrxUOhm2 from "/home/kbet/rems/install/kie-rems-mweb/src/client/plugins/mobile-handle.ts";
export default [
  plugin_vue3_nO85tdiEE8,
  components_plugin_KR1HBZs4kY,
  unhead_jGzvgmX4F7,
  vueuse_head_polyfill_Tdjdtpu5Iq,
  router_1RtbERPIAW,
  prefetch_client_7niuKKqbrP,
  switch_locale_path_ssr_KV8w00Za1s,
  i18n_T1zTZq80Jl,
  plugin_gWl0Ed24Do,
  plugin_A4J26Ulybk,
  debug_Lo3a8j7lwx,
  chunk_reload_client_DwqetwSQTp,
  v_calendar_reOvuQUMeW,
  apexcharts_client_OApXKpYeF4,
  lifecycle_hook_wrym1KhHIU,
  useBootstrap_client_yySSoVS0QR,
  vee_validate_sykkdhZOFm,
  plugin_ZLg0zoRrl8,
  error_handle_Lhl0DV9Yxv,
  i18n_2y6Rk9zdYC,
  mobile_handle_YDdrxUOhm2
]