import { Method } from "~/enums";
import { useUserStore } from "./userStore";
import Swal from "sweetalert2";
import { AttachmentsDto, CodeDto, WorkRequestDetailDto, WorkRequestInfo } from "~/models/dto-types";
import { Attachment, cid_rtu_info_mappings } from "@prisma/client";
import { useCommonCodeStore } from "./commonCodeStore";
import moment from "moment";
import CommonUtils from "~/utils/common-utils";
import { use } from "echarts";

interface gpsInfo{
  latitude: number | undefined,
  longitude: number | undefined,
}

interface WorkAttachInfo{
  [x:string] : File[],
  workDetail: string
}

export const useInstallerStore = defineStore("installerStore", {
	state: () => ({
    // workerGpsInfo: {
    //   latitude: process.env.NODE_ENV !== 'production' ? 36.94626 : undefined,
    //   longitude: process.env.NODE_ENV !== 'production' ? 127.429329 : undefined
    // } as gpsInfo,
    workerGpsInfo: 
    useLocalStorage(
      "workerGpsInfo", {} as {
        latitude: undefined,
        longitude: undefined
      } as gpsInfo
    ),
    workRequestList: useLocalStorage("workRequestList", [] as WorkRequestInfo[]),
    selectWorkOwner: useLocalStorage("selectWorkOwner", null as WorkRequestDetailDto | null),
    selectWorkOrgOwner: useLocalStorage("selectWorkOrgOwner", null as WorkRequestDetailDto | null),
    selectWorkRequest: useLocalStorage("selectWorkRequest", null as WorkRequestInfo | null),
    rtuQRCode: 
      useLocalStorage("rtuQRCode", process.env.NODE_ENV !== 'production'? 'T24K441OS20000003' : '' as string),
      uninstallRtuQRCode: '' as string,
      originRtuQrCode: '' as string,
    workAttachInfo: 
      useLocalStorage("workAttachInfo", {} as WorkAttachInfo),
    rtuQRReaderType: '' as string,
    cmplWorkInfo: useLocalStorage("cmplWorkInfo", null as WorkRequestDetailDto | null),
    complementContent: useLocalStorage("complementContent", '' as string),
    userSeq: useLocalStorage("userSeq", 0 as number),
    getCategoryCodeInfo: useLocalStorage("getCategoryCodeInfo", [] as CodeDto[])
	}),
	getters: {
    isGpsNull(){
      if(this.workerGpsInfo.latitude === undefined || this.workerGpsInfo.longitude === undefined){
        return true;
      }

      return false;
    }
	},
	actions: {
    init(){
      const userStore = useUserStore();
      this.userSeq = userStore.loginUserInfo?.userSeq as number;
    },
    disConnRtuReset(){
      this.originRtuQrCode = '';
      this.uninstallRtuQRCode = '';
    },
    workAttachInfoClear(workType: string){
      // workType = 'new';

      console.log(workType);

      if(!workType) workType = 'new';

      workType = this.kindParser(workType);

      const commonCodeStore = useCommonCodeStore();
      let workTypeUpper = workType.substring(0, 1).toUpperCase() + workType.substring(1, workType.length);
      let mapCodes = commonCodeStore.getMapCodeList(`fileCategory${workTypeUpper}`) as Map<string, CodeDto>;

      console.log(mapCodes);
      let mapValues = mapCodes.values();
      
      this.getCategoryCodeInfo = [];

      for(let i = 0; i < mapCodes.size; i++){
        this.getCategoryCodeInfo.push(mapValues.next().value as CodeDto);
      }

      this.getCategoryCodeInfo = this.getCategoryCodeInfo.sort((a, b) => {
        if((a.sortOrder as number) > (b.sortOrder as number)) return 1;
        if((a.sortOrder as number) < (b.sortOrder as number)) return -1;
        return 0;
      })


      this.workAttachInfo = {};


      for(let i = 0; i < this.getCategoryCodeInfo.length; i++){
        this.workAttachInfo[this.getCategoryCodeInfo[i].code] = [] as File[];
      }
      

      // this.workAttachInfo.ownerAddr = [];
      // this.workAttachInfo.beforeInstallAround = [];
      // this.workAttachInfo.afterInstallAround = [];
      // this.workAttachInfo.afterInstallInnerCase = [];
      // this.workAttachInfo.afterInstallRTU = [];
      // this.workAttachInfo.afterInstallBraker = [];

      this.workAttachInfo.workDetail = '';
      this.complementContent = '';
      this.rtuQRCode = '';
    },

    /**
     * 작업 리스트 조회
     * @param pageNo       검색 페이지 번호
     * @param pageSize     검색 페이지 노출 사이즈
     * @param gpsLatitude  gps상의 위도
     * @param gpsLongitude gps상의 경도
     * @param searchKind   검색 종류(20: 설치자명)
     * @param searchText   검색 단어
     */
    async getWorkRequest(
      kind: string,
      pageNo?: number, 
      pageSize?: number,
      gpsLatitude?: number,
      gpsLongitude?: number,
      searchText?: string,
    ){
      if(searchText?.trim() === ''){
        searchText = undefined;
      }

      const userStore = useUserStore();
      const userSeq = userStore.loginUserInfo?.userSeq;
      const searchKind = searchText && searchText !== '' ? '20' : undefined;

      try{
        const res = await useCustomFetch(`/api/v1/work/works/${kind}/list`, {method: Method.GET, query: {
          userSeq     : userSeq,
          pageNo      : pageNo,
          pageSize    : pageSize,
          gpsLatitude : gpsLatitude,
          gpsLongitude: gpsLongitude,
          searchKind  : searchKind,
          searchText  : searchText
        }});

        this.workRequestList = res.data.value as WorkRequestInfo[];

      } catch(e){
     
      }
    },
    /**
     * 선택된 작업 및 설치자 정보를 store에 저장
     * @param cid 설치자 일련번호
     */
    async setSelectRequests(workSeq: number){
      this.selectWorkRequest = this.workRequestList.find(v => v.workSeq === workSeq) as WorkRequestInfo;
    
    },
    

    /**
     * 작업자의 gps 정보를 저장
     * @param latitude   위도
     * @param longitude  경도
     */
    setWorkerGps(latitude?: number, longitude?: number){
      if(this.workerGpsInfo === null){
        this.workerGpsInfo = {
          latitude: 0,
          longitude: 0,
        }
      }
      this.workerGpsInfo.latitude = latitude as number; this.workerGpsInfo.longitude = longitude as number;
    },

    setQRCode(code: string){
      if(this.rtuQRReaderType === 'uninstall'){
        this.uninstallRtuQRCode = code;
      } else {
        this.rtuQRCode = code;
      }

    },
    clearQRCode(){
      this.rtuQRCode = '';
    },


    /**
     * RTU 시리얼 번호 유효성 체크
     * @param cid CID 번호
     * @param requestRegDate 작업요청일시 
     * @param serialNumber RTU 시리얼 번호
     * @returns 
     */
    async checkRtuSerial(
      serialNumber: string,
      cid: string
    ){
      try{
        const res = await useCustomFetch('/api/v1/work/validationserial', {method: Method.GET, query: {
          serialNumber: serialNumber,
          cid: cid
        }});

        if(res.data.value === null){
          try{
            return JSON.parse(res.error.value?.data.message)[0].message; 
          }catch(e){
            if(res.error.value?.data.message.includes('유효하지 않은 시리얼번호입니다.')){
              return '유효하지 않은 시리얼번호입니다.'
            }

            return res.error.value?.data.message;
          }
        }

        return res.data.value;

      } catch(e){
        console.log(e);

        Swal.fire({
      		html: `<p style="font-size: 15px;">오류가 발생하였습니다.<br>잠시 후 다시 시도해주세요.</p>`,
          confirmButtonColor: "#1949e9",
          confirmButtonText: "확인",
          width: "85%",
        })
      }
    },
    
    async rtuConnect(
      workSeq: number,
      workType: string,
      serialNumber?: string,
    ){
      const gpsInfo = this.workerGpsInfo;

      try{
        const res = await useCustomFetch('/api/v1/work/rtuconnect', {method: Method.POST, query: {
          workSeq: workSeq,
          workType: workType,
          userSeq: this.userSeq,
          serialNumber: serialNumber,
          gpsLatitude: gpsInfo?.latitude,
          gpsLongitude: gpsInfo?.longitude
        }});

        if(res.data.value === null){
          return res.error.value;
        }

        return res.data.value;

      }catch(e){
        Swal.fire({
      		html: `<p style="font-size: 15px;">오류가 발생하였습니다.<br>잠시 후 다시 시도해주세요.</p>`,
          confirmButtonColor: "#1949e9",
          confirmButtonText: "확인",
          width: "85%",
        })
      }

    },
    async workDetail(
      workSeq: number
    ){
      const userSeq = this.userSeq;

      try{
        const res = await useCustomFetch('/api/v1/work/works/detail', {method: Method.GET, query: {
          workSeq: workSeq,
          userSeq: userSeq
        }});

        if(res.data.value === null){
          throw new Error(res.error.value?.data.value);
        }



        this.selectWorkOwner = res.data.value as WorkRequestDetailDto;
        this.workAttachInfoClear(this.selectWorkOwner.workType as string);
        

        this.selectWorkOrgOwner = null;

        if(this.selectWorkOwner.org_workSeq !== null && this.selectWorkOwner.isAttached === null){
          this.complementContent = this.selectWorkOwner.reqContent as string;

          const res =  await useCustomFetch('/api/v1/work/works/detail', {method: Method.GET, query: {
            workSeq: this.selectWorkOwner.org_workSeq,
            userSeq: userSeq
          }});

          
          if(res.data.value === null){
            throw new Error(res.error.value?.data.value);
          }

          this.selectWorkOrgOwner = res.data.value as WorkRequestDetailDto;

          this.selectWorkOwner.attachments = (res.data.value as WorkRequestDetailDto).attachments;
        }

        if(this.selectWorkOwner.attachments !== undefined && this.selectWorkOwner.attachments !== null){
          const attachmentsList = this.selectWorkOwner.attachments as AttachmentsDto[]

          for(let i = 0; i < attachmentsList.length; i++){
            const blob = await this.getAttachFile(attachmentsList[i].attachSeq);
            const newFiles = new File([blob], attachmentsList[i].org_attachName as string, {type: 'image/jpeg'});
            this.workAttachInfo[attachmentsList[i].fileCategory].push(newFiles);            
            // switch(attachmentsList[i].fileCategory){
              // case "addr":{ this.workAttachInfo.ownerAddr.push(newFiles);  break;}
              // case "bf_fg":{ this.workAttachInfo.beforeInstallAround.push(newFiles);  break;}
              // case "af_fg":{ this.workAttachInfo.afterInstallAround.push(newFiles);  break;}
              // case "af_box":{ this.workAttachInfo.afterInstallInnerCase.push(newFiles);  break;}
              // case "af_rtu":{ this.workAttachInfo.afterInstallRTU.push(newFiles);  break;}
              // case "af_cb" :{this.workAttachInfo.afterInstallBraker.push(newFiles); break;}
            // }
          }
        }

         this.workAttachInfo.workDetail = this.selectWorkOwner.workContent as string;

        return true

      }catch(e){
        Swal.fire({
      		html: `<p style="font-size: 15px;">오류가 발생하였습니다.<br>잠시 후 다시 시도해주세요.</p>`,
          confirmButtonColor: "#1949e9",
          confirmButtonText: "확인",
          width: "85%",
        })
      }
    },
    async getAttachFile(attachSeq: Number){
      try{
        const res = await useCustomFetch('/api/v1/work/works/attach', {method: Method.GET, query: {
          attachSeq: attachSeq
        }});

        if(res.data.value === null){
          try{
            return JSON.parse(res.error.value?.data.message)[0].message; 
          }catch(e){
            if(res.error.value?.data.message.includes('첨부파일을 다운로드하는 중에 에러가 발생하였습니다.')){
              return '첨부파일을 다운로드하는 중에 에러가 발생하였습니다.'
            }

            return res.error.value?.data.message;
          }
        }

        return res.data.value;

      }catch(e){
        Swal.fire({
      		html: `<p style="font-size: 15px;">오류가 발생하였습니다.<br>잠시 후 다시 시도해주세요.</p>`,
          confirmButtonColor: "#1949e9",
          confirmButtonText: "확인",
          width: "85%",
        })
      }
    },
    
    async chgWorkStatus(
      kind: 'check' | 'checked' | 'work',
      workSeq: number,
    ){
      const gpsInfo = this.workerGpsInfo;

      try{
        const res = await useCustomFetch('/api/v1/work/chgWorkStatus', {method: Method.PUT, query: {
          kind: kind,
          workSeq: workSeq,
          userSeq: this.userSeq,
          gpsLatitude: gpsInfo.latitude,
          gpsLongitude: gpsInfo.longitude,
        }});

        if(res.data.value === null){
          return res.error.value
        }

        return res.data.value;
      }catch(e){
        Swal.fire({
      		html: `<p style="font-size: 15px;">오류가 발생하였습니다.<br>잠시 후 다시 시도해주세요.</p>`,
          confirmButtonColor: "#1949e9",
          confirmButtonText: "확인",
          width: "85%",
        })
      }

    },
    async rtuConnStatus(
      workSeq: number
    ){

      try{
        const res = await useCustomFetch('/api/v1/work/rtuconnstatus', {method: Method.GET, query: {
          workSeq: workSeq,
          userSeq: this.userSeq
        }});

        if(res.data.value === null){
          return res.error.value
        }

        return res.data.value;
      }catch(e){
        Swal.fire({
      		html: `<p style="font-size: 15px;">오류가 발생하였습니다.<br>잠시 후 다시 시도해주세요.</p>`,
          confirmButtonColor: "#1949e9",
          confirmButtonText: "확인",
          width: "85%",
        })
      }
    },
    async rtuRegInfo(
      workSeq: number
    ){

      try{
        const res = await useCustomFetch('/api/v1/work/rtureginfo', {method: Method.GET, query: {
          workSeq: workSeq,
          userSeq: this.userSeq
        }});
        
        if(res.data.value === null){
          return res.error.value
        }

        return res.data.value;
      }catch(e){
        Swal.fire({
      		html: `<p style="font-size: 15px;">오류가 발생하였습니다.<br>잠시 후 다시 시도해주세요.</p>`,
          confirmButtonColor: "#1949e9",
          confirmButtonText: "확인",
          width: "85%",
        })
      }
    },

    
     kindParser(kind: string){
      let result = kind;

      switch(kind){
        case 'chg_rtu'  : {result = 'new'; break;}
        case 'chg_owner': {result = 'new'; break;}
        case 'crtu_cmpl': {result = 'new'; break;}
        case 'cow_cmpl' : {result = 'new'; break;}
        case 'new_cmpl' : {result = 'new'; break;}
        case 'rtu_cmpl' : {result = ''; break;}
        case 'eq_cmpl' : {result = ''; break;}
        case 'eq_onm' : {result = ''; break;}
        case 'rtu_onm' : {result = ''; break;}
        default: {result = 'new'; break;}
      }

      return result;
    },


    async rtuNewInstallTempSave(
      kind: string,
      requestKind: 'work_temp' | 'worked' | 'complement' | 'complete',
      onmkind?: 'rtu' | 'eq',
    ){

      let codeCategory = kind;
      


      if(this.selectWorkOrgOwner !== null){
        codeCategory = this.selectWorkOrgOwner.workType as string;
      }

      codeCategory = this.kindParser(codeCategory);
      
      const kindUpperCase = codeCategory.substring(0, 1).toUpperCase() + codeCategory.substring(1, codeCategory.length);

      const commonCodeStore = useCommonCodeStore();
      const cateogryList = commonCodeStore.getMapCodeToArray(`fileCategory${kindUpperCase}`);

      // const cid = this.selectWorkOwner?.cid;
      // const requestRegDate = moment(this.selectWorkOwner?.requestRegDate).format('YYYY-MM-DD HH:mm:ss');
      // console.log(requestRegDate,  moment(this.selectWorkOwner?.requestRegDate).format('YYYY-MM-DD HH:mm:ss'), this.selectWorkOwner?.requestRegDate)
      
      const workSeq = this.selectWorkOwner?.workSeq;
      const userSeq = this.userSeq;

      const gpsLatitude = this.workerGpsInfo.latitude;
      const gpsLongitude = this.workerGpsInfo.longitude;

      let fileList = [] as File[];

      let imageSameCount = 1;

      let contents = {} as {
        [x:string] : string[] | string | null
      };

      let formData = new FormData();

      if(requestKind !== 'complete' && requestKind !== 'work_temp'){
        if(this.workAttachInfo.workDetail === null || this.workAttachInfo.workDetail.toString().trim() === ''){
          return '설치 작업 내역을 작성해주세요.';
        }

        contents.content = this.workAttachInfo.workDetail;
      } else {
        contents.content = this.workAttachInfo.workDetail;
      }

      if(requestKind === 'complete'){
        contents.content = null;
      }

      if(requestKind === 'work_temp' || requestKind === 'worked'){

        
        let keys = Object.keys(this.workAttachInfo);


        if(requestKind === 'worked'){
          for(let i = 0; i < keys.length; i++){
            let keyName = keys[i];
            if(keyName === 'workDetail') continue;

            if(this.workAttachInfo[keyName].length <= 0) {
              return `${this.getCategoryCodeInfo.find(v => v.code === keyName)?.codeName}을 업로드해주세요.`
            }
          }

          //   if(this.workAttachInfo.ownerAddr.length <= 0) {return '주소 명판 사진을 업로드해주세요.';}
        //   if(this.workAttachInfo.beforeInstallAround.length <= 0) { return '설치전 전경 사진을 업로드해주세요.';}
        //   if(this.workAttachInfo.afterInstallAround.length <= 0) { return '설치후 전경 사진을 업로드해주세요.'}
        //   if(this.workAttachInfo.afterInstallInnerCase.length <= 0) {return '설치후 모니터링함 내부 사진을 업로드해주세요.';}
        //   if(this.workAttachInfo.afterInstallRTU.length <= 0) {return '설치후 RTU 사진을 업로드해주세요.';}
        //   if(this.workAttachInfo.afterInstallBraker.length <= 0){return '설치후 발전용 차단기 사진을 업로드해주세요.'}
        }



        let newFileCount = 1;


        for(let i = 0; i < keys.length; i++){
          let keyName = keys[i] as keyof typeof this.workAttachInfo;
          if(keyName === 'workDetail') continue;

          for(let j = 0; j < this.workAttachInfo[keyName].length; j++){
           
            let file = this.workAttachInfo[keyName][j] as File;
            if(fileList.findIndex(v => v.name === file.name) === -1){
              fileList.push(file);
            } else {
              let filenameLen = file.name.length;
              let fileNameLastDot = file.name.lastIndexOf('.');
              let splitFileName = file.name.substring(0, fileNameLastDot);
              let fileNameExt = file.name.substring(fileNameLastDot, filenameLen).toLocaleLowerCase();

              let newFileBlob = file.slice(0, file.size, file.type);
              let newFile = new File([newFileBlob], `${splitFileName} - (${newFileCount}).${fileNameExt}`, {type: file.type});
              fileList.push(newFile);
              this.workAttachInfo[keyName][j] = newFile; 
              newFileCount++;

            }
          }
        }


        for(let i = 0; i < cateogryList.length; i++){
          contents[cateogryList[i].key] = this.workAttachInfo[cateogryList[i].key].length <= 0 ? null : this.workAttachInfo[cateogryList[i].key].map(v=> v.name);


          // switch(cateogryList[i].value.code){
          //   case 'addr':{
          //     contents[cateogryList[i].key as keyof typeof contents] = this.workAttachInfo.ownerAddr.length <= 0 ? null : this.workAttachInfo.ownerAddr.map(v => v.name) as string[];
          //     break;
          //   }
          //   case 'bf_fg':{
          //     contents[cateogryList[i].key as keyof typeof contents] = this.workAttachInfo.beforeInstallAround.length <= 0 ? null : this.workAttachInfo.beforeInstallAround.map(v => v.name) as string[];
          //     break;
          //   }
          //   case 'af_fg':{
          //     contents[cateogryList[i].key as keyof typeof contents] = this.workAttachInfo.afterInstallAround.length <= 0 ? null : this.workAttachInfo.afterInstallAround.map(v => v.name) as string[];
          //     break;
          //   }
          //   case 'af_box':{
          //     contents[cateogryList[i].key as keyof typeof contents] = this.workAttachInfo.afterInstallInnerCase.length <= 0 ? null : this.workAttachInfo.afterInstallInnerCase.map(v => v.name) as string[];
          //     break;
          //   }
          //   case 'af_rtu':{
          //     contents[cateogryList[i].key as keyof typeof contents] = this.workAttachInfo.afterInstallRTU.length <= 0 ? null : this.workAttachInfo.afterInstallRTU.map(v => v.name) as string[];
          //     break;
          //   }
          //   case 'af_cb':{
          //     contents[cateogryList[i].key as keyof typeof contents] = this.workAttachInfo.afterInstallBraker.length <= 0 ? null : this.workAttachInfo.afterInstallBraker.map(v => v.name) as string[];
          //     break;
          //   }
          // }
        }
  

        
        // for(let i = 0; i < this.workAttachInfo.ownerAddr.length; i++){
        //   let newFileCount = 1;
        //   if(fileList.findIndex(v => v.name === this.workAttachInfo.ownerAddr[i].name) === -1){
        //     fileList.push(this.workAttachInfo.ownerAddr[i]);
        //   } else {
        //     let file = this.workAttachInfo.ownerAddr[i];
        //     let newFileBlob = file.slice(0, file.size, file.type);
        //     let newFile = new File([newFileBlob], `${file.name} - (${newFileCount})`, {type: file.type});
        //     fileList.push(newFile);
        //   }
        // }

        // for(let i = 0; i < this.workAttachInfo.beforeInstallAround.length; i++){
        //   if(fileList.findIndex(v => v.name === this.workAttachInfo.beforeInstallAround[i].name) === -1){
        //     fileList.push(this.workAttachInfo.beforeInstallAround[i]);
        //   }
        // }
        // for(let i = 0; i < this.workAttachInfo.afterInstallAround.length; i++){
        //   if(fileList.findIndex(v => v.name === this.workAttachInfo.afterInstallAround[i].name) === -1){
        //     fileList.push(this.workAttachInfo.afterInstallAround[i]);
        //   }
        // }
        // for(let i = 0; i < this.workAttachInfo.afterInstallInnerCase.length; i++){
        //   if(fileList.findIndex(v => v.name === this.workAttachInfo.afterInstallInnerCase[i].name) === -1){
        //     fileList.push(this.workAttachInfo.afterInstallInnerCase[i]);
        //   }
        // }
        // for(let i = 0; i < this.workAttachInfo.afterInstallRTU.length; i++){
        //   if(fileList.findIndex(v => v.name === this.workAttachInfo.afterInstallRTU[i].name) === -1){
        //     fileList.push(this.workAttachInfo.afterInstallRTU[i]);
        //   }
        // }

        // for(let i = 0; i < this.workAttachInfo.afterInstallBraker.length; i++){
        //   if(fileList.findIndex(v => v.name === this.workAttachInfo.afterInstallBraker[i].name) === -1){
        //     fileList.push(this.workAttachInfo.afterInstallBraker[i]);
        //   }
        // }
      
      }

        formData.append('contents', JSON.stringify(contents));
        
        fileList.forEach((file) => {
          formData.append('files', file);
        })

      try{
        const res = await useCustomFetch(`/api/v1/work/works/${kind}/report`, {method: Method.POST, query: {
          onmkind: onmkind,
          requestKind: requestKind,
          workSeq: workSeq,
          userSeq: userSeq,
          gpsLatitude: gpsLatitude,
          gpsLongitude: gpsLongitude
        }, body: formData});


        if(res.data.value === null){
          return res.error.value;
        }

        return true;
        
      }catch(e){
        Swal.fire({
      		html: `<p style="font-size: 15px;">오류가 발생하였습니다.<br>잠시 후 다시 시도해주세요.</p>`,
          confirmButtonColor: "#1949e9",
          confirmButtonText: "확인",
          width: "85%",
        })
      }
    },

    /**
     * 시리얼 번호 유호 검사
     * @param serialNumber rtu 시리얼번호
     * @returns 
     */
    async checkRtuSerialVersion(serialNumber: string){
      try{
        const res = await useCustomFetch('/api/v1/work/checkserial', {method: Method.GET, query: {
          serialNumber: serialNumber
        }});
  
        
        if(res.data.value === null){
          try{
            return JSON.parse(res.error.value?.data.message)[0].message; 
          }catch(e){
            if(res.error.value?.data.message.includes('유효하지 않은 시리얼번호입니다.')){
              return '유효하지 않은 시리얼번호입니다.'
            }
  
            return res.error.value?.data.message;
          }
        }
  
        return res.data.value;
      } catch(e){
        Swal.fire({
      		html: `<p style="font-size: 15px;">오류가 발생하였습니다.<br>잠시 후 다시 시도해주세요.</p>`,
          confirmButtonColor: "#1949e9",
          confirmButtonText: "확인",
          width: "85%",
        })
      }
    },

    /**
     * 작업요청 내역 타입 변경
     * @param workSeq 
     * @param requestkind 
     * @returns 
     */
    async changeWork(
      workSeq: number,
      requestkind: "chg_rtu" | "chg_owner",
      chg_workSeq?: number
    ){

      if(requestkind === 'chg_owner' && !chg_workSeq) return '변경할 설치자의 작업요청서 일련번호를 입력해주세요.'

      const userSeq = this.userSeq;

      try{
        const res = await useCustomFetch('/api/v1/work/changes', {method: Method.POST, query: {
          workSeq: workSeq,
          userSeq: userSeq,
          requestkind: requestkind,
          chg_workSeq: chg_workSeq
        }});

        if(res.data.value === null){
          try{
            return JSON.parse(res.error.value?.data.message)[0].message; 
          }catch(e){
            // if(res.error.value?.data.message.includes('유효하지 않은 시리얼번호입니다.')){
            //   return '유효하지 않은 시리얼번호입니다.'
            // }
  
            return res.error.value?.data.message;
          }
        }
  
        return res.data.value;

      } catch(e){
        Swal.fire({
      		html: `<p style="font-size: 15px;">오류가 발생하였습니다.<br>잠시 후 다시 시도해주세요.</p>`,
          confirmButtonColor: "#1949e9",
          confirmButtonText: "확인",
          width: "85%",
        })
      }
    },
    async rtuOwnerUninstall(
      workSeq: number,
      requestkind: 'chg_rtu' | 'chg_owner',
      serialNumber?: string,
      remove_workSeq?: number
    ){
      if(requestkind === 'chg_rtu' && !serialNumber) return '제거하려는 RTU 번호를 입력해주세요.';
      if(requestkind === 'chg_owner' && !remove_workSeq) return '제거하려는 설치자의 작업요청 번호를 입력해주세요';

      const userSeq = this.userSeq;
      const gpsLatitude = this.workerGpsInfo.latitude;
      const gpsLongitude = this.workerGpsInfo.longitude;

      try{
        const res = await useCustomFetch(`/api/v1/work/rtuownerUninstall`, {method: Method.POST, query:{
          workSeq: workSeq,
          userSeq: userSeq,
          requestkind: requestkind,
          serialNumber: serialNumber,
          remove_workSeq: remove_workSeq,
          gpsLatitude: gpsLatitude,
          gpsLongitude: gpsLongitude
        }})

        if(res.data.value === null){
          try{
            return JSON.parse(res.error.value?.data.message)[0].message; 
          }catch(e){
            // if(res.error.value?.data.message.includes('유효하지 않은 시리얼번호입니다.')){
            //   return '유효하지 않은 시리얼번호입니다.'
            // }
  
            return res.error.value?.data.message;
          }
        }
  
        return res.data.value;
      }catch(e){
        Swal.fire({
      		html: `<p style="font-size: 15px;">오류가 발생하였습니다.<br>관리자에게 문의해주세요.</p>`,
          confirmButtonColor: "#1949e9",
          confirmButtonText: "확인",
          width: "85%",
        })
      }

    
    }
  }
});
