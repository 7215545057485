export default {
  "empty-layout": () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/layouts/empty-layout.vue").then(m => m.default || m),
  footer: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/layouts/footer.vue").then(m => m.default || m),
  header: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/layouts/header.vue").then(m => m.default || m),
  mobile: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/layouts/mobile.vue").then(m => m.default || m),
  "non-header-footer": () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/layouts/non-header-footer.vue").then(m => m.default || m),
  "toc-footer": () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/layouts/toc-footer.vue").then(m => m.default || m),
  "toc-header": () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/layouts/toc-header.vue").then(m => m.default || m),
  "toc-non-side": () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/layouts/toc-non-side.vue").then(m => m.default || m),
  "toc-sidebar": () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/layouts/toc-sidebar.vue").then(m => m.default || m),
  "toc-tab": () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/layouts/toc-tab.vue").then(m => m.default || m),
  toc: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/layouts/toc.vue").then(m => m.default || m)
}