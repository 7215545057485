import { default as errorZafFpJkHj5Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/error.vue?macro=true";
import { default as indexLBj8PQnCgZMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/index.vue?macro=true";
import { default as error_45page32iBd1OsRHMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/error-page.vue?macro=true";
import { default as rtu_45100a_45guideKKUgBeOxJmMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/install-guide/rtu-100a-guide.vue?macro=true";
import { default as rtu_45200_45guideYXyLl0EKVUMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/install-guide/rtu-200-guide.vue?macro=true";
import { default as install_45guideWnuD5K0V10Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/install-guide.vue?macro=true";
import { default as installer_45headerM2gtLysmV4Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/installer-header.vue?macro=true";
import { default as all_45request7iuBvKhDsZMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/ownerwork/all-request.vue?macro=true";
import { default as check_45owner_45map_45kakaoqBM4ynfk66Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/ownerwork/check-owner-map-kakao.vue?macro=true";
import { default as check_45owner_45mapZ1UFgkGZISMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/ownerwork/check-owner-map.vue?macro=true";
import { default as check_45ownercWUkiO5TLtMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/ownerwork/check-owner.vue?macro=true";
import { default as history_45requestW6wBKniUjvMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/ownerwork/history-request.vue?macro=true";
import { default as historylistN8FA8GUWcjMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/ownerwork/historylist.vue?macro=true";
import { default as indexsQ7qWlLqVEMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/ownerwork/index.vue?macro=true";
import { default as ownerlist4FreTUQ6pDMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/ownerwork/ownerlist.vue?macro=true";
import { default as all_45policynNzuyHA6khMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/policy/all-policy.vue?macro=true";
import { default as indexAGBLvbgfmdMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/policy/index.vue?macro=true";
import { default as install_45policyEVoimn4fVtMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/policy/install-policy.vue?macro=true";
import { default as checkcw4WlqNbnLMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/rtu-connect/check.vue?macro=true";
import { default as connectyobcWkYXYyMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/rtu-connect/connect.vue?macro=true";
import { default as indexwsRDHxEE2iMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/rtu-connect/index.vue?macro=true";
import { default as rtu_45isonTCq9NWal34Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/rtu-connect/rtu-ison.vue?macro=true";
import { default as indexzTjDdfhHbrMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/rtu-install/index.vue?macro=true";
import { default as installda5J7xMYGUMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/rtu-install/install.vue?macro=true";
import { default as qr_45readerd6dF2kR3JVMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/rtu-install/qr-reader.vue?macro=true";
import { default as uninstallo1AqUA3rwDMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/rtu-install/uninstall.vue?macro=true";
import { default as cmpl_workinfonUy9eaemNdMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/workhistory/cmpl_workinfo.vue?macro=true";
import { default as indexoW4ynITgIfMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/workhistory/index.vue?macro=true";
import { default as workinfoCILirObqsgMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/workhistory/workinfo.vue?macro=true";
import { default as installeraS2slkBHMjMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer.vue?macro=true";
import { default as none_45contents_45pagep2EBZoJ2trMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/none-contents-page.vue?macro=true";
import { default as _250208AvNpz86cdzMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/old-privacy-policy/250208.vue?macro=true";
import { default as old_45privacy_45policyPWYaOoda34Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/old-privacy-policy.vue?macro=true";
import { default as privacy_45policybwaOJk208AMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/privacy-policy.vue?macro=true";
import { default as usage_45policyjApHAus8VuMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/usage-policy.vue?macro=true";
import { default as changePassOyRh3FV3CFMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/changePass.vue?macro=true";
import { default as _10_question053KSVdkOeMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/10_question.vue?macro=true";
import { default as _1_serviceOverview0XWZGyg15wMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/1_serviceOverview.vue?macro=true";
import { default as _2_statusOdVZhc8MjDMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/2_status.vue?macro=true";
import { default as _3_equipSelectQncNVoHRzVMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/3_equipSelect.vue?macro=true";
import { default as _4_instru0zfMXFgpZoMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/4_instru.vue?macro=true";
import { default as _5_equipInfoUJpzKMrz6KMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/5_equipInfo.vue?macro=true";
import { default as _6_eventWCz8xviolHMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/6_event.vue?macro=true";
import { default as _7_powerGenerationVueshnkf2rMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/7_powerGeneration.vue?macro=true";
import { default as _8_installInfoxAnx3KAFm8Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/8_installInfo.vue?macro=true";
import { default as _9_ownerInfoZk5yAIEM7BMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/9_ownerInfo.vue?macro=true";
import { default as indexhw7tRy7PhTMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/index.vue?macro=true";
import { default as _1_serviceOverviewaspxkDJwpNMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/1_serviceOverview.vue?macro=true";
import { default as _2_statusWBamWylyTNMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/2_status.vue?macro=true";
import { default as _3_monitoring7U9eXrMaZUMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/3_monitoring.vue?macro=true";
import { default as _4_equipInfosG2VuwP6V4Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/4_equipInfo.vue?macro=true";
import { default as _5_powerGenerationZczQ1nLXMvMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/5_powerGeneration.vue?macro=true";
import { default as _6_report3PPL4jTNdQMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/6_report.vue?macro=true";
import { default as _7_eventYCy9gb4f7QMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/7_event.vue?macro=true";
import { default as _8_ownerInfo1BWyFFP7yBMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/8_ownerInfo.vue?macro=true";
import { default as _9_questionEuoWs0tu41Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/9_question.vue?macro=true";
import { default as locationTerms1w4ILiAlSgMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/locationTerms.vue?macro=true";
import { default as mainBwRV7ocwx0Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/main.vue?macro=true";
import { default as locationTermsNFqnuSO68IMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/oldTerms/locationTerms.vue?macro=true";
import { default as _250314HmjLEtZsaHMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/oldTerms/personTerms/250314.vue?macro=true";
import { default as personTermsKj0KVvhSI4Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/oldTerms/personTerms.vue?macro=true";
import { default as _250314bqRJhOIq9QMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/oldTerms/useTerms/250314.vue?macro=true";
import { default as useTerms5bdMAuUfzeMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/oldTerms/useTerms.vue?macro=true";
import { default as oldTermsx03oUbgI4SMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/oldTerms.vue?macro=true";
import { default as openSource4V9yoLUxaIMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/openSource.vue?macro=true";
import { default as personTermscHydBgvvAUMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/personTerms.vue?macro=true";
import { default as questionjtgaDwlqbgMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/question.vue?macro=true";
import { default as useTerms0zWcVOkZkGMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/useTerms.vue?macro=true";
import { default as cs328NLeaIRmMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs.vue?macro=true";
import { default as equipList3hettRtgETMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/equipList.vue?macro=true";
import { default as equipMapbYmkQUIVAZMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/equipMap.vue?macro=true";
import { default as alarmETCfgMfnk0I0lMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/alarmETC.vue?macro=true";
import { default as alarmOBSc57YOW03DwMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/alarmOBS.vue?macro=true";
import { default as alarmRTUPVuQE1IZebMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/alarmRTU.vue?macro=true";
import { default as all2E426qotMzMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/all.vue?macro=true";
import { default as alarm3NkzNUb0FjMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm.vue?macro=true";
import { default as _91noticeid_93WhgqGoi6hIMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/notice/[noticeid].vue?macro=true";
import { default as indexSMXYr45LqzMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/notice/index.vue?macro=true";
import { default as noticeMRq18MDHwKMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/notice.vue?macro=true";
import { default as etcos9ujWybeqMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc.vue?macro=true";
import { default as indexBCNnKFRZGfMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/index.vue?macro=true";
import { default as contact_45changeJ7ReNmlLCpMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/contact-change.vue?macro=true";
import { default as main9Ih5phAdp5Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/main.vue?macro=true";
import { default as pw_45changeN3itoPzOR6Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/pw-change.vue?macro=true";
import { default as user_45deletelBxD5LHcOPMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/user-delete.vue?macro=true";
import { default as infoonigkWHBcJMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/info.vue?macro=true";
import { default as settingHvHjJbn83ZMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/setting.vue?macro=true";
import { default as siteListiqUdf2KbvyMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/siteList.vue?macro=true";
import { default as FailoverEditBsFbx8u9SiMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/FailoverEdit.vue?macro=true";
import { default as indexLrkih4nGh2Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstallInfo/index.vue?macro=true";
import { default as InstallInfo6jHKjzN0ElMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstallInfo.vue?macro=true";
import { default as chartpTzqB8U5jHMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstruInfo/chart.vue?macro=true";
import { default as nowCK9bTerOlTMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstruInfo/now.vue?macro=true";
import { default as InstruInfolx98yUQNw8Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstruInfo.vue?macro=true";
import { default as indexfN9cT6ichLMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/index.vue?macro=true";
import { default as essvxoLhDFApjMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess.vue?macro=true";
import { default as indexFCUpM9sFcVMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstallInfo/index.vue?macro=true";
import { default as InstallInfoJcmYX7595PMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstallInfo.vue?macro=true";
import { default as chartFB3Uag82bgMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstruInfo/chart.vue?macro=true";
import { default as nowBTF4glc7a5Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstruInfo/now.vue?macro=true";
import { default as InstruInfofdL6oZEVQZMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstruInfo.vue?macro=true";
import { default as indexSzXKG3dzFdMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/index.vue?macro=true";
import { default as fuelcell07wYnhRssfMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell.vue?macro=true";
import { default as installah9tf7qvfQMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstallInfo/install.vue?macro=true";
import { default as manager7NCKRpA73BMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstallInfo/manager.vue?macro=true";
import { default as InstallInfoAMvOvVtu6KMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstallInfo.vue?macro=true";
import { default as chart4Q4i5bHaq7Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstruInfo/chart.vue?macro=true";
import { default as now2ygj9qIt2FMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstruInfo/now.vue?macro=true";
import { default as InstruInfoOFbQOCGRPuMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstruInfo.vue?macro=true";
import { default as rtuFWV9qJLG5tMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/SpecInfo/rtu.vue?macro=true";
import { default as speca0EHRySIYIMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/SpecInfo/spec.vue?macro=true";
import { default as SpecInfo3YG26Jr8e4Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/SpecInfo.vue?macro=true";
import { default as geoBkGG3aEpFoMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo.vue?macro=true";
import { default as install3G51XbgQYLMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstallInfo/install.vue?macro=true";
import { default as managerPnyrUsPPKNMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstallInfo/manager.vue?macro=true";
import { default as InstallInfoYWYYavj1tfMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstallInfo.vue?macro=true";
import { default as chartJAnV5or0Y5Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstruInfo/chart.vue?macro=true";
import { default as nowZPwN54wA2FMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstruInfo/now.vue?macro=true";
import { default as InstruInfoHduMRcFHIcMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstruInfo.vue?macro=true";
import { default as rtu8cbAkX3it1Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/SpecInfo/rtu.vue?macro=true";
import { default as speckluILvFTD7Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/SpecInfo/spec.vue?macro=true";
import { default as SpecInfotwsBdxARj2Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/SpecInfo.vue?macro=true";
import { default as pv5qLbe8Ai2wMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv.vue?macro=true";
import { default as installSCaTzkNIrPMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstallInfo/install.vue?macro=true";
import { default as manageriwH9OApdZ8Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstallInfo/manager.vue?macro=true";
import { default as InstallInfodpSlQxE3P1Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstallInfo.vue?macro=true";
import { default as chartK5MmJxdU5CMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstruInfo/chart.vue?macro=true";
import { default as nowZkG0Jec6g4Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstruInfo/now.vue?macro=true";
import { default as InstruInfovuAterkACLMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstruInfo.vue?macro=true";
import { default as rtuNcBZBbWSJOMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/SpecInfo/rtu.vue?macro=true";
import { default as specZFJvCaOgsRMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/SpecInfo/spec.vue?macro=true";
import { default as SpecInfoD6AYy9NUMyMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/SpecInfo.vue?macro=true";
import { default as solarheatkV4R8sYNTJMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat.vue?macro=true";
import { default as indexsC1CjR9KTvMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstallInfo/index.vue?macro=true";
import { default as InstallInfofutPTkVxIbMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstallInfo.vue?macro=true";
import { default as chartzxU2aRJwkvMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstruInfo/chart.vue?macro=true";
import { default as nowNIAto4AZKrMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstruInfo/now.vue?macro=true";
import { default as InstruInfoiOJBf2XmrpMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstruInfo.vue?macro=true";
import { default as indexliLd9GwK8GMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/index.vue?macro=true";
import { default as wfgLFoWqZ1KhMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf.vue?macro=true";
import { default as indexXTUEUabMdFMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstallInfo/index.vue?macro=true";
import { default as InstallInfodvG3P7iwY4Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstallInfo.vue?macro=true";
import { default as charttCsoPfU93zMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstruInfo/chart.vue?macro=true";
import { default as nowlzEWStAEnIMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstruInfo/now.vue?macro=true";
import { default as InstruInfoy5nW8zU8UDMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstruInfo.vue?macro=true";
import { default as index1lj0U9UEvnMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/index.vue?macro=true";
import { default as esskGVtxdBj16Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess.vue?macro=true";
import { default as indexxafNxXBlG5Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstallInfo/index.vue?macro=true";
import { default as InstallInfoiX7iUmkz9XMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstallInfo.vue?macro=true";
import { default as chartLGGIH3SUQxMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstruInfo/chart.vue?macro=true";
import { default as nowhX8gZVYc9CMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstruInfo/now.vue?macro=true";
import { default as InstruInfoF5o4Qmf2cCMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstruInfo.vue?macro=true";
import { default as index6OaFIe8YNSMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/index.vue?macro=true";
import { default as fuelcellU3VOXjrSE5Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell.vue?macro=true";
import { default as install3dcdzYvB3uMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstallInfo/install.vue?macro=true";
import { default as managerUpFx8oopOCMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstallInfo/manager.vue?macro=true";
import { default as InstallInfoq8e6wEfwSwMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstallInfo.vue?macro=true";
import { default as chartdqUIllDfGoMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstruInfo/chart.vue?macro=true";
import { default as nowuuRHi5Cbl8Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstruInfo/now.vue?macro=true";
import { default as InstruInfoyAps4yblqyMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstruInfo.vue?macro=true";
import { default as indexv9QLGFhIjmMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/SpecInfo/index.vue?macro=true";
import { default as rtuFNGj15BRsyMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/SpecInfo/rtu.vue?macro=true";
import { default as specZDEjcel9s0Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/SpecInfo/spec.vue?macro=true";
import { default as geonXHs1nttQmMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo.vue?macro=true";
import { default as installFvOllJA9UsMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstallInfo/install.vue?macro=true";
import { default as managerYznrVXsHv4Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstallInfo/manager.vue?macro=true";
import { default as InstallInfokhisvpGFVBMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstallInfo.vue?macro=true";
import { default as chartKegVtAU57HMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstruInfo/chart.vue?macro=true";
import { default as nowG7TwmKY5mHMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstruInfo/now.vue?macro=true";
import { default as InstruInfotVs26OYr1XMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstruInfo.vue?macro=true";
import { default as indexUcXVaevefPMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/SpecInfo/index.vue?macro=true";
import { default as rtuBo8nHW0KZ3Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/SpecInfo/rtu.vue?macro=true";
import { default as specvBmaShwXeGMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/SpecInfo/spec.vue?macro=true";
import { default as pvybFqoNJyHwMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv.vue?macro=true";
import { default as install9xQIH8q7ugMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstallInfo/install.vue?macro=true";
import { default as managerfNeWK7xkeHMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstallInfo/manager.vue?macro=true";
import { default as InstallInfocENLXwYSoWMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstallInfo.vue?macro=true";
import { default as chartGIKOgYFRtrMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo/chart.vue?macro=true";
import { default as indexxIK1JeuIxRMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo/index.vue?macro=true";
import { default as nowMrsVsozVl2Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo/now.vue?macro=true";
import { default as InstruInfoOqt8CZ0KnOMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo.vue?macro=true";
import { default as indexcBEbg5BripMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/SpecInfo/index.vue?macro=true";
import { default as rtuIael3Dn3s1Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/SpecInfo/rtu.vue?macro=true";
import { default as specAHVBDd39vgMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/SpecInfo/spec.vue?macro=true";
import { default as solarheatxpRi0G3hA1Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat.vue?macro=true";
import { default as indexhpJ3BTEAIBMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstallInfo/index.vue?macro=true";
import { default as InstallInfo3X3bfu3wSMMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstallInfo.vue?macro=true";
import { default as chartqcW676dSvZMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstruInfo/chart.vue?macro=true";
import { default as nowhATuYOltfxMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstruInfo/now.vue?macro=true";
import { default as InstruInfoWqOSxnP5FuMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstruInfo.vue?macro=true";
import { default as indexPqXPdGTUVFMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/index.vue?macro=true";
import { default as wf1yfY8UK6U8Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf.vue?macro=true";
import { default as equipInfoqmgMkAKFmsMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo.vue?macro=true";
import { default as ALLmyaVREAR9qMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/ALL.vue?macro=true";
import { default as FailoverTuzKppv94JMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/Failover.vue?macro=true";
import { default as Failuren5l3YaB4HyMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/Failure.vue?macro=true";
import { default as all5HgCdekW0AMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/all.vue?macro=true";
import { default as eventHistoryOoz67gAKu4Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/eventHistory.vue?macro=true";
import { default as rtuEventrn8W8WsZjbMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/rtuEvent.vue?macro=true";
import { default as eventSXidJ8JwKxMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event.vue?macro=true";
import { default as essstPiNB2B51Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/ess.vue?macro=true";
import { default as fuelcellvfhYjcyiuYMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/fuelcell.vue?macro=true";
import { default as geoIYZM0KVWShMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/geo.vue?macro=true";
import { default as pvhrTLuIZkwrMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/pv.vue?macro=true";
import { default as solarheat6TeJqJnQ5rMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/solarheat.vue?macro=true";
import { default as wf5lIcSi4FHbMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/wf.vue?macro=true";
import { default as monitoringu6SDLqnRmAMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring.vue?macro=true";
import { default as monitoring_test0GpgywrHs4Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring_test.vue?macro=true";
import { default as InstallInfoEbiJAyLkdTMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/InstallInfo.vue?macro=true";
import { default as contracts9QszzzqpokMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/contracts.vue?macro=true";
import { default as geoSUjwOGwLN8Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators/geo.vue?macro=true";
import { default as pv8VlU2BHXpCMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators/pv.vue?macro=true";
import { default as solarheatOlnk64GalDMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators/solarheat.vue?macro=true";
import { default as indicatorsHbqtSdNnsMMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators.vue?macro=true";
import { default as indexCLN8tRcumdMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/installerConfirm/index.vue?macro=true";
import { default as installerConfirmr7T9jRy9KSMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/installerConfirm/installerConfirm.vue?macro=true";
import { default as installerConfirmDetailOn8aNPuqOSMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/installerConfirm/installerConfirmDetail.vue?macro=true";
import { default as installerConfirmRequestMY5FtgOjWBMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/installerConfirm/installerConfirmRequest.vue?macro=true";
import { default as menuUJ7KO4IStqMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/menu.vue?macro=true";
import { default as _91noticeid_93uPFhfDgMNKMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/menuToNotice/[noticeid].vue?macro=true";
import { default as ownerInfobXIeOVbvmNMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/ownerInfo.vue?macro=true";
import { default as predictedPowerwUcOv7TLg1Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/predictedPower.vue?macro=true";
import { default as siteInfoCRTMfU6GaLMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/siteInfo.vue?macro=true";
import { default as more1Hd7cX5ezqMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more.vue?macro=true";
import { default as ess28MEXn0W5DMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/ess.vue?macro=true";
import { default as fuelcellGK7LTb2R7wMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/fuelcell.vue?macro=true";
import { default as geoFY5B5J8tXyMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/geo.vue?macro=true";
import { default as pvOcmh1JCISKMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/pv.vue?macro=true";
import { default as solarheatVJ9WsDlXf4Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/solarheat.vue?macro=true";
import { default as wfNXvRa99z1jMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/wf.vue?macro=true";
import { default as powerGenerationr2TtwxD2YyMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration.vue?macro=true";
import { default as geoJmdMkFJEVXMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report/geo.vue?macro=true";
import { default as pvyVA5MhtozXMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report/pv.vue?macro=true";
import { default as solarheatRykZnjGSmwMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report/solarheat.vue?macro=true";
import { default as reportVsUsCfjWfHMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report.vue?macro=true";
import { default as pvhAzlIIoCsDMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/reportPDF/pv.vue?macro=true";
import { default as reportPDFjlYOT9ElOHMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/reportPDF.vue?macro=true";
import { default as statusWeIrVvUMiFMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/status.vue?macro=true";
import { default as viewspe6wvp3WOfMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views.vue?macro=true";
import { default as _91id_9382n8D2KWbnMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id].vue?macro=true";
import { default as doneFindid7L05zJL96FMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/finduser/doneFindid.vue?macro=true";
import { default as doneFindpassimKDGeQiEIMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/finduser/doneFindpass.vue?macro=true";
import { default as findidNEsXxzCoUhMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/finduser/findid.vue?macro=true";
import { default as findpass38NWYa7vUMMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/finduser/findpass.vue?macro=true";
import { default as finduserPzGTOcUy6qMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/finduser.vue?macro=true";
import { default as intropqYNajRUrZMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/intro.vue?macro=true";
import { default as loginvl6JjCDIkOMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/login.vue?macro=true";
import { default as logoutGKR8XzO2xoMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/logout.vue?macro=true";
import { default as personTermsMRls5N8spqMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/signin/personTerms.vue?macro=true";
import { default as termJHudLLWwUNMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/signin/term.vue?macro=true";
import { default as useTermslfusohqivGMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/signin/useTerms.vue?macro=true";
import { default as signinxfdf4lB7LqMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/signin.vue?macro=true";
import { default as userQ2GGffyRnUMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user.vue?macro=true";
import { default as mobileC5phNFcSf5Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile.vue?macro=true";
import { default as swagger_45uiGt6lpYFNLnMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/swagger-ui.vue?macro=true";
import { default as geo9dxP2fGjh1Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/test/geo.vue?macro=true";
import { default as loginhERYIKujusMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/test/login.vue?macro=true";
import { default as mainWZBdSkVehuMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/test/main.vue?macro=true";
import { default as qrcodePxZu17osFuMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/test/qrcode.vue?macro=true";
import { default as qrtestMLby6UYQK6Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/test/qrtest.vue?macro=true";
import { default as compare_45ownere0mKqZExHfMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/analysis/compare-owner.vue?macro=true";
import { default as compare_45region4qbBIZnVeHMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/analysis/compare-region.vue?macro=true";
import { default as geoSKthRD2TKpMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/analysis/component/geo.vue?macro=true";
import { default as ownerSearchModalc6zyO8Dt0OMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/analysis/component/ownerSearchModal.vue?macro=true";
import { default as pvXfkqTfbIkgMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/analysis/component/pv.vue?macro=true";
import { default as solarheatzwOF0udeioMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/analysis/component/solarheat.vue?macro=true";
import { default as owner9eDRpCJihQMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/analysis/owner.vue?macro=true";
import { default as analysiseIyuGLQRTWMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/analysis.vue?macro=true";
import { default as Energy_45ListKb0nfRupneMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/Dashboard/Energy-List.vue?macro=true";
import { default as card_45baseH5mDE7D9FhMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/Dashboard/card-base.vue?macro=true";
import { default as energy_45cardcpqDzZ5cXNMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/Dashboard/energy-card.vue?macro=true";
import { default as energy_45card2dckVdagq8dMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/Dashboard/energy-card2.vue?macro=true";
import { default as info_45cardjHVYiAdKcqMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/Dashboard/info-card.vue?macro=true";
import { default as link_45rate_45cardw1blvLAtWCMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/Dashboard/link-rate-card.vue?macro=true";
import { default as FileUploadnlbPf75jTyMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/FileUpload.vue?macro=true";
import { default as index0catWmmGhJMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/InstallInfo/index.vue?macro=true";
import { default as pvHExqipAIGmMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/InstallInfo/pv.vue?macro=true";
import { default as solarheats94edZl7CBMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/InstallInfo/solarheat.vue?macro=true";
import { default as pv8uAAuIANMRMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/InstruInfo/pv.vue?macro=true";
import { default as index30e7AE70YBMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/ManagerInfo/index.vue?macro=true";
import { default as pvvmUXuLs8T2Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/ManagerInfo/pv.vue?macro=true";
import { default as solarheatZr3ZtcJh3IMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/ManagerInfo/solarheat.vue?macro=true";
import { default as pvCLoFIXDo9PMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/ManagerInfo/pv.vue?macro=true";
import { default as solarheatXiwRqOHt9tMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/ManagerInfo/solarheat.vue?macro=true";
import { default as indextwMoTJCAR7Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/index.vue?macro=true";
import { default as pvSOCjqOhGnWMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/pv.vue?macro=true";
import { default as solarYnq78mqperMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/solar.vue?macro=true";
import { default as indexZ7APsd5uifMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/RtuInfo/index.vue?macro=true";
import { default as common_45code_45dropdown2Zg2w46GHvMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/SearchBox/common-code-dropdown.vue?macro=true";
import { default as date_45set_45reportgS3uUF8gEJMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/SearchBox/date-set-report.vue?macro=true";
import { default as date_45set32aERP3ELfMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/SearchBox/date-set.vue?macro=true";
import { default as region_45seteQSHzr3ZFJMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/SearchBox/region-set.vue?macro=true";
import { default as geo7XAwVyiSrSMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/SpecInfo/geo.vue?macro=true";
import { default as pvtJ7DjFgSdqMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/SpecInfo/pv.vue?macro=true";
import { default as solarheat6TRIrinmRXMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/SpecInfo/solarheat.vue?macro=true";
import { default as Table_45BoxxFmzBRoEQeMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/Table-Box.vue?macro=true";
import { default as api_45search_45boxwhuhyHms2GMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/api-search-box.vue?macro=true";
import { default as data_45tableuGMWixKZSkMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/data-table.vue?macro=true";
import { default as event_45search_45grid1bd0R3CfX6Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/event-search-grid.vue?macro=true";
import { default as info_45boxlPYtbG5BmPMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/info-box.vue?macro=true";
import { default as region_45selectcWhdu2mkpwMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/region-select.vue?macro=true";
import { default as search_45boxq6i2siG9CfMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/search-box.vue?macro=true";
import { default as table_45pagingej9y3PG55DMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/table-paging.vue?macro=true";
import { default as toc_45templateXyVKzG9CBtMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/template/toc-template.vue?macro=true";
import { default as test_45griddWFNOCvegDMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/test-grid.vue?macro=true";
import { default as _91id_93Scb4mVHiSsMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/faq/[id].vue?macro=true";
import { default as indexmTVz54HXAxMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/faq/index.vue?macro=true";
import { default as faq_companyUYgmKj36I6Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/faq_company.vue?macro=true";
import { default as login_45termsOg85xV1MXPMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/login-terms.vue?macro=true";
import { default as _91id_93W5xXCrnMS6Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/notice/[id].vue?macro=true";
import { default as indexwPgqHgYgGmMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/notice/index.vue?macro=true";
import { default as noticerroWhTqmTcMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/notice.vue?macro=true";
import { default as _91id_93TCjwxQ7MEqMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/qna/[id].vue?macro=true";
import { default as add5XhPABBv17Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/qna/add.vue?macro=true";
import { default as indexv6cLonrqYCMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/qna/index.vue?macro=true";
import { default as qnaw3lRppSKZ8Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/qna.vue?macro=true";
import { default as _91id_93hg8qgCe0cxMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/reference/[id].vue?macro=true";
import { default as index23OSzsfyhvMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/reference/index.vue?macro=true";
import { default as referenceXkx12jlGkcMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/reference.vue?macro=true";
import { default as terms16bO7O09xAyMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/terms-list/terms1.vue?macro=true";
import { default as terms2RK0G88rOjyMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/terms-list/terms2.vue?macro=true";
import { default as terms1Azp7POQxqMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/terms.vue?macro=true";
import { default as customerLdI5xojgyBMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer.vue?macro=true";
import { default as geo_45prod_45cardHTekrpUtwZMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-prod-card.vue?macro=true";
import { default as geo_45prod_45chart3rJaRBBxkRMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-prod-chart.vue?macro=true";
import { default as geo_45usage_45card1IvlCgwBlNMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-usage-card.vue?macro=true";
import { default as geo_45usage_45chartJhaAM1hjKtMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-usage-chart.vue?macro=true";
import { default as raw_45data_45chartxYcPE4Cn48Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/raw-data-chart.vue?macro=true";
import { default as raw_45data_45infoCKhniqmzY2Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/raw-data-info.vue?macro=true";
import { default as solarheat_45prod_45cardChX8WgF1dhMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-prod-card.vue?macro=true";
import { default as solarheat_45prod_45chartRqbCLneJ66Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-prod-chart.vue?macro=true";
import { default as solarheat_45usage_45carduhN2nwx8zrMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-usage-card.vue?macro=true";
import { default as solarheat_45usage_45chart539gqFY364Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-usage-chart.vue?macro=true";
import { default as geo_45prodOjJCoLhIpDMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo-prod.vue?macro=true";
import { default as geo_45usageuRo1CQK5zTMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo-usage.vue?macro=true";
import { default as geoRmv26WCKQaMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo.vue?macro=true";
import { default as pvINc1RfOSbGMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/pv.vue?macro=true";
import { default as solarheatYKop1msxV7Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/solarheat.vue?macro=true";
import { default as _91cid_93usIRYI3jjtMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid].vue?macro=true";
import { default as equip_45detailJUlE1xlaxLMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail.vue?macro=true";
import { default as equip_45list5IPsRQeTDEMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-list.vue?macro=true";
import { default as equip_45map0qNu7bjznOMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-map.vue?macro=true";
import { default as measure9eAynuFxd7Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/measure.vue?macro=true";
import { default as monitoring_45mapPvbez8sIIZMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/monitoring-map.vue?macro=true";
import { default as indexJhbUpN8z3iMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/power-generate/index.vue?macro=true";
import { default as power_45generateyc3sp04o3NMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/power-generate.vue?macro=true";
import { default as rtuErL3UqQg2UMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/rtu.vue?macro=true";
import { default as geo0JQjAA1Eb6Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[newsitename]/geo.vue?macro=true";
import { default as pvTW3q7nu06QMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[newsitename]/pv.vue?macro=true";
import { default as solarheatNUzEl1GRHZMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[newsitename]/solarheat.vue?macro=true";
import { default as geoWiDmzzD8anMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename]/geo.vue?macro=true";
import { default as pv6aITLOARqdMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename]/pv.vue?macro=true";
import { default as solarheat6VU7SYKQYoMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename]/solarheat.vue?macro=true";
import { default as _91sitename_93AT8O9SEt3JMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename].vue?macro=true";
import { default as indexVRF6XkuYN7Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/index.vue?macro=true";
import { default as _91newsitename_93P7x3gGNMmCMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/test/[newsitename].vue?macro=true";
import { default as sitepBvApbNQ09Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site.vue?macro=true";
import { default as equipxdBgeZS5YPMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip.vue?macro=true";
import { default as equipinfosxgBQRIjwYMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equipinfo.vue?macro=true";
import { default as error6cZOdA7NlsMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/error.vue?macro=true";
import { default as _91eventId_93bdjW5QdxiYMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/event/event-list/[eventId].vue?macro=true";
import { default as event_45list9hFEKuPqz4Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/event/event-list.vue?macro=true";
import { default as indexH0YbqBaCGbMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/event/index.vue?macro=true";
import { default as eventTGNnombWZYMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/event.vue?macro=true";
import { default as installrn9zNpNyVdMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/install.vue?macro=true";
import { default as addyTFOkw70q3Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/inventory/equip-company/add.vue?macro=true";
import { default as productfqq2KMufIvMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/inventory/equip-company/product.vue?macro=true";
import { default as inventoryGEP1749Fl4Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/inventory.vue?macro=true";
import { default as maintainenceYkCNoriGMkMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/maintainence.vue?macro=true";
import { default as addL6pj7nqV7DMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/company/add.vue?macro=true";
import { default as indexLYVvGEGkryMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/company/index.vue?macro=true";
import { default as companycazLMGpueVMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/company.vue?macro=true";
import { default as equip_45modelYouxvUmxxSMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/equip-model.vue?macro=true";
import { default as find_45ownerS1yPARnW0lMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/equip/add/find-owner.vue?macro=true";
import { default as geo_45specRwyS4og8hpMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/equip/add/geo-spec.vue?macro=true";
import { default as pv_45specmMBBIWaIqnMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/equip/add/pv-spec.vue?macro=true";
import { default as solarheat_45spec4ZVk9SVPyLMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/equip/add/solarheat-spec.vue?macro=true";
import { default as addQXbJKVQGfHMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/equip/add.vue?macro=true";
import { default as indexNDNwS1z8XbMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/equip/index.vue?macro=true";
import { default as equipN2RlGWCrgvMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/equip.vue?macro=true";
import { default as managementqA4kOkt7pjMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/management.vue?macro=true";
import { default as indexhrPDaN8qArMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/manager/index.vue?macro=true";
import { default as noticefh0JIMfIAwMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/notice.vue?macro=true";
import { default as addSYQgR6ztr3Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/owner/add.vue?macro=true";
import { default as indexACUh5ERKVhMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/owner/index.vue?macro=true";
import { default as ownerU5HZVWjUzMMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/owner.vue?macro=true";
import { default as indexWmsfCWai42Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/permit/index.vue?macro=true";
import { default as permitdIEz6UeXLyMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/permit.vue?macro=true";
import { default as operatorm97brQ6JYpMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/member/operator.vue?macro=true";
import { default as geo4oqtxC5s9FMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id]/geo.vue?macro=true";
import { default as pvUqe2e3tK8UMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id]/pv.vue?macro=true";
import { default as solarheatWdTlMqfd1HMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id]/solarheat.vue?macro=true";
import { default as _91id_937EKAAXlzo9Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id].vue?macro=true";
import { default as owner_45registWAJLlzgsbNMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/member/owner-member/owner-regist.vue?macro=true";
import { default as owner_45searchKUQC8mEoYzMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/member/owner-member/owner-search.vue?macro=true";
import { default as ownerzaVRzNd3wQMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/member/owner.vue?macro=true";
import { default as systemux2qfivpVQMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/member/system.vue?macro=true";
import { default as memberh4S5VokkLWMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/member.vue?macro=true";
import { default as alarm_45sendingabGdPtFZrpMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/message/alarm-sending.vue?macro=true";
import { default as email_45sendingXZYnFx0MIWMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/message/email-sending.vue?macro=true";
import { default as messageW3DOZuu0PcMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/message.vue?macro=true";
import { default as comprehensive_45slide5F2cBbLxbfMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/monitoring/comprehensive-slide.vue?macro=true";
import { default as dashboard6j2lHCPiyjMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/monitoring/dashboard.vue?macro=true";
import { default as equip_dashboardl9WEF69DKAMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/monitoring/equip_dashboard.vue?macro=true";
import { default as newDashboardZwb3OcqB2HMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/monitoring/newDashboard.vue?macro=true";
import { default as prod_45slide7PicKSCFNMMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/monitoring/prod-slide.vue?macro=true";
import { default as temp_newDashboardzF1BNErXNYMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/monitoring/temp_newDashboard.vue?macro=true";
import { default as monitoringoTfaEprwfpMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/monitoring.vue?macro=true";
import { default as not_45foundaWB2Nt3WNUMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/not-found.vue?macro=true";
import { default as operateh5g4gFKFu8Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/operate.vue?macro=true";
import { default as ownersgsK8Oz99eFMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient/owners.vue?macro=true";
import { default as prjkindsqyiWPc8LRJMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient/prjkinds.vue?macro=true";
import { default as regionsgTzssJpPXvMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient/regions.vue?macro=true";
import { default as analyze_45efficientHxQv8LLfqmMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient.vue?macro=true";
import { default as ownersivZ8YEnBy0Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/analyze-product/owners.vue?macro=true";
import { default as prjkindsy1xtTSNrZOMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/analyze-product/prjkinds.vue?macro=true";
import { default as regionssKmJtAKjrxMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/analyze-product/regions.vue?macro=true";
import { default as analyze_45product2Xurh1hWynMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/analyze-product.vue?macro=true";
import { default as env_45factor1asZjtSMd7Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/env-factor.vue?macro=true";
import { default as event4c8sALwxrPMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/event.vue?macro=true";
import { default as predictVeWoFHoTdtMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/predict.vue?macro=true";
import { default as productljYkeyQgcZMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/product.vue?macro=true";
import { default as reportgHonmib4dSMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report.vue?macro=true";
import { default as app_45versionnGHCzApvuEMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/service-info/app-version.vue?macro=true";
import { default as service_45infobdDCmdWzGuMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/service-info.vue?macro=true";
import { default as testqj7t5VVLcnMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/test.vue?macro=true";
import { default as manager_45infoTbWDPChZ1IMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/manager-info.vue?macro=true";
import { default as confirm_45modalJbiXsdnZcjMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/modal/confirm-modal.vue?macro=true";
import { default as manager_45add_45modalvQzH26Sh8AMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/modal/manager-add-modal.vue?macro=true";
import { default as manager_45modify_45modalbFrMZTHUGnMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/modal/manager-modify-modal.vue?macro=true";
import { default as set_45manager_45all_45modalguvMPNhGxAMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/modal/set-manager-all-modal.vue?macro=true";
import { default as set_45manager_45select_45modalS5XRWdxSucMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/modal/set-manager-select-modal.vue?macro=true";
import { default as set_45managerG78tZENVZKMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/set-manager.vue?macro=true";
import { default as indexeLBkymWUFoMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/user-as-management/index.vue?macro=true";
import { default as infoa3YAGevWqHMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/user-as-management/info.vue?macro=true";
import { default as user_45as_45managementtQtrenodYBMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/user-as-management.vue?macro=true";
import { default as indexwreLD1VCRuMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/user-as-request/index.vue?macro=true";
import { default as infoxPN7VngmV7Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/user-as-request/info.vue?macro=true";
import { default as user_45as_45requesthgbOywvUB6Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/user-as-request.vue?macro=true";
import { default as user_45infobKri70uBqvMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/user-info.vue?macro=true";
import { default as user_45settingKJGdUu0rSBMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/user-setting.vue?macro=true";
import { default as user_45servicewpOfCxmWWTMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service.vue?macro=true";
import { default as find_45id8t01s3Nqf7Meta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user/find-id.vue?macro=true";
import { default as indexNtfpJRXKkVMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user/index.vue?macro=true";
import { default as login9gYaMVyk7ZMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user/login.vue?macro=true";
import { default as tocYDOohQW1wLMeta } from "/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc.vue?macro=true";
export default [
  {
    name: errorZafFpJkHj5Meta?.name ?? "error",
    path: errorZafFpJkHj5Meta?.path ?? "/error",
    meta: errorZafFpJkHj5Meta || {},
    alias: errorZafFpJkHj5Meta?.alias || [],
    redirect: errorZafFpJkHj5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/error.vue").then(m => m.default || m)
  },
  {
    name: indexLBj8PQnCgZMeta?.name ?? "index",
    path: indexLBj8PQnCgZMeta?.path ?? "/",
    meta: indexLBj8PQnCgZMeta || {},
    alias: indexLBj8PQnCgZMeta?.alias || [],
    redirect: indexLBj8PQnCgZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: mobileC5phNFcSf5Meta?.name ?? "mobile",
    path: mobileC5phNFcSf5Meta?.path ?? "/mobile",
    children: [
  {
    name: error_45page32iBd1OsRHMeta?.name ?? "mobile-error-page",
    path: error_45page32iBd1OsRHMeta?.path ?? "error-page",
    meta: error_45page32iBd1OsRHMeta || {},
    alias: error_45page32iBd1OsRHMeta?.alias || [],
    redirect: error_45page32iBd1OsRHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/error-page.vue").then(m => m.default || m)
  },
  {
    name: install_45guideWnuD5K0V10Meta?.name ?? "mobile-install-guide",
    path: install_45guideWnuD5K0V10Meta?.path ?? "install-guide",
    children: [
  {
    name: rtu_45100a_45guideKKUgBeOxJmMeta?.name ?? "mobile-install-guide-rtu-100a-guide",
    path: rtu_45100a_45guideKKUgBeOxJmMeta?.path ?? "rtu-100a-guide",
    meta: rtu_45100a_45guideKKUgBeOxJmMeta || {},
    alias: rtu_45100a_45guideKKUgBeOxJmMeta?.alias || [],
    redirect: rtu_45100a_45guideKKUgBeOxJmMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/install-guide/rtu-100a-guide.vue").then(m => m.default || m)
  },
  {
    name: rtu_45200_45guideYXyLl0EKVUMeta?.name ?? "mobile-install-guide-rtu-200-guide",
    path: rtu_45200_45guideYXyLl0EKVUMeta?.path ?? "rtu-200-guide",
    meta: rtu_45200_45guideYXyLl0EKVUMeta || {},
    alias: rtu_45200_45guideYXyLl0EKVUMeta?.alias || [],
    redirect: rtu_45200_45guideYXyLl0EKVUMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/install-guide/rtu-200-guide.vue").then(m => m.default || m)
  }
],
    meta: install_45guideWnuD5K0V10Meta || {},
    alias: install_45guideWnuD5K0V10Meta?.alias || [],
    redirect: install_45guideWnuD5K0V10Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/install-guide.vue").then(m => m.default || m)
  },
  {
    name: installeraS2slkBHMjMeta?.name ?? "mobile-installer",
    path: installeraS2slkBHMjMeta?.path ?? "installer",
    children: [
  {
    name: installer_45headerM2gtLysmV4Meta?.name ?? "mobile-installer-installer-header",
    path: installer_45headerM2gtLysmV4Meta?.path ?? "installer-header",
    meta: installer_45headerM2gtLysmV4Meta || {},
    alias: installer_45headerM2gtLysmV4Meta?.alias || [],
    redirect: installer_45headerM2gtLysmV4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/installer-header.vue").then(m => m.default || m)
  },
  {
    name: all_45request7iuBvKhDsZMeta?.name ?? "mobile-installer-ownerwork-all-request",
    path: all_45request7iuBvKhDsZMeta?.path ?? "ownerwork/all-request",
    meta: all_45request7iuBvKhDsZMeta || {},
    alias: all_45request7iuBvKhDsZMeta?.alias || [],
    redirect: all_45request7iuBvKhDsZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/ownerwork/all-request.vue").then(m => m.default || m)
  },
  {
    name: check_45owner_45map_45kakaoqBM4ynfk66Meta?.name ?? "mobile-installer-ownerwork-check-owner-map-kakao",
    path: check_45owner_45map_45kakaoqBM4ynfk66Meta?.path ?? "ownerwork/check-owner-map-kakao",
    meta: check_45owner_45map_45kakaoqBM4ynfk66Meta || {},
    alias: check_45owner_45map_45kakaoqBM4ynfk66Meta?.alias || [],
    redirect: check_45owner_45map_45kakaoqBM4ynfk66Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/ownerwork/check-owner-map-kakao.vue").then(m => m.default || m)
  },
  {
    name: check_45owner_45mapZ1UFgkGZISMeta?.name ?? "mobile-installer-ownerwork-check-owner-map",
    path: check_45owner_45mapZ1UFgkGZISMeta?.path ?? "ownerwork/check-owner-map",
    meta: check_45owner_45mapZ1UFgkGZISMeta || {},
    alias: check_45owner_45mapZ1UFgkGZISMeta?.alias || [],
    redirect: check_45owner_45mapZ1UFgkGZISMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/ownerwork/check-owner-map.vue").then(m => m.default || m)
  },
  {
    name: check_45ownercWUkiO5TLtMeta?.name ?? "mobile-installer-ownerwork-check-owner",
    path: check_45ownercWUkiO5TLtMeta?.path ?? "ownerwork/check-owner",
    meta: check_45ownercWUkiO5TLtMeta || {},
    alias: check_45ownercWUkiO5TLtMeta?.alias || [],
    redirect: check_45ownercWUkiO5TLtMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/ownerwork/check-owner.vue").then(m => m.default || m)
  },
  {
    name: history_45requestW6wBKniUjvMeta?.name ?? "mobile-installer-ownerwork-history-request",
    path: history_45requestW6wBKniUjvMeta?.path ?? "ownerwork/history-request",
    meta: history_45requestW6wBKniUjvMeta || {},
    alias: history_45requestW6wBKniUjvMeta?.alias || [],
    redirect: history_45requestW6wBKniUjvMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/ownerwork/history-request.vue").then(m => m.default || m)
  },
  {
    name: historylistN8FA8GUWcjMeta?.name ?? "mobile-installer-ownerwork-historylist",
    path: historylistN8FA8GUWcjMeta?.path ?? "ownerwork/historylist",
    meta: historylistN8FA8GUWcjMeta || {},
    alias: historylistN8FA8GUWcjMeta?.alias || [],
    redirect: historylistN8FA8GUWcjMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/ownerwork/historylist.vue").then(m => m.default || m)
  },
  {
    name: indexsQ7qWlLqVEMeta?.name ?? "mobile-installer-ownerwork",
    path: indexsQ7qWlLqVEMeta?.path ?? "ownerwork",
    meta: indexsQ7qWlLqVEMeta || {},
    alias: indexsQ7qWlLqVEMeta?.alias || [],
    redirect: indexsQ7qWlLqVEMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/ownerwork/index.vue").then(m => m.default || m)
  },
  {
    name: ownerlist4FreTUQ6pDMeta?.name ?? "mobile-installer-ownerwork-ownerlist",
    path: ownerlist4FreTUQ6pDMeta?.path ?? "ownerwork/ownerlist",
    meta: ownerlist4FreTUQ6pDMeta || {},
    alias: ownerlist4FreTUQ6pDMeta?.alias || [],
    redirect: ownerlist4FreTUQ6pDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/ownerwork/ownerlist.vue").then(m => m.default || m)
  },
  {
    name: all_45policynNzuyHA6khMeta?.name ?? "mobile-installer-policy-all-policy",
    path: all_45policynNzuyHA6khMeta?.path ?? "policy/all-policy",
    meta: all_45policynNzuyHA6khMeta || {},
    alias: all_45policynNzuyHA6khMeta?.alias || [],
    redirect: all_45policynNzuyHA6khMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/policy/all-policy.vue").then(m => m.default || m)
  },
  {
    name: indexAGBLvbgfmdMeta?.name ?? "mobile-installer-policy",
    path: indexAGBLvbgfmdMeta?.path ?? "policy",
    meta: indexAGBLvbgfmdMeta || {},
    alias: indexAGBLvbgfmdMeta?.alias || [],
    redirect: indexAGBLvbgfmdMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/policy/index.vue").then(m => m.default || m)
  },
  {
    name: install_45policyEVoimn4fVtMeta?.name ?? "mobile-installer-policy-install-policy",
    path: install_45policyEVoimn4fVtMeta?.path ?? "policy/install-policy",
    meta: install_45policyEVoimn4fVtMeta || {},
    alias: install_45policyEVoimn4fVtMeta?.alias || [],
    redirect: install_45policyEVoimn4fVtMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/policy/install-policy.vue").then(m => m.default || m)
  },
  {
    name: checkcw4WlqNbnLMeta?.name ?? "mobile-installer-rtu-connect-check",
    path: checkcw4WlqNbnLMeta?.path ?? "rtu-connect/check",
    meta: checkcw4WlqNbnLMeta || {},
    alias: checkcw4WlqNbnLMeta?.alias || [],
    redirect: checkcw4WlqNbnLMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/rtu-connect/check.vue").then(m => m.default || m)
  },
  {
    name: connectyobcWkYXYyMeta?.name ?? "mobile-installer-rtu-connect-connect",
    path: connectyobcWkYXYyMeta?.path ?? "rtu-connect/connect",
    meta: connectyobcWkYXYyMeta || {},
    alias: connectyobcWkYXYyMeta?.alias || [],
    redirect: connectyobcWkYXYyMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/rtu-connect/connect.vue").then(m => m.default || m)
  },
  {
    name: indexwsRDHxEE2iMeta?.name ?? "mobile-installer-rtu-connect",
    path: indexwsRDHxEE2iMeta?.path ?? "rtu-connect",
    meta: indexwsRDHxEE2iMeta || {},
    alias: indexwsRDHxEE2iMeta?.alias || [],
    redirect: indexwsRDHxEE2iMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/rtu-connect/index.vue").then(m => m.default || m)
  },
  {
    name: rtu_45isonTCq9NWal34Meta?.name ?? "mobile-installer-rtu-connect-rtu-ison",
    path: rtu_45isonTCq9NWal34Meta?.path ?? "rtu-connect/rtu-ison",
    meta: rtu_45isonTCq9NWal34Meta || {},
    alias: rtu_45isonTCq9NWal34Meta?.alias || [],
    redirect: rtu_45isonTCq9NWal34Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/rtu-connect/rtu-ison.vue").then(m => m.default || m)
  },
  {
    name: indexzTjDdfhHbrMeta?.name ?? "mobile-installer-rtu-install",
    path: indexzTjDdfhHbrMeta?.path ?? "rtu-install",
    meta: indexzTjDdfhHbrMeta || {},
    alias: indexzTjDdfhHbrMeta?.alias || [],
    redirect: indexzTjDdfhHbrMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/rtu-install/index.vue").then(m => m.default || m)
  },
  {
    name: installda5J7xMYGUMeta?.name ?? "mobile-installer-rtu-install-install",
    path: installda5J7xMYGUMeta?.path ?? "rtu-install/install",
    meta: installda5J7xMYGUMeta || {},
    alias: installda5J7xMYGUMeta?.alias || [],
    redirect: installda5J7xMYGUMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/rtu-install/install.vue").then(m => m.default || m)
  },
  {
    name: qr_45readerd6dF2kR3JVMeta?.name ?? "mobile-installer-rtu-install-qr-reader",
    path: qr_45readerd6dF2kR3JVMeta?.path ?? "rtu-install/qr-reader",
    meta: qr_45readerd6dF2kR3JVMeta || {},
    alias: qr_45readerd6dF2kR3JVMeta?.alias || [],
    redirect: qr_45readerd6dF2kR3JVMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/rtu-install/qr-reader.vue").then(m => m.default || m)
  },
  {
    name: uninstallo1AqUA3rwDMeta?.name ?? "mobile-installer-rtu-install-uninstall",
    path: uninstallo1AqUA3rwDMeta?.path ?? "rtu-install/uninstall",
    meta: uninstallo1AqUA3rwDMeta || {},
    alias: uninstallo1AqUA3rwDMeta?.alias || [],
    redirect: uninstallo1AqUA3rwDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/rtu-install/uninstall.vue").then(m => m.default || m)
  },
  {
    name: cmpl_workinfonUy9eaemNdMeta?.name ?? "mobile-installer-workhistory-cmpl_workinfo",
    path: cmpl_workinfonUy9eaemNdMeta?.path ?? "workhistory/cmpl_workinfo",
    meta: cmpl_workinfonUy9eaemNdMeta || {},
    alias: cmpl_workinfonUy9eaemNdMeta?.alias || [],
    redirect: cmpl_workinfonUy9eaemNdMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/workhistory/cmpl_workinfo.vue").then(m => m.default || m)
  },
  {
    name: indexoW4ynITgIfMeta?.name ?? "mobile-installer-workhistory",
    path: indexoW4ynITgIfMeta?.path ?? "workhistory",
    meta: indexoW4ynITgIfMeta || {},
    alias: indexoW4ynITgIfMeta?.alias || [],
    redirect: indexoW4ynITgIfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/workhistory/index.vue").then(m => m.default || m)
  },
  {
    name: workinfoCILirObqsgMeta?.name ?? "mobile-installer-workhistory-workinfo",
    path: workinfoCILirObqsgMeta?.path ?? "workhistory/workinfo",
    meta: workinfoCILirObqsgMeta || {},
    alias: workinfoCILirObqsgMeta?.alias || [],
    redirect: workinfoCILirObqsgMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer/workhistory/workinfo.vue").then(m => m.default || m)
  }
],
    meta: installeraS2slkBHMjMeta || {},
    alias: installeraS2slkBHMjMeta?.alias || [],
    redirect: installeraS2slkBHMjMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/installer.vue").then(m => m.default || m)
  },
  {
    name: none_45contents_45pagep2EBZoJ2trMeta?.name ?? "mobile-none-contents-page",
    path: none_45contents_45pagep2EBZoJ2trMeta?.path ?? "none-contents-page",
    meta: none_45contents_45pagep2EBZoJ2trMeta || {},
    alias: none_45contents_45pagep2EBZoJ2trMeta?.alias || [],
    redirect: none_45contents_45pagep2EBZoJ2trMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/none-contents-page.vue").then(m => m.default || m)
  },
  {
    name: old_45privacy_45policyPWYaOoda34Meta?.name ?? "mobile-old-privacy-policy",
    path: old_45privacy_45policyPWYaOoda34Meta?.path ?? "old-privacy-policy",
    children: [
  {
    name: _250208AvNpz86cdzMeta?.name ?? "mobile-old-privacy-policy-250208",
    path: _250208AvNpz86cdzMeta?.path ?? "250208",
    meta: _250208AvNpz86cdzMeta || {},
    alias: _250208AvNpz86cdzMeta?.alias || [],
    redirect: _250208AvNpz86cdzMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/old-privacy-policy/250208.vue").then(m => m.default || m)
  }
],
    meta: old_45privacy_45policyPWYaOoda34Meta || {},
    alias: old_45privacy_45policyPWYaOoda34Meta?.alias || [],
    redirect: old_45privacy_45policyPWYaOoda34Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/old-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policybwaOJk208AMeta?.name ?? "mobile-privacy-policy",
    path: privacy_45policybwaOJk208AMeta?.path ?? "privacy-policy",
    meta: privacy_45policybwaOJk208AMeta || {},
    alias: privacy_45policybwaOJk208AMeta?.alias || [],
    redirect: privacy_45policybwaOJk208AMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: usage_45policyjApHAus8VuMeta?.name ?? "mobile-usage-policy",
    path: usage_45policyjApHAus8VuMeta?.path ?? "usage-policy",
    meta: usage_45policyjApHAus8VuMeta || {},
    alias: usage_45policyjApHAus8VuMeta?.alias || [],
    redirect: usage_45policyjApHAus8VuMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/usage-policy.vue").then(m => m.default || m)
  },
  {
    name: userQ2GGffyRnUMeta?.name ?? "mobile-user",
    path: userQ2GGffyRnUMeta?.path ?? "user",
    children: [
  {
    path: _91id_9382n8D2KWbnMeta?.path ?? ":id",
    children: [
  {
    name: changePassOyRh3FV3CFMeta?.name ?? "mobile-user-id-changePass",
    path: changePassOyRh3FV3CFMeta?.path ?? "changePass",
    meta: changePassOyRh3FV3CFMeta || {},
    alias: changePassOyRh3FV3CFMeta?.alias || [],
    redirect: changePassOyRh3FV3CFMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/changePass.vue").then(m => m.default || m)
  },
  {
    name: cs328NLeaIRmMeta?.name ?? "mobile-user-id-cs",
    path: cs328NLeaIRmMeta?.path ?? "cs",
    children: [
  {
    name: _10_question053KSVdkOeMeta?.name ?? "mobile-user-id-cs-guide-company-10_question",
    path: _10_question053KSVdkOeMeta?.path ?? "guide/company/10_question",
    meta: _10_question053KSVdkOeMeta || {},
    alias: _10_question053KSVdkOeMeta?.alias || [],
    redirect: _10_question053KSVdkOeMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/10_question.vue").then(m => m.default || m)
  },
  {
    name: _1_serviceOverview0XWZGyg15wMeta?.name ?? "mobile-user-id-cs-guide-company-1_serviceOverview",
    path: _1_serviceOverview0XWZGyg15wMeta?.path ?? "guide/company/1_serviceOverview",
    meta: _1_serviceOverview0XWZGyg15wMeta || {},
    alias: _1_serviceOverview0XWZGyg15wMeta?.alias || [],
    redirect: _1_serviceOverview0XWZGyg15wMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/1_serviceOverview.vue").then(m => m.default || m)
  },
  {
    name: _2_statusOdVZhc8MjDMeta?.name ?? "mobile-user-id-cs-guide-company-2_status",
    path: _2_statusOdVZhc8MjDMeta?.path ?? "guide/company/2_status",
    meta: _2_statusOdVZhc8MjDMeta || {},
    alias: _2_statusOdVZhc8MjDMeta?.alias || [],
    redirect: _2_statusOdVZhc8MjDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/2_status.vue").then(m => m.default || m)
  },
  {
    name: _3_equipSelectQncNVoHRzVMeta?.name ?? "mobile-user-id-cs-guide-company-3_equipSelect",
    path: _3_equipSelectQncNVoHRzVMeta?.path ?? "guide/company/3_equipSelect",
    meta: _3_equipSelectQncNVoHRzVMeta || {},
    alias: _3_equipSelectQncNVoHRzVMeta?.alias || [],
    redirect: _3_equipSelectQncNVoHRzVMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/3_equipSelect.vue").then(m => m.default || m)
  },
  {
    name: _4_instru0zfMXFgpZoMeta?.name ?? "mobile-user-id-cs-guide-company-4_instru",
    path: _4_instru0zfMXFgpZoMeta?.path ?? "guide/company/4_instru",
    meta: _4_instru0zfMXFgpZoMeta || {},
    alias: _4_instru0zfMXFgpZoMeta?.alias || [],
    redirect: _4_instru0zfMXFgpZoMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/4_instru.vue").then(m => m.default || m)
  },
  {
    name: _5_equipInfoUJpzKMrz6KMeta?.name ?? "mobile-user-id-cs-guide-company-5_equipInfo",
    path: _5_equipInfoUJpzKMrz6KMeta?.path ?? "guide/company/5_equipInfo",
    meta: _5_equipInfoUJpzKMrz6KMeta || {},
    alias: _5_equipInfoUJpzKMrz6KMeta?.alias || [],
    redirect: _5_equipInfoUJpzKMrz6KMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/5_equipInfo.vue").then(m => m.default || m)
  },
  {
    name: _6_eventWCz8xviolHMeta?.name ?? "mobile-user-id-cs-guide-company-6_event",
    path: _6_eventWCz8xviolHMeta?.path ?? "guide/company/6_event",
    meta: _6_eventWCz8xviolHMeta || {},
    alias: _6_eventWCz8xviolHMeta?.alias || [],
    redirect: _6_eventWCz8xviolHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/6_event.vue").then(m => m.default || m)
  },
  {
    name: _7_powerGenerationVueshnkf2rMeta?.name ?? "mobile-user-id-cs-guide-company-7_powerGeneration",
    path: _7_powerGenerationVueshnkf2rMeta?.path ?? "guide/company/7_powerGeneration",
    meta: _7_powerGenerationVueshnkf2rMeta || {},
    alias: _7_powerGenerationVueshnkf2rMeta?.alias || [],
    redirect: _7_powerGenerationVueshnkf2rMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/7_powerGeneration.vue").then(m => m.default || m)
  },
  {
    name: _8_installInfoxAnx3KAFm8Meta?.name ?? "mobile-user-id-cs-guide-company-8_installInfo",
    path: _8_installInfoxAnx3KAFm8Meta?.path ?? "guide/company/8_installInfo",
    meta: _8_installInfoxAnx3KAFm8Meta || {},
    alias: _8_installInfoxAnx3KAFm8Meta?.alias || [],
    redirect: _8_installInfoxAnx3KAFm8Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/8_installInfo.vue").then(m => m.default || m)
  },
  {
    name: _9_ownerInfoZk5yAIEM7BMeta?.name ?? "mobile-user-id-cs-guide-company-9_ownerInfo",
    path: _9_ownerInfoZk5yAIEM7BMeta?.path ?? "guide/company/9_ownerInfo",
    meta: _9_ownerInfoZk5yAIEM7BMeta || {},
    alias: _9_ownerInfoZk5yAIEM7BMeta?.alias || [],
    redirect: _9_ownerInfoZk5yAIEM7BMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/9_ownerInfo.vue").then(m => m.default || m)
  },
  {
    name: indexhw7tRy7PhTMeta?.name ?? "mobile-user-id-cs-guide",
    path: indexhw7tRy7PhTMeta?.path ?? "guide",
    meta: indexhw7tRy7PhTMeta || {},
    alias: indexhw7tRy7PhTMeta?.alias || [],
    redirect: indexhw7tRy7PhTMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/index.vue").then(m => m.default || m)
  },
  {
    name: _1_serviceOverviewaspxkDJwpNMeta?.name ?? "mobile-user-id-cs-guide-owner-1_serviceOverview",
    path: _1_serviceOverviewaspxkDJwpNMeta?.path ?? "guide/owner/1_serviceOverview",
    meta: _1_serviceOverviewaspxkDJwpNMeta || {},
    alias: _1_serviceOverviewaspxkDJwpNMeta?.alias || [],
    redirect: _1_serviceOverviewaspxkDJwpNMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/1_serviceOverview.vue").then(m => m.default || m)
  },
  {
    name: _2_statusWBamWylyTNMeta?.name ?? "mobile-user-id-cs-guide-owner-2_status",
    path: _2_statusWBamWylyTNMeta?.path ?? "guide/owner/2_status",
    meta: _2_statusWBamWylyTNMeta || {},
    alias: _2_statusWBamWylyTNMeta?.alias || [],
    redirect: _2_statusWBamWylyTNMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/2_status.vue").then(m => m.default || m)
  },
  {
    name: _3_monitoring7U9eXrMaZUMeta?.name ?? "mobile-user-id-cs-guide-owner-3_monitoring",
    path: _3_monitoring7U9eXrMaZUMeta?.path ?? "guide/owner/3_monitoring",
    meta: _3_monitoring7U9eXrMaZUMeta || {},
    alias: _3_monitoring7U9eXrMaZUMeta?.alias || [],
    redirect: _3_monitoring7U9eXrMaZUMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/3_monitoring.vue").then(m => m.default || m)
  },
  {
    name: _4_equipInfosG2VuwP6V4Meta?.name ?? "mobile-user-id-cs-guide-owner-4_equipInfo",
    path: _4_equipInfosG2VuwP6V4Meta?.path ?? "guide/owner/4_equipInfo",
    meta: _4_equipInfosG2VuwP6V4Meta || {},
    alias: _4_equipInfosG2VuwP6V4Meta?.alias || [],
    redirect: _4_equipInfosG2VuwP6V4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/4_equipInfo.vue").then(m => m.default || m)
  },
  {
    name: _5_powerGenerationZczQ1nLXMvMeta?.name ?? "mobile-user-id-cs-guide-owner-5_powerGeneration",
    path: _5_powerGenerationZczQ1nLXMvMeta?.path ?? "guide/owner/5_powerGeneration",
    meta: _5_powerGenerationZczQ1nLXMvMeta || {},
    alias: _5_powerGenerationZczQ1nLXMvMeta?.alias || [],
    redirect: _5_powerGenerationZczQ1nLXMvMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/5_powerGeneration.vue").then(m => m.default || m)
  },
  {
    name: _6_report3PPL4jTNdQMeta?.name ?? "mobile-user-id-cs-guide-owner-6_report",
    path: _6_report3PPL4jTNdQMeta?.path ?? "guide/owner/6_report",
    meta: _6_report3PPL4jTNdQMeta || {},
    alias: _6_report3PPL4jTNdQMeta?.alias || [],
    redirect: _6_report3PPL4jTNdQMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/6_report.vue").then(m => m.default || m)
  },
  {
    name: _7_eventYCy9gb4f7QMeta?.name ?? "mobile-user-id-cs-guide-owner-7_event",
    path: _7_eventYCy9gb4f7QMeta?.path ?? "guide/owner/7_event",
    meta: _7_eventYCy9gb4f7QMeta || {},
    alias: _7_eventYCy9gb4f7QMeta?.alias || [],
    redirect: _7_eventYCy9gb4f7QMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/7_event.vue").then(m => m.default || m)
  },
  {
    name: _8_ownerInfo1BWyFFP7yBMeta?.name ?? "mobile-user-id-cs-guide-owner-8_ownerInfo",
    path: _8_ownerInfo1BWyFFP7yBMeta?.path ?? "guide/owner/8_ownerInfo",
    meta: _8_ownerInfo1BWyFFP7yBMeta || {},
    alias: _8_ownerInfo1BWyFFP7yBMeta?.alias || [],
    redirect: _8_ownerInfo1BWyFFP7yBMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/8_ownerInfo.vue").then(m => m.default || m)
  },
  {
    name: _9_questionEuoWs0tu41Meta?.name ?? "mobile-user-id-cs-guide-owner-9_question",
    path: _9_questionEuoWs0tu41Meta?.path ?? "guide/owner/9_question",
    meta: _9_questionEuoWs0tu41Meta || {},
    alias: _9_questionEuoWs0tu41Meta?.alias || [],
    redirect: _9_questionEuoWs0tu41Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/9_question.vue").then(m => m.default || m)
  },
  {
    name: locationTerms1w4ILiAlSgMeta?.name ?? "mobile-user-id-cs-locationTerms",
    path: locationTerms1w4ILiAlSgMeta?.path ?? "locationTerms",
    meta: locationTerms1w4ILiAlSgMeta || {},
    alias: locationTerms1w4ILiAlSgMeta?.alias || [],
    redirect: locationTerms1w4ILiAlSgMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/locationTerms.vue").then(m => m.default || m)
  },
  {
    name: mainBwRV7ocwx0Meta?.name ?? "mobile-user-id-cs-main",
    path: mainBwRV7ocwx0Meta?.path ?? "main",
    meta: mainBwRV7ocwx0Meta || {},
    alias: mainBwRV7ocwx0Meta?.alias || [],
    redirect: mainBwRV7ocwx0Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/main.vue").then(m => m.default || m)
  },
  {
    name: oldTermsx03oUbgI4SMeta?.name ?? "mobile-user-id-cs-oldTerms",
    path: oldTermsx03oUbgI4SMeta?.path ?? "oldTerms",
    children: [
  {
    name: locationTermsNFqnuSO68IMeta?.name ?? "mobile-user-id-cs-oldTerms-locationTerms",
    path: locationTermsNFqnuSO68IMeta?.path ?? "locationTerms",
    meta: locationTermsNFqnuSO68IMeta || {},
    alias: locationTermsNFqnuSO68IMeta?.alias || [],
    redirect: locationTermsNFqnuSO68IMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/oldTerms/locationTerms.vue").then(m => m.default || m)
  },
  {
    name: personTermsKj0KVvhSI4Meta?.name ?? "mobile-user-id-cs-oldTerms-personTerms",
    path: personTermsKj0KVvhSI4Meta?.path ?? "personTerms",
    children: [
  {
    name: _250314HmjLEtZsaHMeta?.name ?? "mobile-user-id-cs-oldTerms-personTerms-250314",
    path: _250314HmjLEtZsaHMeta?.path ?? "250314",
    meta: _250314HmjLEtZsaHMeta || {},
    alias: _250314HmjLEtZsaHMeta?.alias || [],
    redirect: _250314HmjLEtZsaHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/oldTerms/personTerms/250314.vue").then(m => m.default || m)
  }
],
    meta: personTermsKj0KVvhSI4Meta || {},
    alias: personTermsKj0KVvhSI4Meta?.alias || [],
    redirect: personTermsKj0KVvhSI4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/oldTerms/personTerms.vue").then(m => m.default || m)
  },
  {
    name: useTerms5bdMAuUfzeMeta?.name ?? "mobile-user-id-cs-oldTerms-useTerms",
    path: useTerms5bdMAuUfzeMeta?.path ?? "useTerms",
    children: [
  {
    name: _250314bqRJhOIq9QMeta?.name ?? "mobile-user-id-cs-oldTerms-useTerms-250314",
    path: _250314bqRJhOIq9QMeta?.path ?? "250314",
    meta: _250314bqRJhOIq9QMeta || {},
    alias: _250314bqRJhOIq9QMeta?.alias || [],
    redirect: _250314bqRJhOIq9QMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/oldTerms/useTerms/250314.vue").then(m => m.default || m)
  }
],
    meta: useTerms5bdMAuUfzeMeta || {},
    alias: useTerms5bdMAuUfzeMeta?.alias || [],
    redirect: useTerms5bdMAuUfzeMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/oldTerms/useTerms.vue").then(m => m.default || m)
  }
],
    meta: oldTermsx03oUbgI4SMeta || {},
    alias: oldTermsx03oUbgI4SMeta?.alias || [],
    redirect: oldTermsx03oUbgI4SMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/oldTerms.vue").then(m => m.default || m)
  },
  {
    name: openSource4V9yoLUxaIMeta?.name ?? "mobile-user-id-cs-openSource",
    path: openSource4V9yoLUxaIMeta?.path ?? "openSource",
    meta: openSource4V9yoLUxaIMeta || {},
    alias: openSource4V9yoLUxaIMeta?.alias || [],
    redirect: openSource4V9yoLUxaIMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/openSource.vue").then(m => m.default || m)
  },
  {
    name: personTermscHydBgvvAUMeta?.name ?? "mobile-user-id-cs-personTerms",
    path: personTermscHydBgvvAUMeta?.path ?? "personTerms",
    meta: personTermscHydBgvvAUMeta || {},
    alias: personTermscHydBgvvAUMeta?.alias || [],
    redirect: personTermscHydBgvvAUMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/personTerms.vue").then(m => m.default || m)
  },
  {
    name: questionjtgaDwlqbgMeta?.name ?? "mobile-user-id-cs-question",
    path: questionjtgaDwlqbgMeta?.path ?? "question",
    meta: questionjtgaDwlqbgMeta || {},
    alias: questionjtgaDwlqbgMeta?.alias || [],
    redirect: questionjtgaDwlqbgMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/question.vue").then(m => m.default || m)
  },
  {
    name: useTerms0zWcVOkZkGMeta?.name ?? "mobile-user-id-cs-useTerms",
    path: useTerms0zWcVOkZkGMeta?.path ?? "useTerms",
    meta: useTerms0zWcVOkZkGMeta || {},
    alias: useTerms0zWcVOkZkGMeta?.alias || [],
    redirect: useTerms0zWcVOkZkGMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/useTerms.vue").then(m => m.default || m)
  }
],
    meta: cs328NLeaIRmMeta || {},
    alias: cs328NLeaIRmMeta?.alias || [],
    redirect: cs328NLeaIRmMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs.vue").then(m => m.default || m)
  },
  {
    name: equipList3hettRtgETMeta?.name ?? "mobile-user-id-equipList",
    path: equipList3hettRtgETMeta?.path ?? "equipList",
    meta: equipList3hettRtgETMeta || {},
    alias: equipList3hettRtgETMeta?.alias || [],
    redirect: equipList3hettRtgETMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/equipList.vue").then(m => m.default || m)
  },
  {
    name: equipMapbYmkQUIVAZMeta?.name ?? "mobile-user-id-equipMap",
    path: equipMapbYmkQUIVAZMeta?.path ?? "equipMap",
    meta: equipMapbYmkQUIVAZMeta || {},
    alias: equipMapbYmkQUIVAZMeta?.alias || [],
    redirect: equipMapbYmkQUIVAZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/equipMap.vue").then(m => m.default || m)
  },
  {
    name: etcos9ujWybeqMeta?.name ?? "mobile-user-id-etc",
    path: etcos9ujWybeqMeta?.path ?? "etc",
    children: [
  {
    name: alarm3NkzNUb0FjMeta?.name ?? "mobile-user-id-etc-alarm",
    path: alarm3NkzNUb0FjMeta?.path ?? "alarm",
    children: [
  {
    name: alarmETCfgMfnk0I0lMeta?.name ?? "mobile-user-id-etc-alarm-alarmETC",
    path: alarmETCfgMfnk0I0lMeta?.path ?? "alarmETC",
    meta: alarmETCfgMfnk0I0lMeta || {},
    alias: alarmETCfgMfnk0I0lMeta?.alias || [],
    redirect: alarmETCfgMfnk0I0lMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/alarmETC.vue").then(m => m.default || m)
  },
  {
    name: alarmOBSc57YOW03DwMeta?.name ?? "mobile-user-id-etc-alarm-alarmOBS",
    path: alarmOBSc57YOW03DwMeta?.path ?? "alarmOBS",
    meta: alarmOBSc57YOW03DwMeta || {},
    alias: alarmOBSc57YOW03DwMeta?.alias || [],
    redirect: alarmOBSc57YOW03DwMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/alarmOBS.vue").then(m => m.default || m)
  },
  {
    name: alarmRTUPVuQE1IZebMeta?.name ?? "mobile-user-id-etc-alarm-alarmRTU",
    path: alarmRTUPVuQE1IZebMeta?.path ?? "alarmRTU",
    meta: alarmRTUPVuQE1IZebMeta || {},
    alias: alarmRTUPVuQE1IZebMeta?.alias || [],
    redirect: alarmRTUPVuQE1IZebMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/alarmRTU.vue").then(m => m.default || m)
  },
  {
    name: all2E426qotMzMeta?.name ?? "mobile-user-id-etc-alarm-all",
    path: all2E426qotMzMeta?.path ?? "all",
    meta: all2E426qotMzMeta || {},
    alias: all2E426qotMzMeta?.alias || [],
    redirect: all2E426qotMzMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/all.vue").then(m => m.default || m)
  }
],
    meta: alarm3NkzNUb0FjMeta || {},
    alias: alarm3NkzNUb0FjMeta?.alias || [],
    redirect: alarm3NkzNUb0FjMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm.vue").then(m => m.default || m)
  },
  {
    path: noticeMRq18MDHwKMeta?.path ?? "notice",
    children: [
  {
    name: _91noticeid_93WhgqGoi6hIMeta?.name ?? "mobile-user-id-etc-notice-noticeid",
    path: _91noticeid_93WhgqGoi6hIMeta?.path ?? ":noticeid",
    meta: _91noticeid_93WhgqGoi6hIMeta || {},
    alias: _91noticeid_93WhgqGoi6hIMeta?.alias || [],
    redirect: _91noticeid_93WhgqGoi6hIMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/notice/[noticeid].vue").then(m => m.default || m)
  },
  {
    name: indexSMXYr45LqzMeta?.name ?? "mobile-user-id-etc-notice",
    path: indexSMXYr45LqzMeta?.path ?? "",
    meta: indexSMXYr45LqzMeta || {},
    alias: indexSMXYr45LqzMeta?.alias || [],
    redirect: indexSMXYr45LqzMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/notice/index.vue").then(m => m.default || m)
  }
],
    name: noticeMRq18MDHwKMeta?.name ?? undefined,
    meta: noticeMRq18MDHwKMeta || {},
    alias: noticeMRq18MDHwKMeta?.alias || [],
    redirect: noticeMRq18MDHwKMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/notice.vue").then(m => m.default || m)
  }
],
    meta: etcos9ujWybeqMeta || {},
    alias: etcos9ujWybeqMeta?.alias || [],
    redirect: etcos9ujWybeqMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc.vue").then(m => m.default || m)
  },
  {
    name: indexBCNnKFRZGfMeta?.name ?? "mobile-user-id",
    path: indexBCNnKFRZGfMeta?.path ?? "",
    meta: indexBCNnKFRZGfMeta || {},
    alias: indexBCNnKFRZGfMeta?.alias || [],
    redirect: indexBCNnKFRZGfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: infoonigkWHBcJMeta?.name ?? "mobile-user-id-info",
    path: infoonigkWHBcJMeta?.path ?? "info",
    children: [
  {
    name: contact_45changeJ7ReNmlLCpMeta?.name ?? "mobile-user-id-info-contact-change",
    path: contact_45changeJ7ReNmlLCpMeta?.path ?? "contact-change",
    meta: contact_45changeJ7ReNmlLCpMeta || {},
    alias: contact_45changeJ7ReNmlLCpMeta?.alias || [],
    redirect: contact_45changeJ7ReNmlLCpMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/contact-change.vue").then(m => m.default || m)
  },
  {
    name: main9Ih5phAdp5Meta?.name ?? "mobile-user-id-info-main",
    path: main9Ih5phAdp5Meta?.path ?? "main",
    meta: main9Ih5phAdp5Meta || {},
    alias: main9Ih5phAdp5Meta?.alias || [],
    redirect: main9Ih5phAdp5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/main.vue").then(m => m.default || m)
  },
  {
    name: pw_45changeN3itoPzOR6Meta?.name ?? "mobile-user-id-info-pw-change",
    path: pw_45changeN3itoPzOR6Meta?.path ?? "pw-change",
    meta: pw_45changeN3itoPzOR6Meta || {},
    alias: pw_45changeN3itoPzOR6Meta?.alias || [],
    redirect: pw_45changeN3itoPzOR6Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/pw-change.vue").then(m => m.default || m)
  },
  {
    name: user_45deletelBxD5LHcOPMeta?.name ?? "mobile-user-id-info-user-delete",
    path: user_45deletelBxD5LHcOPMeta?.path ?? "user-delete",
    meta: user_45deletelBxD5LHcOPMeta || {},
    alias: user_45deletelBxD5LHcOPMeta?.alias || [],
    redirect: user_45deletelBxD5LHcOPMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/user-delete.vue").then(m => m.default || m)
  }
],
    meta: infoonigkWHBcJMeta || {},
    alias: infoonigkWHBcJMeta?.alias || [],
    redirect: infoonigkWHBcJMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/info.vue").then(m => m.default || m)
  },
  {
    name: settingHvHjJbn83ZMeta?.name ?? "mobile-user-id-setting",
    path: settingHvHjJbn83ZMeta?.path ?? "setting",
    meta: settingHvHjJbn83ZMeta || {},
    alias: settingHvHjJbn83ZMeta?.alias || [],
    redirect: settingHvHjJbn83ZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/setting.vue").then(m => m.default || m)
  },
  {
    name: siteListiqUdf2KbvyMeta?.name ?? "mobile-user-id-siteList",
    path: siteListiqUdf2KbvyMeta?.path ?? "siteList",
    meta: siteListiqUdf2KbvyMeta || {},
    alias: siteListiqUdf2KbvyMeta?.alias || [],
    redirect: siteListiqUdf2KbvyMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/siteList.vue").then(m => m.default || m)
  },
  {
    name: viewspe6wvp3WOfMeta?.name ?? "mobile-user-id-views",
    path: viewspe6wvp3WOfMeta?.path ?? "views",
    children: [
  {
    name: FailoverEditBsFbx8u9SiMeta?.name ?? "mobile-user-id-views-FailoverEdit",
    path: FailoverEditBsFbx8u9SiMeta?.path ?? "FailoverEdit",
    meta: FailoverEditBsFbx8u9SiMeta || {},
    alias: FailoverEditBsFbx8u9SiMeta?.alias || [],
    redirect: FailoverEditBsFbx8u9SiMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/FailoverEdit.vue").then(m => m.default || m)
  },
  {
    name: equipInfoqmgMkAKFmsMeta?.name ?? "mobile-user-id-views-equipInfo",
    path: equipInfoqmgMkAKFmsMeta?.path ?? "equipInfo",
    children: [
  {
    path: essvxoLhDFApjMeta?.path ?? "company/ess",
    children: [
  {
    path: InstallInfo6jHKjzN0ElMeta?.path ?? "InstallInfo",
    children: [
  {
    name: indexLrkih4nGh2Meta?.name ?? "mobile-user-id-views-equipInfo-company-ess-InstallInfo",
    path: indexLrkih4nGh2Meta?.path ?? "",
    meta: indexLrkih4nGh2Meta || {},
    alias: indexLrkih4nGh2Meta?.alias || [],
    redirect: indexLrkih4nGh2Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstallInfo/index.vue").then(m => m.default || m)
  }
],
    name: InstallInfo6jHKjzN0ElMeta?.name ?? undefined,
    meta: InstallInfo6jHKjzN0ElMeta || {},
    alias: InstallInfo6jHKjzN0ElMeta?.alias || [],
    redirect: InstallInfo6jHKjzN0ElMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfolx98yUQNw8Meta?.name ?? "mobile-user-id-views-equipInfo-company-ess-InstruInfo",
    path: InstruInfolx98yUQNw8Meta?.path ?? "InstruInfo",
    children: [
  {
    name: chartpTzqB8U5jHMeta?.name ?? "mobile-user-id-views-equipInfo-company-ess-InstruInfo-chart",
    path: chartpTzqB8U5jHMeta?.path ?? "chart",
    meta: chartpTzqB8U5jHMeta || {},
    alias: chartpTzqB8U5jHMeta?.alias || [],
    redirect: chartpTzqB8U5jHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowCK9bTerOlTMeta?.name ?? "mobile-user-id-views-equipInfo-company-ess-InstruInfo-now",
    path: nowCK9bTerOlTMeta?.path ?? "now",
    meta: nowCK9bTerOlTMeta || {},
    alias: nowCK9bTerOlTMeta?.alias || [],
    redirect: nowCK9bTerOlTMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfolx98yUQNw8Meta || {},
    alias: InstruInfolx98yUQNw8Meta?.alias || [],
    redirect: InstruInfolx98yUQNw8Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexfN9cT6ichLMeta?.name ?? "mobile-user-id-views-equipInfo-company-ess",
    path: indexfN9cT6ichLMeta?.path ?? "",
    meta: indexfN9cT6ichLMeta || {},
    alias: indexfN9cT6ichLMeta?.alias || [],
    redirect: indexfN9cT6ichLMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/index.vue").then(m => m.default || m)
  }
],
    name: essvxoLhDFApjMeta?.name ?? undefined,
    meta: essvxoLhDFApjMeta || {},
    alias: essvxoLhDFApjMeta?.alias || [],
    redirect: essvxoLhDFApjMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess.vue").then(m => m.default || m)
  },
  {
    path: fuelcell07wYnhRssfMeta?.path ?? "company/fuelcell",
    children: [
  {
    path: InstallInfoJcmYX7595PMeta?.path ?? "InstallInfo",
    children: [
  {
    name: indexFCUpM9sFcVMeta?.name ?? "mobile-user-id-views-equipInfo-company-fuelcell-InstallInfo",
    path: indexFCUpM9sFcVMeta?.path ?? "",
    meta: indexFCUpM9sFcVMeta || {},
    alias: indexFCUpM9sFcVMeta?.alias || [],
    redirect: indexFCUpM9sFcVMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstallInfo/index.vue").then(m => m.default || m)
  }
],
    name: InstallInfoJcmYX7595PMeta?.name ?? undefined,
    meta: InstallInfoJcmYX7595PMeta || {},
    alias: InstallInfoJcmYX7595PMeta?.alias || [],
    redirect: InstallInfoJcmYX7595PMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfofdL6oZEVQZMeta?.name ?? "mobile-user-id-views-equipInfo-company-fuelcell-InstruInfo",
    path: InstruInfofdL6oZEVQZMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartFB3Uag82bgMeta?.name ?? "mobile-user-id-views-equipInfo-company-fuelcell-InstruInfo-chart",
    path: chartFB3Uag82bgMeta?.path ?? "chart",
    meta: chartFB3Uag82bgMeta || {},
    alias: chartFB3Uag82bgMeta?.alias || [],
    redirect: chartFB3Uag82bgMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowBTF4glc7a5Meta?.name ?? "mobile-user-id-views-equipInfo-company-fuelcell-InstruInfo-now",
    path: nowBTF4glc7a5Meta?.path ?? "now",
    meta: nowBTF4glc7a5Meta || {},
    alias: nowBTF4glc7a5Meta?.alias || [],
    redirect: nowBTF4glc7a5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfofdL6oZEVQZMeta || {},
    alias: InstruInfofdL6oZEVQZMeta?.alias || [],
    redirect: InstruInfofdL6oZEVQZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexSzXKG3dzFdMeta?.name ?? "mobile-user-id-views-equipInfo-company-fuelcell",
    path: indexSzXKG3dzFdMeta?.path ?? "",
    meta: indexSzXKG3dzFdMeta || {},
    alias: indexSzXKG3dzFdMeta?.alias || [],
    redirect: indexSzXKG3dzFdMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/index.vue").then(m => m.default || m)
  }
],
    name: fuelcell07wYnhRssfMeta?.name ?? undefined,
    meta: fuelcell07wYnhRssfMeta || {},
    alias: fuelcell07wYnhRssfMeta?.alias || [],
    redirect: fuelcell07wYnhRssfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell.vue").then(m => m.default || m)
  },
  {
    name: geoBkGG3aEpFoMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo",
    path: geoBkGG3aEpFoMeta?.path ?? "company/geo",
    children: [
  {
    name: InstallInfoAMvOvVtu6KMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-InstallInfo",
    path: InstallInfoAMvOvVtu6KMeta?.path ?? "InstallInfo",
    children: [
  {
    name: installah9tf7qvfQMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-InstallInfo-install",
    path: installah9tf7qvfQMeta?.path ?? "install",
    meta: installah9tf7qvfQMeta || {},
    alias: installah9tf7qvfQMeta?.alias || [],
    redirect: installah9tf7qvfQMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstallInfo/install.vue").then(m => m.default || m)
  },
  {
    name: manager7NCKRpA73BMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-InstallInfo-manager",
    path: manager7NCKRpA73BMeta?.path ?? "manager",
    meta: manager7NCKRpA73BMeta || {},
    alias: manager7NCKRpA73BMeta?.alias || [],
    redirect: manager7NCKRpA73BMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstallInfo/manager.vue").then(m => m.default || m)
  }
],
    meta: InstallInfoAMvOvVtu6KMeta || {},
    alias: InstallInfoAMvOvVtu6KMeta?.alias || [],
    redirect: InstallInfoAMvOvVtu6KMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfoOFbQOCGRPuMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-InstruInfo",
    path: InstruInfoOFbQOCGRPuMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chart4Q4i5bHaq7Meta?.name ?? "mobile-user-id-views-equipInfo-company-geo-InstruInfo-chart",
    path: chart4Q4i5bHaq7Meta?.path ?? "chart",
    meta: chart4Q4i5bHaq7Meta || {},
    alias: chart4Q4i5bHaq7Meta?.alias || [],
    redirect: chart4Q4i5bHaq7Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: now2ygj9qIt2FMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-InstruInfo-now",
    path: now2ygj9qIt2FMeta?.path ?? "now",
    meta: now2ygj9qIt2FMeta || {},
    alias: now2ygj9qIt2FMeta?.alias || [],
    redirect: now2ygj9qIt2FMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfoOFbQOCGRPuMeta || {},
    alias: InstruInfoOFbQOCGRPuMeta?.alias || [],
    redirect: InstruInfoOFbQOCGRPuMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: SpecInfo3YG26Jr8e4Meta?.name ?? "mobile-user-id-views-equipInfo-company-geo-SpecInfo",
    path: SpecInfo3YG26Jr8e4Meta?.path ?? "SpecInfo",
    children: [
  {
    name: rtuFWV9qJLG5tMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-SpecInfo-rtu",
    path: rtuFWV9qJLG5tMeta?.path ?? "rtu",
    meta: rtuFWV9qJLG5tMeta || {},
    alias: rtuFWV9qJLG5tMeta?.alias || [],
    redirect: rtuFWV9qJLG5tMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/SpecInfo/rtu.vue").then(m => m.default || m)
  },
  {
    name: speca0EHRySIYIMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-SpecInfo-spec",
    path: speca0EHRySIYIMeta?.path ?? "spec",
    meta: speca0EHRySIYIMeta || {},
    alias: speca0EHRySIYIMeta?.alias || [],
    redirect: speca0EHRySIYIMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/SpecInfo/spec.vue").then(m => m.default || m)
  }
],
    meta: SpecInfo3YG26Jr8e4Meta || {},
    alias: SpecInfo3YG26Jr8e4Meta?.alias || [],
    redirect: SpecInfo3YG26Jr8e4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/SpecInfo.vue").then(m => m.default || m)
  }
],
    meta: geoBkGG3aEpFoMeta || {},
    alias: geoBkGG3aEpFoMeta?.alias || [],
    redirect: geoBkGG3aEpFoMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo.vue").then(m => m.default || m)
  },
  {
    name: pv5qLbe8Ai2wMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv",
    path: pv5qLbe8Ai2wMeta?.path ?? "company/pv",
    children: [
  {
    name: InstallInfoYWYYavj1tfMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-InstallInfo",
    path: InstallInfoYWYYavj1tfMeta?.path ?? "InstallInfo",
    children: [
  {
    name: install3G51XbgQYLMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-InstallInfo-install",
    path: install3G51XbgQYLMeta?.path ?? "install",
    meta: install3G51XbgQYLMeta || {},
    alias: install3G51XbgQYLMeta?.alias || [],
    redirect: install3G51XbgQYLMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstallInfo/install.vue").then(m => m.default || m)
  },
  {
    name: managerPnyrUsPPKNMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-InstallInfo-manager",
    path: managerPnyrUsPPKNMeta?.path ?? "manager",
    meta: managerPnyrUsPPKNMeta || {},
    alias: managerPnyrUsPPKNMeta?.alias || [],
    redirect: managerPnyrUsPPKNMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstallInfo/manager.vue").then(m => m.default || m)
  }
],
    meta: InstallInfoYWYYavj1tfMeta || {},
    alias: InstallInfoYWYYavj1tfMeta?.alias || [],
    redirect: InstallInfoYWYYavj1tfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfoHduMRcFHIcMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-InstruInfo",
    path: InstruInfoHduMRcFHIcMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartJAnV5or0Y5Meta?.name ?? "mobile-user-id-views-equipInfo-company-pv-InstruInfo-chart",
    path: chartJAnV5or0Y5Meta?.path ?? "chart",
    meta: chartJAnV5or0Y5Meta || {},
    alias: chartJAnV5or0Y5Meta?.alias || [],
    redirect: chartJAnV5or0Y5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowZPwN54wA2FMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-InstruInfo-now",
    path: nowZPwN54wA2FMeta?.path ?? "now",
    meta: nowZPwN54wA2FMeta || {},
    alias: nowZPwN54wA2FMeta?.alias || [],
    redirect: nowZPwN54wA2FMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfoHduMRcFHIcMeta || {},
    alias: InstruInfoHduMRcFHIcMeta?.alias || [],
    redirect: InstruInfoHduMRcFHIcMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: SpecInfotwsBdxARj2Meta?.name ?? "mobile-user-id-views-equipInfo-company-pv-SpecInfo",
    path: SpecInfotwsBdxARj2Meta?.path ?? "SpecInfo",
    children: [
  {
    name: rtu8cbAkX3it1Meta?.name ?? "mobile-user-id-views-equipInfo-company-pv-SpecInfo-rtu",
    path: rtu8cbAkX3it1Meta?.path ?? "rtu",
    meta: rtu8cbAkX3it1Meta || {},
    alias: rtu8cbAkX3it1Meta?.alias || [],
    redirect: rtu8cbAkX3it1Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/SpecInfo/rtu.vue").then(m => m.default || m)
  },
  {
    name: speckluILvFTD7Meta?.name ?? "mobile-user-id-views-equipInfo-company-pv-SpecInfo-spec",
    path: speckluILvFTD7Meta?.path ?? "spec",
    meta: speckluILvFTD7Meta || {},
    alias: speckluILvFTD7Meta?.alias || [],
    redirect: speckluILvFTD7Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/SpecInfo/spec.vue").then(m => m.default || m)
  }
],
    meta: SpecInfotwsBdxARj2Meta || {},
    alias: SpecInfotwsBdxARj2Meta?.alias || [],
    redirect: SpecInfotwsBdxARj2Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/SpecInfo.vue").then(m => m.default || m)
  }
],
    meta: pv5qLbe8Ai2wMeta || {},
    alias: pv5qLbe8Ai2wMeta?.alias || [],
    redirect: pv5qLbe8Ai2wMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatkV4R8sYNTJMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat",
    path: solarheatkV4R8sYNTJMeta?.path ?? "company/solarheat",
    children: [
  {
    name: InstallInfodpSlQxE3P1Meta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-InstallInfo",
    path: InstallInfodpSlQxE3P1Meta?.path ?? "InstallInfo",
    children: [
  {
    name: installSCaTzkNIrPMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-InstallInfo-install",
    path: installSCaTzkNIrPMeta?.path ?? "install",
    meta: installSCaTzkNIrPMeta || {},
    alias: installSCaTzkNIrPMeta?.alias || [],
    redirect: installSCaTzkNIrPMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstallInfo/install.vue").then(m => m.default || m)
  },
  {
    name: manageriwH9OApdZ8Meta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-InstallInfo-manager",
    path: manageriwH9OApdZ8Meta?.path ?? "manager",
    meta: manageriwH9OApdZ8Meta || {},
    alias: manageriwH9OApdZ8Meta?.alias || [],
    redirect: manageriwH9OApdZ8Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstallInfo/manager.vue").then(m => m.default || m)
  }
],
    meta: InstallInfodpSlQxE3P1Meta || {},
    alias: InstallInfodpSlQxE3P1Meta?.alias || [],
    redirect: InstallInfodpSlQxE3P1Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfovuAterkACLMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-InstruInfo",
    path: InstruInfovuAterkACLMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartK5MmJxdU5CMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-InstruInfo-chart",
    path: chartK5MmJxdU5CMeta?.path ?? "chart",
    meta: chartK5MmJxdU5CMeta || {},
    alias: chartK5MmJxdU5CMeta?.alias || [],
    redirect: chartK5MmJxdU5CMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowZkG0Jec6g4Meta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-InstruInfo-now",
    path: nowZkG0Jec6g4Meta?.path ?? "now",
    meta: nowZkG0Jec6g4Meta || {},
    alias: nowZkG0Jec6g4Meta?.alias || [],
    redirect: nowZkG0Jec6g4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfovuAterkACLMeta || {},
    alias: InstruInfovuAterkACLMeta?.alias || [],
    redirect: InstruInfovuAterkACLMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: SpecInfoD6AYy9NUMyMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-SpecInfo",
    path: SpecInfoD6AYy9NUMyMeta?.path ?? "SpecInfo",
    children: [
  {
    name: rtuNcBZBbWSJOMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-SpecInfo-rtu",
    path: rtuNcBZBbWSJOMeta?.path ?? "rtu",
    meta: rtuNcBZBbWSJOMeta || {},
    alias: rtuNcBZBbWSJOMeta?.alias || [],
    redirect: rtuNcBZBbWSJOMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/SpecInfo/rtu.vue").then(m => m.default || m)
  },
  {
    name: specZFJvCaOgsRMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-SpecInfo-spec",
    path: specZFJvCaOgsRMeta?.path ?? "spec",
    meta: specZFJvCaOgsRMeta || {},
    alias: specZFJvCaOgsRMeta?.alias || [],
    redirect: specZFJvCaOgsRMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/SpecInfo/spec.vue").then(m => m.default || m)
  }
],
    meta: SpecInfoD6AYy9NUMyMeta || {},
    alias: SpecInfoD6AYy9NUMyMeta?.alias || [],
    redirect: SpecInfoD6AYy9NUMyMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/SpecInfo.vue").then(m => m.default || m)
  }
],
    meta: solarheatkV4R8sYNTJMeta || {},
    alias: solarheatkV4R8sYNTJMeta?.alias || [],
    redirect: solarheatkV4R8sYNTJMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat.vue").then(m => m.default || m)
  },
  {
    path: wfgLFoWqZ1KhMeta?.path ?? "company/wf",
    children: [
  {
    path: InstallInfofutPTkVxIbMeta?.path ?? "InstallInfo",
    children: [
  {
    name: indexsC1CjR9KTvMeta?.name ?? "mobile-user-id-views-equipInfo-company-wf-InstallInfo",
    path: indexsC1CjR9KTvMeta?.path ?? "",
    meta: indexsC1CjR9KTvMeta || {},
    alias: indexsC1CjR9KTvMeta?.alias || [],
    redirect: indexsC1CjR9KTvMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstallInfo/index.vue").then(m => m.default || m)
  }
],
    name: InstallInfofutPTkVxIbMeta?.name ?? undefined,
    meta: InstallInfofutPTkVxIbMeta || {},
    alias: InstallInfofutPTkVxIbMeta?.alias || [],
    redirect: InstallInfofutPTkVxIbMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfoiOJBf2XmrpMeta?.name ?? "mobile-user-id-views-equipInfo-company-wf-InstruInfo",
    path: InstruInfoiOJBf2XmrpMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartzxU2aRJwkvMeta?.name ?? "mobile-user-id-views-equipInfo-company-wf-InstruInfo-chart",
    path: chartzxU2aRJwkvMeta?.path ?? "chart",
    meta: chartzxU2aRJwkvMeta || {},
    alias: chartzxU2aRJwkvMeta?.alias || [],
    redirect: chartzxU2aRJwkvMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowNIAto4AZKrMeta?.name ?? "mobile-user-id-views-equipInfo-company-wf-InstruInfo-now",
    path: nowNIAto4AZKrMeta?.path ?? "now",
    meta: nowNIAto4AZKrMeta || {},
    alias: nowNIAto4AZKrMeta?.alias || [],
    redirect: nowNIAto4AZKrMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfoiOJBf2XmrpMeta || {},
    alias: InstruInfoiOJBf2XmrpMeta?.alias || [],
    redirect: InstruInfoiOJBf2XmrpMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexliLd9GwK8GMeta?.name ?? "mobile-user-id-views-equipInfo-company-wf",
    path: indexliLd9GwK8GMeta?.path ?? "",
    meta: indexliLd9GwK8GMeta || {},
    alias: indexliLd9GwK8GMeta?.alias || [],
    redirect: indexliLd9GwK8GMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/index.vue").then(m => m.default || m)
  }
],
    name: wfgLFoWqZ1KhMeta?.name ?? undefined,
    meta: wfgLFoWqZ1KhMeta || {},
    alias: wfgLFoWqZ1KhMeta?.alias || [],
    redirect: wfgLFoWqZ1KhMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf.vue").then(m => m.default || m)
  },
  {
    path: esskGVtxdBj16Meta?.path ?? "owner/ess",
    children: [
  {
    path: InstallInfodvG3P7iwY4Meta?.path ?? "InstallInfo",
    children: [
  {
    name: indexXTUEUabMdFMeta?.name ?? "mobile-user-id-views-equipInfo-owner-ess-InstallInfo",
    path: indexXTUEUabMdFMeta?.path ?? "",
    meta: indexXTUEUabMdFMeta || {},
    alias: indexXTUEUabMdFMeta?.alias || [],
    redirect: indexXTUEUabMdFMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstallInfo/index.vue").then(m => m.default || m)
  }
],
    name: InstallInfodvG3P7iwY4Meta?.name ?? undefined,
    meta: InstallInfodvG3P7iwY4Meta || {},
    alias: InstallInfodvG3P7iwY4Meta?.alias || [],
    redirect: InstallInfodvG3P7iwY4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfoy5nW8zU8UDMeta?.name ?? "mobile-user-id-views-equipInfo-owner-ess-InstruInfo",
    path: InstruInfoy5nW8zU8UDMeta?.path ?? "InstruInfo",
    children: [
  {
    name: charttCsoPfU93zMeta?.name ?? "mobile-user-id-views-equipInfo-owner-ess-InstruInfo-chart",
    path: charttCsoPfU93zMeta?.path ?? "chart",
    meta: charttCsoPfU93zMeta || {},
    alias: charttCsoPfU93zMeta?.alias || [],
    redirect: charttCsoPfU93zMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowlzEWStAEnIMeta?.name ?? "mobile-user-id-views-equipInfo-owner-ess-InstruInfo-now",
    path: nowlzEWStAEnIMeta?.path ?? "now",
    meta: nowlzEWStAEnIMeta || {},
    alias: nowlzEWStAEnIMeta?.alias || [],
    redirect: nowlzEWStAEnIMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfoy5nW8zU8UDMeta || {},
    alias: InstruInfoy5nW8zU8UDMeta?.alias || [],
    redirect: InstruInfoy5nW8zU8UDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: index1lj0U9UEvnMeta?.name ?? "mobile-user-id-views-equipInfo-owner-ess",
    path: index1lj0U9UEvnMeta?.path ?? "",
    meta: index1lj0U9UEvnMeta || {},
    alias: index1lj0U9UEvnMeta?.alias || [],
    redirect: index1lj0U9UEvnMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/index.vue").then(m => m.default || m)
  }
],
    name: esskGVtxdBj16Meta?.name ?? undefined,
    meta: esskGVtxdBj16Meta || {},
    alias: esskGVtxdBj16Meta?.alias || [],
    redirect: esskGVtxdBj16Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess.vue").then(m => m.default || m)
  },
  {
    path: fuelcellU3VOXjrSE5Meta?.path ?? "owner/fuelcell",
    children: [
  {
    path: InstallInfoiX7iUmkz9XMeta?.path ?? "InstallInfo",
    children: [
  {
    name: indexxafNxXBlG5Meta?.name ?? "mobile-user-id-views-equipInfo-owner-fuelcell-InstallInfo",
    path: indexxafNxXBlG5Meta?.path ?? "",
    meta: indexxafNxXBlG5Meta || {},
    alias: indexxafNxXBlG5Meta?.alias || [],
    redirect: indexxafNxXBlG5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstallInfo/index.vue").then(m => m.default || m)
  }
],
    name: InstallInfoiX7iUmkz9XMeta?.name ?? undefined,
    meta: InstallInfoiX7iUmkz9XMeta || {},
    alias: InstallInfoiX7iUmkz9XMeta?.alias || [],
    redirect: InstallInfoiX7iUmkz9XMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfoF5o4Qmf2cCMeta?.name ?? "mobile-user-id-views-equipInfo-owner-fuelcell-InstruInfo",
    path: InstruInfoF5o4Qmf2cCMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartLGGIH3SUQxMeta?.name ?? "mobile-user-id-views-equipInfo-owner-fuelcell-InstruInfo-chart",
    path: chartLGGIH3SUQxMeta?.path ?? "chart",
    meta: chartLGGIH3SUQxMeta || {},
    alias: chartLGGIH3SUQxMeta?.alias || [],
    redirect: chartLGGIH3SUQxMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowhX8gZVYc9CMeta?.name ?? "mobile-user-id-views-equipInfo-owner-fuelcell-InstruInfo-now",
    path: nowhX8gZVYc9CMeta?.path ?? "now",
    meta: nowhX8gZVYc9CMeta || {},
    alias: nowhX8gZVYc9CMeta?.alias || [],
    redirect: nowhX8gZVYc9CMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfoF5o4Qmf2cCMeta || {},
    alias: InstruInfoF5o4Qmf2cCMeta?.alias || [],
    redirect: InstruInfoF5o4Qmf2cCMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: index6OaFIe8YNSMeta?.name ?? "mobile-user-id-views-equipInfo-owner-fuelcell",
    path: index6OaFIe8YNSMeta?.path ?? "",
    meta: index6OaFIe8YNSMeta || {},
    alias: index6OaFIe8YNSMeta?.alias || [],
    redirect: index6OaFIe8YNSMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/index.vue").then(m => m.default || m)
  }
],
    name: fuelcellU3VOXjrSE5Meta?.name ?? undefined,
    meta: fuelcellU3VOXjrSE5Meta || {},
    alias: fuelcellU3VOXjrSE5Meta?.alias || [],
    redirect: fuelcellU3VOXjrSE5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell.vue").then(m => m.default || m)
  },
  {
    name: geonXHs1nttQmMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo",
    path: geonXHs1nttQmMeta?.path ?? "owner/geo",
    children: [
  {
    name: InstallInfoq8e6wEfwSwMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-InstallInfo",
    path: InstallInfoq8e6wEfwSwMeta?.path ?? "InstallInfo",
    children: [
  {
    name: install3dcdzYvB3uMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-InstallInfo-install",
    path: install3dcdzYvB3uMeta?.path ?? "install",
    meta: install3dcdzYvB3uMeta || {},
    alias: install3dcdzYvB3uMeta?.alias || [],
    redirect: install3dcdzYvB3uMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstallInfo/install.vue").then(m => m.default || m)
  },
  {
    name: managerUpFx8oopOCMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-InstallInfo-manager",
    path: managerUpFx8oopOCMeta?.path ?? "manager",
    meta: managerUpFx8oopOCMeta || {},
    alias: managerUpFx8oopOCMeta?.alias || [],
    redirect: managerUpFx8oopOCMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstallInfo/manager.vue").then(m => m.default || m)
  }
],
    meta: InstallInfoq8e6wEfwSwMeta || {},
    alias: InstallInfoq8e6wEfwSwMeta?.alias || [],
    redirect: InstallInfoq8e6wEfwSwMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfoyAps4yblqyMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-InstruInfo",
    path: InstruInfoyAps4yblqyMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartdqUIllDfGoMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-InstruInfo-chart",
    path: chartdqUIllDfGoMeta?.path ?? "chart",
    meta: chartdqUIllDfGoMeta || {},
    alias: chartdqUIllDfGoMeta?.alias || [],
    redirect: chartdqUIllDfGoMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowuuRHi5Cbl8Meta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-InstruInfo-now",
    path: nowuuRHi5Cbl8Meta?.path ?? "now",
    meta: nowuuRHi5Cbl8Meta || {},
    alias: nowuuRHi5Cbl8Meta?.alias || [],
    redirect: nowuuRHi5Cbl8Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfoyAps4yblqyMeta || {},
    alias: InstruInfoyAps4yblqyMeta?.alias || [],
    redirect: InstruInfoyAps4yblqyMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexv9QLGFhIjmMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-SpecInfo",
    path: indexv9QLGFhIjmMeta?.path ?? "SpecInfo",
    meta: indexv9QLGFhIjmMeta || {},
    alias: indexv9QLGFhIjmMeta?.alias || [],
    redirect: indexv9QLGFhIjmMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/SpecInfo/index.vue").then(m => m.default || m)
  },
  {
    name: rtuFNGj15BRsyMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-SpecInfo-rtu",
    path: rtuFNGj15BRsyMeta?.path ?? "SpecInfo/rtu",
    meta: rtuFNGj15BRsyMeta || {},
    alias: rtuFNGj15BRsyMeta?.alias || [],
    redirect: rtuFNGj15BRsyMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/SpecInfo/rtu.vue").then(m => m.default || m)
  },
  {
    name: specZDEjcel9s0Meta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-SpecInfo-spec",
    path: specZDEjcel9s0Meta?.path ?? "SpecInfo/spec",
    meta: specZDEjcel9s0Meta || {},
    alias: specZDEjcel9s0Meta?.alias || [],
    redirect: specZDEjcel9s0Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/SpecInfo/spec.vue").then(m => m.default || m)
  }
],
    meta: geonXHs1nttQmMeta || {},
    alias: geonXHs1nttQmMeta?.alias || [],
    redirect: geonXHs1nttQmMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo.vue").then(m => m.default || m)
  },
  {
    name: pvybFqoNJyHwMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv",
    path: pvybFqoNJyHwMeta?.path ?? "owner/pv",
    children: [
  {
    name: InstallInfokhisvpGFVBMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-InstallInfo",
    path: InstallInfokhisvpGFVBMeta?.path ?? "InstallInfo",
    children: [
  {
    name: installFvOllJA9UsMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-InstallInfo-install",
    path: installFvOllJA9UsMeta?.path ?? "install",
    meta: installFvOllJA9UsMeta || {},
    alias: installFvOllJA9UsMeta?.alias || [],
    redirect: installFvOllJA9UsMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstallInfo/install.vue").then(m => m.default || m)
  },
  {
    name: managerYznrVXsHv4Meta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-InstallInfo-manager",
    path: managerYznrVXsHv4Meta?.path ?? "manager",
    meta: managerYznrVXsHv4Meta || {},
    alias: managerYznrVXsHv4Meta?.alias || [],
    redirect: managerYznrVXsHv4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstallInfo/manager.vue").then(m => m.default || m)
  }
],
    meta: InstallInfokhisvpGFVBMeta || {},
    alias: InstallInfokhisvpGFVBMeta?.alias || [],
    redirect: InstallInfokhisvpGFVBMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfotVs26OYr1XMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-InstruInfo",
    path: InstruInfotVs26OYr1XMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartKegVtAU57HMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-InstruInfo-chart",
    path: chartKegVtAU57HMeta?.path ?? "chart",
    meta: chartKegVtAU57HMeta || {},
    alias: chartKegVtAU57HMeta?.alias || [],
    redirect: chartKegVtAU57HMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowG7TwmKY5mHMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-InstruInfo-now",
    path: nowG7TwmKY5mHMeta?.path ?? "now",
    meta: nowG7TwmKY5mHMeta || {},
    alias: nowG7TwmKY5mHMeta?.alias || [],
    redirect: nowG7TwmKY5mHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfotVs26OYr1XMeta || {},
    alias: InstruInfotVs26OYr1XMeta?.alias || [],
    redirect: InstruInfotVs26OYr1XMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexUcXVaevefPMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-SpecInfo",
    path: indexUcXVaevefPMeta?.path ?? "SpecInfo",
    meta: indexUcXVaevefPMeta || {},
    alias: indexUcXVaevefPMeta?.alias || [],
    redirect: indexUcXVaevefPMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/SpecInfo/index.vue").then(m => m.default || m)
  },
  {
    name: rtuBo8nHW0KZ3Meta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-SpecInfo-rtu",
    path: rtuBo8nHW0KZ3Meta?.path ?? "SpecInfo/rtu",
    meta: rtuBo8nHW0KZ3Meta || {},
    alias: rtuBo8nHW0KZ3Meta?.alias || [],
    redirect: rtuBo8nHW0KZ3Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/SpecInfo/rtu.vue").then(m => m.default || m)
  },
  {
    name: specvBmaShwXeGMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-SpecInfo-spec",
    path: specvBmaShwXeGMeta?.path ?? "SpecInfo/spec",
    meta: specvBmaShwXeGMeta || {},
    alias: specvBmaShwXeGMeta?.alias || [],
    redirect: specvBmaShwXeGMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/SpecInfo/spec.vue").then(m => m.default || m)
  }
],
    meta: pvybFqoNJyHwMeta || {},
    alias: pvybFqoNJyHwMeta?.alias || [],
    redirect: pvybFqoNJyHwMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatxpRi0G3hA1Meta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat",
    path: solarheatxpRi0G3hA1Meta?.path ?? "owner/solarheat",
    children: [
  {
    name: InstallInfocENLXwYSoWMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-InstallInfo",
    path: InstallInfocENLXwYSoWMeta?.path ?? "InstallInfo",
    children: [
  {
    name: install9xQIH8q7ugMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-InstallInfo-install",
    path: install9xQIH8q7ugMeta?.path ?? "install",
    meta: install9xQIH8q7ugMeta || {},
    alias: install9xQIH8q7ugMeta?.alias || [],
    redirect: install9xQIH8q7ugMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstallInfo/install.vue").then(m => m.default || m)
  },
  {
    name: managerfNeWK7xkeHMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-InstallInfo-manager",
    path: managerfNeWK7xkeHMeta?.path ?? "manager",
    meta: managerfNeWK7xkeHMeta || {},
    alias: managerfNeWK7xkeHMeta?.alias || [],
    redirect: managerfNeWK7xkeHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstallInfo/manager.vue").then(m => m.default || m)
  }
],
    meta: InstallInfocENLXwYSoWMeta || {},
    alias: InstallInfocENLXwYSoWMeta?.alias || [],
    redirect: InstallInfocENLXwYSoWMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstallInfo.vue").then(m => m.default || m)
  },
  {
    path: InstruInfoOqt8CZ0KnOMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartGIKOgYFRtrMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-InstruInfo-chart",
    path: chartGIKOgYFRtrMeta?.path ?? "chart",
    meta: chartGIKOgYFRtrMeta || {},
    alias: chartGIKOgYFRtrMeta?.alias || [],
    redirect: chartGIKOgYFRtrMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: indexxIK1JeuIxRMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-InstruInfo",
    path: indexxIK1JeuIxRMeta?.path ?? "",
    meta: indexxIK1JeuIxRMeta || {},
    alias: indexxIK1JeuIxRMeta?.alias || [],
    redirect: indexxIK1JeuIxRMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo/index.vue").then(m => m.default || m)
  },
  {
    name: nowMrsVsozVl2Meta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-InstruInfo-now",
    path: nowMrsVsozVl2Meta?.path ?? "now",
    meta: nowMrsVsozVl2Meta || {},
    alias: nowMrsVsozVl2Meta?.alias || [],
    redirect: nowMrsVsozVl2Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    name: InstruInfoOqt8CZ0KnOMeta?.name ?? undefined,
    meta: InstruInfoOqt8CZ0KnOMeta || {},
    alias: InstruInfoOqt8CZ0KnOMeta?.alias || [],
    redirect: InstruInfoOqt8CZ0KnOMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexcBEbg5BripMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-SpecInfo",
    path: indexcBEbg5BripMeta?.path ?? "SpecInfo",
    meta: indexcBEbg5BripMeta || {},
    alias: indexcBEbg5BripMeta?.alias || [],
    redirect: indexcBEbg5BripMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/SpecInfo/index.vue").then(m => m.default || m)
  },
  {
    name: rtuIael3Dn3s1Meta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-SpecInfo-rtu",
    path: rtuIael3Dn3s1Meta?.path ?? "SpecInfo/rtu",
    meta: rtuIael3Dn3s1Meta || {},
    alias: rtuIael3Dn3s1Meta?.alias || [],
    redirect: rtuIael3Dn3s1Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/SpecInfo/rtu.vue").then(m => m.default || m)
  },
  {
    name: specAHVBDd39vgMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-SpecInfo-spec",
    path: specAHVBDd39vgMeta?.path ?? "SpecInfo/spec",
    meta: specAHVBDd39vgMeta || {},
    alias: specAHVBDd39vgMeta?.alias || [],
    redirect: specAHVBDd39vgMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/SpecInfo/spec.vue").then(m => m.default || m)
  }
],
    meta: solarheatxpRi0G3hA1Meta || {},
    alias: solarheatxpRi0G3hA1Meta?.alias || [],
    redirect: solarheatxpRi0G3hA1Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat.vue").then(m => m.default || m)
  },
  {
    path: wf1yfY8UK6U8Meta?.path ?? "owner/wf",
    children: [
  {
    path: InstallInfo3X3bfu3wSMMeta?.path ?? "InstallInfo",
    children: [
  {
    name: indexhpJ3BTEAIBMeta?.name ?? "mobile-user-id-views-equipInfo-owner-wf-InstallInfo",
    path: indexhpJ3BTEAIBMeta?.path ?? "",
    meta: indexhpJ3BTEAIBMeta || {},
    alias: indexhpJ3BTEAIBMeta?.alias || [],
    redirect: indexhpJ3BTEAIBMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstallInfo/index.vue").then(m => m.default || m)
  }
],
    name: InstallInfo3X3bfu3wSMMeta?.name ?? undefined,
    meta: InstallInfo3X3bfu3wSMMeta || {},
    alias: InstallInfo3X3bfu3wSMMeta?.alias || [],
    redirect: InstallInfo3X3bfu3wSMMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfoWqOSxnP5FuMeta?.name ?? "mobile-user-id-views-equipInfo-owner-wf-InstruInfo",
    path: InstruInfoWqOSxnP5FuMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartqcW676dSvZMeta?.name ?? "mobile-user-id-views-equipInfo-owner-wf-InstruInfo-chart",
    path: chartqcW676dSvZMeta?.path ?? "chart",
    meta: chartqcW676dSvZMeta || {},
    alias: chartqcW676dSvZMeta?.alias || [],
    redirect: chartqcW676dSvZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowhATuYOltfxMeta?.name ?? "mobile-user-id-views-equipInfo-owner-wf-InstruInfo-now",
    path: nowhATuYOltfxMeta?.path ?? "now",
    meta: nowhATuYOltfxMeta || {},
    alias: nowhATuYOltfxMeta?.alias || [],
    redirect: nowhATuYOltfxMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfoWqOSxnP5FuMeta || {},
    alias: InstruInfoWqOSxnP5FuMeta?.alias || [],
    redirect: InstruInfoWqOSxnP5FuMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexPqXPdGTUVFMeta?.name ?? "mobile-user-id-views-equipInfo-owner-wf",
    path: indexPqXPdGTUVFMeta?.path ?? "",
    meta: indexPqXPdGTUVFMeta || {},
    alias: indexPqXPdGTUVFMeta?.alias || [],
    redirect: indexPqXPdGTUVFMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/index.vue").then(m => m.default || m)
  }
],
    name: wf1yfY8UK6U8Meta?.name ?? undefined,
    meta: wf1yfY8UK6U8Meta || {},
    alias: wf1yfY8UK6U8Meta?.alias || [],
    redirect: wf1yfY8UK6U8Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf.vue").then(m => m.default || m)
  }
],
    meta: equipInfoqmgMkAKFmsMeta || {},
    alias: equipInfoqmgMkAKFmsMeta?.alias || [],
    redirect: equipInfoqmgMkAKFmsMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo.vue").then(m => m.default || m)
  },
  {
    name: eventSXidJ8JwKxMeta?.name ?? "mobile-user-id-views-event",
    path: eventSXidJ8JwKxMeta?.path ?? "event",
    children: [
  {
    name: ALLmyaVREAR9qMeta?.name ?? "mobile-user-id-views-event-ALL",
    path: ALLmyaVREAR9qMeta?.path ?? "ALL",
    meta: ALLmyaVREAR9qMeta || {},
    alias: ALLmyaVREAR9qMeta?.alias || [],
    redirect: ALLmyaVREAR9qMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/ALL.vue").then(m => m.default || m)
  },
  {
    name: FailoverTuzKppv94JMeta?.name ?? "mobile-user-id-views-event-Failover",
    path: FailoverTuzKppv94JMeta?.path ?? "Failover",
    meta: FailoverTuzKppv94JMeta || {},
    alias: FailoverTuzKppv94JMeta?.alias || [],
    redirect: FailoverTuzKppv94JMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/Failover.vue").then(m => m.default || m)
  },
  {
    name: Failuren5l3YaB4HyMeta?.name ?? "mobile-user-id-views-event-Failure",
    path: Failuren5l3YaB4HyMeta?.path ?? "Failure",
    meta: Failuren5l3YaB4HyMeta || {},
    alias: Failuren5l3YaB4HyMeta?.alias || [],
    redirect: Failuren5l3YaB4HyMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/Failure.vue").then(m => m.default || m)
  },
  {
    name: all5HgCdekW0AMeta?.name ?? "mobile-user-id-views-event-all",
    path: all5HgCdekW0AMeta?.path ?? "all",
    meta: all5HgCdekW0AMeta || {},
    alias: all5HgCdekW0AMeta?.alias || [],
    redirect: all5HgCdekW0AMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/all.vue").then(m => m.default || m)
  },
  {
    name: eventHistoryOoz67gAKu4Meta?.name ?? "mobile-user-id-views-event-eventHistory",
    path: eventHistoryOoz67gAKu4Meta?.path ?? "eventHistory",
    meta: eventHistoryOoz67gAKu4Meta || {},
    alias: eventHistoryOoz67gAKu4Meta?.alias || [],
    redirect: eventHistoryOoz67gAKu4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/eventHistory.vue").then(m => m.default || m)
  },
  {
    name: rtuEventrn8W8WsZjbMeta?.name ?? "mobile-user-id-views-event-rtuEvent",
    path: rtuEventrn8W8WsZjbMeta?.path ?? "rtuEvent",
    meta: rtuEventrn8W8WsZjbMeta || {},
    alias: rtuEventrn8W8WsZjbMeta?.alias || [],
    redirect: rtuEventrn8W8WsZjbMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/rtuEvent.vue").then(m => m.default || m)
  }
],
    meta: eventSXidJ8JwKxMeta || {},
    alias: eventSXidJ8JwKxMeta?.alias || [],
    redirect: eventSXidJ8JwKxMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event.vue").then(m => m.default || m)
  },
  {
    name: monitoringu6SDLqnRmAMeta?.name ?? "mobile-user-id-views-monitoring",
    path: monitoringu6SDLqnRmAMeta?.path ?? "monitoring",
    children: [
  {
    name: essstPiNB2B51Meta?.name ?? "mobile-user-id-views-monitoring-ess",
    path: essstPiNB2B51Meta?.path ?? "ess",
    meta: essstPiNB2B51Meta || {},
    alias: essstPiNB2B51Meta?.alias || [],
    redirect: essstPiNB2B51Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/ess.vue").then(m => m.default || m)
  },
  {
    name: fuelcellvfhYjcyiuYMeta?.name ?? "mobile-user-id-views-monitoring-fuelcell",
    path: fuelcellvfhYjcyiuYMeta?.path ?? "fuelcell",
    meta: fuelcellvfhYjcyiuYMeta || {},
    alias: fuelcellvfhYjcyiuYMeta?.alias || [],
    redirect: fuelcellvfhYjcyiuYMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/fuelcell.vue").then(m => m.default || m)
  },
  {
    name: geoIYZM0KVWShMeta?.name ?? "mobile-user-id-views-monitoring-geo",
    path: geoIYZM0KVWShMeta?.path ?? "geo",
    meta: geoIYZM0KVWShMeta || {},
    alias: geoIYZM0KVWShMeta?.alias || [],
    redirect: geoIYZM0KVWShMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/geo.vue").then(m => m.default || m)
  },
  {
    name: pvhrTLuIZkwrMeta?.name ?? "mobile-user-id-views-monitoring-pv",
    path: pvhrTLuIZkwrMeta?.path ?? "pv",
    meta: pvhrTLuIZkwrMeta || {},
    alias: pvhrTLuIZkwrMeta?.alias || [],
    redirect: pvhrTLuIZkwrMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheat6TeJqJnQ5rMeta?.name ?? "mobile-user-id-views-monitoring-solarheat",
    path: solarheat6TeJqJnQ5rMeta?.path ?? "solarheat",
    meta: solarheat6TeJqJnQ5rMeta || {},
    alias: solarheat6TeJqJnQ5rMeta?.alias || [],
    redirect: solarheat6TeJqJnQ5rMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/solarheat.vue").then(m => m.default || m)
  },
  {
    name: wf5lIcSi4FHbMeta?.name ?? "mobile-user-id-views-monitoring-wf",
    path: wf5lIcSi4FHbMeta?.path ?? "wf",
    meta: wf5lIcSi4FHbMeta || {},
    alias: wf5lIcSi4FHbMeta?.alias || [],
    redirect: wf5lIcSi4FHbMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/wf.vue").then(m => m.default || m)
  }
],
    meta: monitoringu6SDLqnRmAMeta || {},
    alias: monitoringu6SDLqnRmAMeta?.alias || [],
    redirect: monitoringu6SDLqnRmAMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring.vue").then(m => m.default || m)
  },
  {
    name: monitoring_test0GpgywrHs4Meta?.name ?? "mobile-user-id-views-monitoring_test",
    path: monitoring_test0GpgywrHs4Meta?.path ?? "monitoring_test",
    meta: monitoring_test0GpgywrHs4Meta || {},
    alias: monitoring_test0GpgywrHs4Meta?.alias || [],
    redirect: monitoring_test0GpgywrHs4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring_test.vue").then(m => m.default || m)
  },
  {
    name: more1Hd7cX5ezqMeta?.name ?? "mobile-user-id-views-more",
    path: more1Hd7cX5ezqMeta?.path ?? "more",
    children: [
  {
    name: InstallInfoEbiJAyLkdTMeta?.name ?? "mobile-user-id-views-more-InstallInfo",
    path: InstallInfoEbiJAyLkdTMeta?.path ?? "InstallInfo",
    meta: InstallInfoEbiJAyLkdTMeta || {},
    alias: InstallInfoEbiJAyLkdTMeta?.alias || [],
    redirect: InstallInfoEbiJAyLkdTMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: contracts9QszzzqpokMeta?.name ?? "mobile-user-id-views-more-contracts",
    path: contracts9QszzzqpokMeta?.path ?? "contracts",
    meta: contracts9QszzzqpokMeta || {},
    alias: contracts9QszzzqpokMeta?.alias || [],
    redirect: contracts9QszzzqpokMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/contracts.vue").then(m => m.default || m)
  },
  {
    name: indicatorsHbqtSdNnsMMeta?.name ?? "mobile-user-id-views-more-indicators",
    path: indicatorsHbqtSdNnsMMeta?.path ?? "indicators",
    children: [
  {
    name: geoSUjwOGwLN8Meta?.name ?? "mobile-user-id-views-more-indicators-geo",
    path: geoSUjwOGwLN8Meta?.path ?? "geo",
    meta: geoSUjwOGwLN8Meta || {},
    alias: geoSUjwOGwLN8Meta?.alias || [],
    redirect: geoSUjwOGwLN8Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators/geo.vue").then(m => m.default || m)
  },
  {
    name: pv8VlU2BHXpCMeta?.name ?? "mobile-user-id-views-more-indicators-pv",
    path: pv8VlU2BHXpCMeta?.path ?? "pv",
    meta: pv8VlU2BHXpCMeta || {},
    alias: pv8VlU2BHXpCMeta?.alias || [],
    redirect: pv8VlU2BHXpCMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatOlnk64GalDMeta?.name ?? "mobile-user-id-views-more-indicators-solarheat",
    path: solarheatOlnk64GalDMeta?.path ?? "solarheat",
    meta: solarheatOlnk64GalDMeta || {},
    alias: solarheatOlnk64GalDMeta?.alias || [],
    redirect: solarheatOlnk64GalDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators/solarheat.vue").then(m => m.default || m)
  }
],
    meta: indicatorsHbqtSdNnsMMeta || {},
    alias: indicatorsHbqtSdNnsMMeta?.alias || [],
    redirect: indicatorsHbqtSdNnsMMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators.vue").then(m => m.default || m)
  },
  {
    name: indexCLN8tRcumdMeta?.name ?? "mobile-user-id-views-more-installerConfirm",
    path: indexCLN8tRcumdMeta?.path ?? "installerConfirm",
    meta: indexCLN8tRcumdMeta || {},
    alias: indexCLN8tRcumdMeta?.alias || [],
    redirect: indexCLN8tRcumdMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/installerConfirm/index.vue").then(m => m.default || m)
  },
  {
    name: installerConfirmr7T9jRy9KSMeta?.name ?? "mobile-user-id-views-more-installerConfirm-installerConfirm",
    path: installerConfirmr7T9jRy9KSMeta?.path ?? "installerConfirm/installerConfirm",
    meta: installerConfirmr7T9jRy9KSMeta || {},
    alias: installerConfirmr7T9jRy9KSMeta?.alias || [],
    redirect: installerConfirmr7T9jRy9KSMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/installerConfirm/installerConfirm.vue").then(m => m.default || m)
  },
  {
    name: installerConfirmDetailOn8aNPuqOSMeta?.name ?? "mobile-user-id-views-more-installerConfirm-installerConfirmDetail",
    path: installerConfirmDetailOn8aNPuqOSMeta?.path ?? "installerConfirm/installerConfirmDetail",
    meta: installerConfirmDetailOn8aNPuqOSMeta || {},
    alias: installerConfirmDetailOn8aNPuqOSMeta?.alias || [],
    redirect: installerConfirmDetailOn8aNPuqOSMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/installerConfirm/installerConfirmDetail.vue").then(m => m.default || m)
  },
  {
    name: installerConfirmRequestMY5FtgOjWBMeta?.name ?? "mobile-user-id-views-more-installerConfirm-installerConfirmRequest",
    path: installerConfirmRequestMY5FtgOjWBMeta?.path ?? "installerConfirm/installerConfirmRequest",
    meta: installerConfirmRequestMY5FtgOjWBMeta || {},
    alias: installerConfirmRequestMY5FtgOjWBMeta?.alias || [],
    redirect: installerConfirmRequestMY5FtgOjWBMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/installerConfirm/installerConfirmRequest.vue").then(m => m.default || m)
  },
  {
    name: menuUJ7KO4IStqMeta?.name ?? "mobile-user-id-views-more-menu",
    path: menuUJ7KO4IStqMeta?.path ?? "menu",
    meta: menuUJ7KO4IStqMeta || {},
    alias: menuUJ7KO4IStqMeta?.alias || [],
    redirect: menuUJ7KO4IStqMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/menu.vue").then(m => m.default || m)
  },
  {
    name: _91noticeid_93uPFhfDgMNKMeta?.name ?? "mobile-user-id-views-more-menuToNotice-noticeid",
    path: _91noticeid_93uPFhfDgMNKMeta?.path ?? "menuToNotice/:noticeid",
    meta: _91noticeid_93uPFhfDgMNKMeta || {},
    alias: _91noticeid_93uPFhfDgMNKMeta?.alias || [],
    redirect: _91noticeid_93uPFhfDgMNKMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/menuToNotice/[noticeid].vue").then(m => m.default || m)
  },
  {
    name: ownerInfobXIeOVbvmNMeta?.name ?? "mobile-user-id-views-more-ownerInfo",
    path: ownerInfobXIeOVbvmNMeta?.path ?? "ownerInfo",
    meta: ownerInfobXIeOVbvmNMeta || {},
    alias: ownerInfobXIeOVbvmNMeta?.alias || [],
    redirect: ownerInfobXIeOVbvmNMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/ownerInfo.vue").then(m => m.default || m)
  },
  {
    name: predictedPowerwUcOv7TLg1Meta?.name ?? "mobile-user-id-views-more-predictedPower",
    path: predictedPowerwUcOv7TLg1Meta?.path ?? "predictedPower",
    meta: predictedPowerwUcOv7TLg1Meta || {},
    alias: predictedPowerwUcOv7TLg1Meta?.alias || [],
    redirect: predictedPowerwUcOv7TLg1Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/predictedPower.vue").then(m => m.default || m)
  },
  {
    name: siteInfoCRTMfU6GaLMeta?.name ?? "mobile-user-id-views-more-siteInfo",
    path: siteInfoCRTMfU6GaLMeta?.path ?? "siteInfo",
    meta: siteInfoCRTMfU6GaLMeta || {},
    alias: siteInfoCRTMfU6GaLMeta?.alias || [],
    redirect: siteInfoCRTMfU6GaLMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/siteInfo.vue").then(m => m.default || m)
  }
],
    meta: more1Hd7cX5ezqMeta || {},
    alias: more1Hd7cX5ezqMeta?.alias || [],
    redirect: more1Hd7cX5ezqMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more.vue").then(m => m.default || m)
  },
  {
    name: powerGenerationr2TtwxD2YyMeta?.name ?? "mobile-user-id-views-powerGeneration",
    path: powerGenerationr2TtwxD2YyMeta?.path ?? "powerGeneration",
    children: [
  {
    name: ess28MEXn0W5DMeta?.name ?? "mobile-user-id-views-powerGeneration-ess",
    path: ess28MEXn0W5DMeta?.path ?? "ess",
    meta: ess28MEXn0W5DMeta || {},
    alias: ess28MEXn0W5DMeta?.alias || [],
    redirect: ess28MEXn0W5DMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/ess.vue").then(m => m.default || m)
  },
  {
    name: fuelcellGK7LTb2R7wMeta?.name ?? "mobile-user-id-views-powerGeneration-fuelcell",
    path: fuelcellGK7LTb2R7wMeta?.path ?? "fuelcell",
    meta: fuelcellGK7LTb2R7wMeta || {},
    alias: fuelcellGK7LTb2R7wMeta?.alias || [],
    redirect: fuelcellGK7LTb2R7wMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/fuelcell.vue").then(m => m.default || m)
  },
  {
    name: geoFY5B5J8tXyMeta?.name ?? "mobile-user-id-views-powerGeneration-geo",
    path: geoFY5B5J8tXyMeta?.path ?? "geo",
    meta: geoFY5B5J8tXyMeta || {},
    alias: geoFY5B5J8tXyMeta?.alias || [],
    redirect: geoFY5B5J8tXyMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/geo.vue").then(m => m.default || m)
  },
  {
    name: pvOcmh1JCISKMeta?.name ?? "mobile-user-id-views-powerGeneration-pv",
    path: pvOcmh1JCISKMeta?.path ?? "pv",
    meta: pvOcmh1JCISKMeta || {},
    alias: pvOcmh1JCISKMeta?.alias || [],
    redirect: pvOcmh1JCISKMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatVJ9WsDlXf4Meta?.name ?? "mobile-user-id-views-powerGeneration-solarheat",
    path: solarheatVJ9WsDlXf4Meta?.path ?? "solarheat",
    meta: solarheatVJ9WsDlXf4Meta || {},
    alias: solarheatVJ9WsDlXf4Meta?.alias || [],
    redirect: solarheatVJ9WsDlXf4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/solarheat.vue").then(m => m.default || m)
  },
  {
    name: wfNXvRa99z1jMeta?.name ?? "mobile-user-id-views-powerGeneration-wf",
    path: wfNXvRa99z1jMeta?.path ?? "wf",
    meta: wfNXvRa99z1jMeta || {},
    alias: wfNXvRa99z1jMeta?.alias || [],
    redirect: wfNXvRa99z1jMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/wf.vue").then(m => m.default || m)
  }
],
    meta: powerGenerationr2TtwxD2YyMeta || {},
    alias: powerGenerationr2TtwxD2YyMeta?.alias || [],
    redirect: powerGenerationr2TtwxD2YyMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration.vue").then(m => m.default || m)
  },
  {
    name: reportVsUsCfjWfHMeta?.name ?? "mobile-user-id-views-report",
    path: reportVsUsCfjWfHMeta?.path ?? "report",
    children: [
  {
    name: geoJmdMkFJEVXMeta?.name ?? "mobile-user-id-views-report-geo",
    path: geoJmdMkFJEVXMeta?.path ?? "geo",
    meta: geoJmdMkFJEVXMeta || {},
    alias: geoJmdMkFJEVXMeta?.alias || [],
    redirect: geoJmdMkFJEVXMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report/geo.vue").then(m => m.default || m)
  },
  {
    name: pvyVA5MhtozXMeta?.name ?? "mobile-user-id-views-report-pv",
    path: pvyVA5MhtozXMeta?.path ?? "pv",
    meta: pvyVA5MhtozXMeta || {},
    alias: pvyVA5MhtozXMeta?.alias || [],
    redirect: pvyVA5MhtozXMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatRykZnjGSmwMeta?.name ?? "mobile-user-id-views-report-solarheat",
    path: solarheatRykZnjGSmwMeta?.path ?? "solarheat",
    meta: solarheatRykZnjGSmwMeta || {},
    alias: solarheatRykZnjGSmwMeta?.alias || [],
    redirect: solarheatRykZnjGSmwMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report/solarheat.vue").then(m => m.default || m)
  }
],
    meta: reportVsUsCfjWfHMeta || {},
    alias: reportVsUsCfjWfHMeta?.alias || [],
    redirect: reportVsUsCfjWfHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report.vue").then(m => m.default || m)
  },
  {
    name: reportPDFjlYOT9ElOHMeta?.name ?? "mobile-user-id-views-reportPDF",
    path: reportPDFjlYOT9ElOHMeta?.path ?? "reportPDF",
    children: [
  {
    name: pvhAzlIIoCsDMeta?.name ?? "mobile-user-id-views-reportPDF-pv",
    path: pvhAzlIIoCsDMeta?.path ?? "pv",
    meta: pvhAzlIIoCsDMeta || {},
    alias: pvhAzlIIoCsDMeta?.alias || [],
    redirect: pvhAzlIIoCsDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/reportPDF/pv.vue").then(m => m.default || m)
  }
],
    meta: reportPDFjlYOT9ElOHMeta || {},
    alias: reportPDFjlYOT9ElOHMeta?.alias || [],
    redirect: reportPDFjlYOT9ElOHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/reportPDF.vue").then(m => m.default || m)
  },
  {
    name: statusWeIrVvUMiFMeta?.name ?? "mobile-user-id-views-status",
    path: statusWeIrVvUMiFMeta?.path ?? "status",
    meta: statusWeIrVvUMiFMeta || {},
    alias: statusWeIrVvUMiFMeta?.alias || [],
    redirect: statusWeIrVvUMiFMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/status.vue").then(m => m.default || m)
  }
],
    meta: viewspe6wvp3WOfMeta || {},
    alias: viewspe6wvp3WOfMeta?.alias || [],
    redirect: viewspe6wvp3WOfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id]/views.vue").then(m => m.default || m)
  }
],
    name: _91id_9382n8D2KWbnMeta?.name ?? undefined,
    meta: _91id_9382n8D2KWbnMeta || {},
    alias: _91id_9382n8D2KWbnMeta?.alias || [],
    redirect: _91id_9382n8D2KWbnMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/[id].vue").then(m => m.default || m)
  },
  {
    name: finduserPzGTOcUy6qMeta?.name ?? "mobile-user-finduser",
    path: finduserPzGTOcUy6qMeta?.path ?? "finduser",
    children: [
  {
    name: doneFindid7L05zJL96FMeta?.name ?? "mobile-user-finduser-doneFindid",
    path: doneFindid7L05zJL96FMeta?.path ?? "doneFindid",
    meta: doneFindid7L05zJL96FMeta || {},
    alias: doneFindid7L05zJL96FMeta?.alias || [],
    redirect: doneFindid7L05zJL96FMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/finduser/doneFindid.vue").then(m => m.default || m)
  },
  {
    name: doneFindpassimKDGeQiEIMeta?.name ?? "mobile-user-finduser-doneFindpass",
    path: doneFindpassimKDGeQiEIMeta?.path ?? "doneFindpass",
    meta: doneFindpassimKDGeQiEIMeta || {},
    alias: doneFindpassimKDGeQiEIMeta?.alias || [],
    redirect: doneFindpassimKDGeQiEIMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/finduser/doneFindpass.vue").then(m => m.default || m)
  },
  {
    name: findidNEsXxzCoUhMeta?.name ?? "mobile-user-finduser-findid",
    path: findidNEsXxzCoUhMeta?.path ?? "findid",
    meta: findidNEsXxzCoUhMeta || {},
    alias: findidNEsXxzCoUhMeta?.alias || [],
    redirect: findidNEsXxzCoUhMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/finduser/findid.vue").then(m => m.default || m)
  },
  {
    name: findpass38NWYa7vUMMeta?.name ?? "mobile-user-finduser-findpass",
    path: findpass38NWYa7vUMMeta?.path ?? "findpass",
    meta: findpass38NWYa7vUMMeta || {},
    alias: findpass38NWYa7vUMMeta?.alias || [],
    redirect: findpass38NWYa7vUMMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/finduser/findpass.vue").then(m => m.default || m)
  }
],
    meta: finduserPzGTOcUy6qMeta || {},
    alias: finduserPzGTOcUy6qMeta?.alias || [],
    redirect: finduserPzGTOcUy6qMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/finduser.vue").then(m => m.default || m)
  },
  {
    name: intropqYNajRUrZMeta?.name ?? "mobile-user-intro",
    path: intropqYNajRUrZMeta?.path ?? "intro",
    meta: intropqYNajRUrZMeta || {},
    alias: intropqYNajRUrZMeta?.alias || [],
    redirect: intropqYNajRUrZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/intro.vue").then(m => m.default || m)
  },
  {
    name: loginvl6JjCDIkOMeta?.name ?? "mobile-user-login",
    path: loginvl6JjCDIkOMeta?.path ?? "login",
    meta: loginvl6JjCDIkOMeta || {},
    alias: loginvl6JjCDIkOMeta?.alias || [],
    redirect: loginvl6JjCDIkOMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/login.vue").then(m => m.default || m)
  },
  {
    name: logoutGKR8XzO2xoMeta?.name ?? "mobile-user-logout",
    path: logoutGKR8XzO2xoMeta?.path ?? "logout",
    meta: logoutGKR8XzO2xoMeta || {},
    alias: logoutGKR8XzO2xoMeta?.alias || [],
    redirect: logoutGKR8XzO2xoMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/logout.vue").then(m => m.default || m)
  },
  {
    name: signinxfdf4lB7LqMeta?.name ?? "mobile-user-signin",
    path: signinxfdf4lB7LqMeta?.path ?? "signin",
    children: [
  {
    name: personTermsMRls5N8spqMeta?.name ?? "mobile-user-signin-personTerms",
    path: personTermsMRls5N8spqMeta?.path ?? "personTerms",
    meta: personTermsMRls5N8spqMeta || {},
    alias: personTermsMRls5N8spqMeta?.alias || [],
    redirect: personTermsMRls5N8spqMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/signin/personTerms.vue").then(m => m.default || m)
  },
  {
    name: termJHudLLWwUNMeta?.name ?? "mobile-user-signin-term",
    path: termJHudLLWwUNMeta?.path ?? "term",
    meta: termJHudLLWwUNMeta || {},
    alias: termJHudLLWwUNMeta?.alias || [],
    redirect: termJHudLLWwUNMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/signin/term.vue").then(m => m.default || m)
  },
  {
    name: useTermslfusohqivGMeta?.name ?? "mobile-user-signin-useTerms",
    path: useTermslfusohqivGMeta?.path ?? "useTerms",
    meta: useTermslfusohqivGMeta || {},
    alias: useTermslfusohqivGMeta?.alias || [],
    redirect: useTermslfusohqivGMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/signin/useTerms.vue").then(m => m.default || m)
  }
],
    meta: signinxfdf4lB7LqMeta || {},
    alias: signinxfdf4lB7LqMeta?.alias || [],
    redirect: signinxfdf4lB7LqMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user/signin.vue").then(m => m.default || m)
  }
],
    meta: userQ2GGffyRnUMeta || {},
    alias: userQ2GGffyRnUMeta?.alias || [],
    redirect: userQ2GGffyRnUMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile/user.vue").then(m => m.default || m)
  }
],
    meta: mobileC5phNFcSf5Meta || {},
    alias: mobileC5phNFcSf5Meta?.alias || [],
    redirect: mobileC5phNFcSf5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/mobile.vue").then(m => m.default || m)
  },
  {
    name: swagger_45uiGt6lpYFNLnMeta?.name ?? "swagger-ui",
    path: swagger_45uiGt6lpYFNLnMeta?.path ?? "/swagger-ui",
    meta: swagger_45uiGt6lpYFNLnMeta || {},
    alias: swagger_45uiGt6lpYFNLnMeta?.alias || [],
    redirect: swagger_45uiGt6lpYFNLnMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/swagger-ui.vue").then(m => m.default || m)
  },
  {
    name: geo9dxP2fGjh1Meta?.name ?? "test-geo",
    path: geo9dxP2fGjh1Meta?.path ?? "/test/geo",
    meta: geo9dxP2fGjh1Meta || {},
    alias: geo9dxP2fGjh1Meta?.alias || [],
    redirect: geo9dxP2fGjh1Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/test/geo.vue").then(m => m.default || m)
  },
  {
    name: loginhERYIKujusMeta?.name ?? "test-login",
    path: loginhERYIKujusMeta?.path ?? "/test/login",
    meta: loginhERYIKujusMeta || {},
    alias: loginhERYIKujusMeta?.alias || [],
    redirect: loginhERYIKujusMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/test/login.vue").then(m => m.default || m)
  },
  {
    name: mainWZBdSkVehuMeta?.name ?? "test-main",
    path: mainWZBdSkVehuMeta?.path ?? "/test/main",
    meta: mainWZBdSkVehuMeta || {},
    alias: mainWZBdSkVehuMeta?.alias || [],
    redirect: mainWZBdSkVehuMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/test/main.vue").then(m => m.default || m)
  },
  {
    name: qrcodePxZu17osFuMeta?.name ?? "test-qrcode",
    path: qrcodePxZu17osFuMeta?.path ?? "/test/qrcode",
    meta: qrcodePxZu17osFuMeta || {},
    alias: qrcodePxZu17osFuMeta?.alias || [],
    redirect: qrcodePxZu17osFuMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/test/qrcode.vue").then(m => m.default || m)
  },
  {
    name: qrtestMLby6UYQK6Meta?.name ?? "test-qrtest",
    path: qrtestMLby6UYQK6Meta?.path ?? "/test/qrtest",
    meta: qrtestMLby6UYQK6Meta || {},
    alias: qrtestMLby6UYQK6Meta?.alias || [],
    redirect: qrtestMLby6UYQK6Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/test/qrtest.vue").then(m => m.default || m)
  },
  {
    name: tocYDOohQW1wLMeta?.name ?? "toc",
    path: tocYDOohQW1wLMeta?.path ?? "/toc",
    children: [
  {
    name: analysiseIyuGLQRTWMeta?.name ?? "toc-analysis",
    path: analysiseIyuGLQRTWMeta?.path ?? "analysis",
    children: [
  {
    name: compare_45ownere0mKqZExHfMeta?.name ?? "toc-analysis-compare-owner",
    path: compare_45ownere0mKqZExHfMeta?.path ?? "compare-owner",
    meta: compare_45ownere0mKqZExHfMeta || {},
    alias: compare_45ownere0mKqZExHfMeta?.alias || [],
    redirect: compare_45ownere0mKqZExHfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/analysis/compare-owner.vue").then(m => m.default || m)
  },
  {
    name: compare_45region4qbBIZnVeHMeta?.name ?? "toc-analysis-compare-region",
    path: compare_45region4qbBIZnVeHMeta?.path ?? "compare-region",
    meta: compare_45region4qbBIZnVeHMeta || {},
    alias: compare_45region4qbBIZnVeHMeta?.alias || [],
    redirect: compare_45region4qbBIZnVeHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/analysis/compare-region.vue").then(m => m.default || m)
  },
  {
    name: geoSKthRD2TKpMeta?.name ?? "toc-analysis-component-geo",
    path: geoSKthRD2TKpMeta?.path ?? "component/geo",
    meta: geoSKthRD2TKpMeta || {},
    alias: geoSKthRD2TKpMeta?.alias || [],
    redirect: geoSKthRD2TKpMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/analysis/component/geo.vue").then(m => m.default || m)
  },
  {
    name: ownerSearchModalc6zyO8Dt0OMeta?.name ?? "toc-analysis-component-ownerSearchModal",
    path: ownerSearchModalc6zyO8Dt0OMeta?.path ?? "component/ownerSearchModal",
    meta: ownerSearchModalc6zyO8Dt0OMeta || {},
    alias: ownerSearchModalc6zyO8Dt0OMeta?.alias || [],
    redirect: ownerSearchModalc6zyO8Dt0OMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/analysis/component/ownerSearchModal.vue").then(m => m.default || m)
  },
  {
    name: pvXfkqTfbIkgMeta?.name ?? "toc-analysis-component-pv",
    path: pvXfkqTfbIkgMeta?.path ?? "component/pv",
    meta: pvXfkqTfbIkgMeta || {},
    alias: pvXfkqTfbIkgMeta?.alias || [],
    redirect: pvXfkqTfbIkgMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/analysis/component/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatzwOF0udeioMeta?.name ?? "toc-analysis-component-solarheat",
    path: solarheatzwOF0udeioMeta?.path ?? "component/solarheat",
    meta: solarheatzwOF0udeioMeta || {},
    alias: solarheatzwOF0udeioMeta?.alias || [],
    redirect: solarheatzwOF0udeioMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/analysis/component/solarheat.vue").then(m => m.default || m)
  },
  {
    name: owner9eDRpCJihQMeta?.name ?? "toc-analysis-owner",
    path: owner9eDRpCJihQMeta?.path ?? "owner",
    meta: owner9eDRpCJihQMeta || {},
    alias: owner9eDRpCJihQMeta?.alias || [],
    redirect: owner9eDRpCJihQMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/analysis/owner.vue").then(m => m.default || m)
  }
],
    meta: analysiseIyuGLQRTWMeta || {},
    alias: analysiseIyuGLQRTWMeta?.alias || [],
    redirect: analysiseIyuGLQRTWMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/analysis.vue").then(m => m.default || m)
  },
  {
    name: Energy_45ListKb0nfRupneMeta?.name ?? "toc-component-Dashboard-Energy-List",
    path: Energy_45ListKb0nfRupneMeta?.path ?? "component/Dashboard/Energy-List",
    meta: Energy_45ListKb0nfRupneMeta || {},
    alias: Energy_45ListKb0nfRupneMeta?.alias || [],
    redirect: Energy_45ListKb0nfRupneMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/Dashboard/Energy-List.vue").then(m => m.default || m)
  },
  {
    name: card_45baseH5mDE7D9FhMeta?.name ?? "toc-component-Dashboard-card-base",
    path: card_45baseH5mDE7D9FhMeta?.path ?? "component/Dashboard/card-base",
    meta: card_45baseH5mDE7D9FhMeta || {},
    alias: card_45baseH5mDE7D9FhMeta?.alias || [],
    redirect: card_45baseH5mDE7D9FhMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/Dashboard/card-base.vue").then(m => m.default || m)
  },
  {
    name: energy_45cardcpqDzZ5cXNMeta?.name ?? "toc-component-Dashboard-energy-card",
    path: energy_45cardcpqDzZ5cXNMeta?.path ?? "component/Dashboard/energy-card",
    meta: energy_45cardcpqDzZ5cXNMeta || {},
    alias: energy_45cardcpqDzZ5cXNMeta?.alias || [],
    redirect: energy_45cardcpqDzZ5cXNMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/Dashboard/energy-card.vue").then(m => m.default || m)
  },
  {
    name: energy_45card2dckVdagq8dMeta?.name ?? "toc-component-Dashboard-energy-card2",
    path: energy_45card2dckVdagq8dMeta?.path ?? "component/Dashboard/energy-card2",
    meta: energy_45card2dckVdagq8dMeta || {},
    alias: energy_45card2dckVdagq8dMeta?.alias || [],
    redirect: energy_45card2dckVdagq8dMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/Dashboard/energy-card2.vue").then(m => m.default || m)
  },
  {
    name: info_45cardjHVYiAdKcqMeta?.name ?? "toc-component-Dashboard-info-card",
    path: info_45cardjHVYiAdKcqMeta?.path ?? "component/Dashboard/info-card",
    meta: info_45cardjHVYiAdKcqMeta || {},
    alias: info_45cardjHVYiAdKcqMeta?.alias || [],
    redirect: info_45cardjHVYiAdKcqMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/Dashboard/info-card.vue").then(m => m.default || m)
  },
  {
    name: link_45rate_45cardw1blvLAtWCMeta?.name ?? "toc-component-Dashboard-link-rate-card",
    path: link_45rate_45cardw1blvLAtWCMeta?.path ?? "component/Dashboard/link-rate-card",
    meta: link_45rate_45cardw1blvLAtWCMeta || {},
    alias: link_45rate_45cardw1blvLAtWCMeta?.alias || [],
    redirect: link_45rate_45cardw1blvLAtWCMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/Dashboard/link-rate-card.vue").then(m => m.default || m)
  },
  {
    name: FileUploadnlbPf75jTyMeta?.name ?? "toc-component-FileUpload",
    path: FileUploadnlbPf75jTyMeta?.path ?? "component/FileUpload",
    meta: FileUploadnlbPf75jTyMeta || {},
    alias: FileUploadnlbPf75jTyMeta?.alias || [],
    redirect: FileUploadnlbPf75jTyMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/FileUpload.vue").then(m => m.default || m)
  },
  {
    name: index0catWmmGhJMeta?.name ?? "toc-component-InstallInfo",
    path: index0catWmmGhJMeta?.path ?? "component/InstallInfo",
    meta: index0catWmmGhJMeta || {},
    alias: index0catWmmGhJMeta?.alias || [],
    redirect: index0catWmmGhJMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/InstallInfo/index.vue").then(m => m.default || m)
  },
  {
    name: pvHExqipAIGmMeta?.name ?? "toc-component-InstallInfo-pv",
    path: pvHExqipAIGmMeta?.path ?? "component/InstallInfo/pv",
    meta: pvHExqipAIGmMeta || {},
    alias: pvHExqipAIGmMeta?.alias || [],
    redirect: pvHExqipAIGmMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/InstallInfo/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheats94edZl7CBMeta?.name ?? "toc-component-InstallInfo-solarheat",
    path: solarheats94edZl7CBMeta?.path ?? "component/InstallInfo/solarheat",
    meta: solarheats94edZl7CBMeta || {},
    alias: solarheats94edZl7CBMeta?.alias || [],
    redirect: solarheats94edZl7CBMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/InstallInfo/solarheat.vue").then(m => m.default || m)
  },
  {
    name: pv8uAAuIANMRMeta?.name ?? "toc-component-InstruInfo-pv",
    path: pv8uAAuIANMRMeta?.path ?? "component/InstruInfo/pv",
    meta: pv8uAAuIANMRMeta || {},
    alias: pv8uAAuIANMRMeta?.alias || [],
    redirect: pv8uAAuIANMRMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/InstruInfo/pv.vue").then(m => m.default || m)
  },
  {
    name: index30e7AE70YBMeta?.name ?? "toc-component-ManagerInfo",
    path: index30e7AE70YBMeta?.path ?? "component/ManagerInfo",
    meta: index30e7AE70YBMeta || {},
    alias: index30e7AE70YBMeta?.alias || [],
    redirect: index30e7AE70YBMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/ManagerInfo/index.vue").then(m => m.default || m)
  },
  {
    name: pvvmUXuLs8T2Meta?.name ?? "toc-component-ManagerInfo-pv",
    path: pvvmUXuLs8T2Meta?.path ?? "component/ManagerInfo/pv",
    meta: pvvmUXuLs8T2Meta || {},
    alias: pvvmUXuLs8T2Meta?.alias || [],
    redirect: pvvmUXuLs8T2Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/ManagerInfo/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatZr3ZtcJh3IMeta?.name ?? "toc-component-ManagerInfo-solarheat",
    path: solarheatZr3ZtcJh3IMeta?.path ?? "component/ManagerInfo/solarheat",
    meta: solarheatZr3ZtcJh3IMeta || {},
    alias: solarheatZr3ZtcJh3IMeta?.alias || [],
    redirect: solarheatZr3ZtcJh3IMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/ManagerInfo/solarheat.vue").then(m => m.default || m)
  },
  {
    name: pvCLoFIXDo9PMeta?.name ?? "toc-component-OwnerInfo-ManagerInfo-pv",
    path: pvCLoFIXDo9PMeta?.path ?? "component/OwnerInfo/ManagerInfo/pv",
    meta: pvCLoFIXDo9PMeta || {},
    alias: pvCLoFIXDo9PMeta?.alias || [],
    redirect: pvCLoFIXDo9PMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/ManagerInfo/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatXiwRqOHt9tMeta?.name ?? "toc-component-OwnerInfo-ManagerInfo-solarheat",
    path: solarheatXiwRqOHt9tMeta?.path ?? "component/OwnerInfo/ManagerInfo/solarheat",
    meta: solarheatXiwRqOHt9tMeta || {},
    alias: solarheatXiwRqOHt9tMeta?.alias || [],
    redirect: solarheatXiwRqOHt9tMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/ManagerInfo/solarheat.vue").then(m => m.default || m)
  },
  {
    name: indextwMoTJCAR7Meta?.name ?? "toc-component-OwnerInfo",
    path: indextwMoTJCAR7Meta?.path ?? "component/OwnerInfo",
    meta: indextwMoTJCAR7Meta || {},
    alias: indextwMoTJCAR7Meta?.alias || [],
    redirect: indextwMoTJCAR7Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/index.vue").then(m => m.default || m)
  },
  {
    name: pvSOCjqOhGnWMeta?.name ?? "toc-component-OwnerInfo-pv",
    path: pvSOCjqOhGnWMeta?.path ?? "component/OwnerInfo/pv",
    meta: pvSOCjqOhGnWMeta || {},
    alias: pvSOCjqOhGnWMeta?.alias || [],
    redirect: pvSOCjqOhGnWMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/pv.vue").then(m => m.default || m)
  },
  {
    name: solarYnq78mqperMeta?.name ?? "toc-component-OwnerInfo-solar",
    path: solarYnq78mqperMeta?.path ?? "component/OwnerInfo/solar",
    meta: solarYnq78mqperMeta || {},
    alias: solarYnq78mqperMeta?.alias || [],
    redirect: solarYnq78mqperMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/solar.vue").then(m => m.default || m)
  },
  {
    name: indexZ7APsd5uifMeta?.name ?? "toc-component-RtuInfo",
    path: indexZ7APsd5uifMeta?.path ?? "component/RtuInfo",
    meta: indexZ7APsd5uifMeta || {},
    alias: indexZ7APsd5uifMeta?.alias || [],
    redirect: indexZ7APsd5uifMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/RtuInfo/index.vue").then(m => m.default || m)
  },
  {
    name: common_45code_45dropdown2Zg2w46GHvMeta?.name ?? "toc-component-SearchBox-common-code-dropdown",
    path: common_45code_45dropdown2Zg2w46GHvMeta?.path ?? "component/SearchBox/common-code-dropdown",
    meta: common_45code_45dropdown2Zg2w46GHvMeta || {},
    alias: common_45code_45dropdown2Zg2w46GHvMeta?.alias || [],
    redirect: common_45code_45dropdown2Zg2w46GHvMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/SearchBox/common-code-dropdown.vue").then(m => m.default || m)
  },
  {
    name: date_45set_45reportgS3uUF8gEJMeta?.name ?? "toc-component-SearchBox-date-set-report",
    path: date_45set_45reportgS3uUF8gEJMeta?.path ?? "component/SearchBox/date-set-report",
    meta: date_45set_45reportgS3uUF8gEJMeta || {},
    alias: date_45set_45reportgS3uUF8gEJMeta?.alias || [],
    redirect: date_45set_45reportgS3uUF8gEJMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/SearchBox/date-set-report.vue").then(m => m.default || m)
  },
  {
    name: date_45set32aERP3ELfMeta?.name ?? "toc-component-SearchBox-date-set",
    path: date_45set32aERP3ELfMeta?.path ?? "component/SearchBox/date-set",
    meta: date_45set32aERP3ELfMeta || {},
    alias: date_45set32aERP3ELfMeta?.alias || [],
    redirect: date_45set32aERP3ELfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/SearchBox/date-set.vue").then(m => m.default || m)
  },
  {
    name: region_45seteQSHzr3ZFJMeta?.name ?? "toc-component-SearchBox-region-set",
    path: region_45seteQSHzr3ZFJMeta?.path ?? "component/SearchBox/region-set",
    meta: region_45seteQSHzr3ZFJMeta || {},
    alias: region_45seteQSHzr3ZFJMeta?.alias || [],
    redirect: region_45seteQSHzr3ZFJMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/SearchBox/region-set.vue").then(m => m.default || m)
  },
  {
    name: geo7XAwVyiSrSMeta?.name ?? "toc-component-SpecInfo-geo",
    path: geo7XAwVyiSrSMeta?.path ?? "component/SpecInfo/geo",
    meta: geo7XAwVyiSrSMeta || {},
    alias: geo7XAwVyiSrSMeta?.alias || [],
    redirect: geo7XAwVyiSrSMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/SpecInfo/geo.vue").then(m => m.default || m)
  },
  {
    name: pvtJ7DjFgSdqMeta?.name ?? "toc-component-SpecInfo-pv",
    path: pvtJ7DjFgSdqMeta?.path ?? "component/SpecInfo/pv",
    meta: pvtJ7DjFgSdqMeta || {},
    alias: pvtJ7DjFgSdqMeta?.alias || [],
    redirect: pvtJ7DjFgSdqMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/SpecInfo/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheat6TRIrinmRXMeta?.name ?? "toc-component-SpecInfo-solarheat",
    path: solarheat6TRIrinmRXMeta?.path ?? "component/SpecInfo/solarheat",
    meta: solarheat6TRIrinmRXMeta || {},
    alias: solarheat6TRIrinmRXMeta?.alias || [],
    redirect: solarheat6TRIrinmRXMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/SpecInfo/solarheat.vue").then(m => m.default || m)
  },
  {
    name: Table_45BoxxFmzBRoEQeMeta?.name ?? "toc-component-Table-Box",
    path: Table_45BoxxFmzBRoEQeMeta?.path ?? "component/Table-Box",
    meta: Table_45BoxxFmzBRoEQeMeta || {},
    alias: Table_45BoxxFmzBRoEQeMeta?.alias || [],
    redirect: Table_45BoxxFmzBRoEQeMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/Table-Box.vue").then(m => m.default || m)
  },
  {
    name: api_45search_45boxwhuhyHms2GMeta?.name ?? "toc-component-api-search-box",
    path: api_45search_45boxwhuhyHms2GMeta?.path ?? "component/api-search-box",
    meta: api_45search_45boxwhuhyHms2GMeta || {},
    alias: api_45search_45boxwhuhyHms2GMeta?.alias || [],
    redirect: api_45search_45boxwhuhyHms2GMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/api-search-box.vue").then(m => m.default || m)
  },
  {
    name: data_45tableuGMWixKZSkMeta?.name ?? "toc-component-data-table",
    path: data_45tableuGMWixKZSkMeta?.path ?? "component/data-table",
    meta: data_45tableuGMWixKZSkMeta || {},
    alias: data_45tableuGMWixKZSkMeta?.alias || [],
    redirect: data_45tableuGMWixKZSkMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/data-table.vue").then(m => m.default || m)
  },
  {
    name: event_45search_45grid1bd0R3CfX6Meta?.name ?? "toc-component-event-search-grid",
    path: event_45search_45grid1bd0R3CfX6Meta?.path ?? "component/event-search-grid",
    meta: event_45search_45grid1bd0R3CfX6Meta || {},
    alias: event_45search_45grid1bd0R3CfX6Meta?.alias || [],
    redirect: event_45search_45grid1bd0R3CfX6Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/event-search-grid.vue").then(m => m.default || m)
  },
  {
    name: info_45boxlPYtbG5BmPMeta?.name ?? "toc-component-info-box",
    path: info_45boxlPYtbG5BmPMeta?.path ?? "component/info-box",
    meta: info_45boxlPYtbG5BmPMeta || {},
    alias: info_45boxlPYtbG5BmPMeta?.alias || [],
    redirect: info_45boxlPYtbG5BmPMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/info-box.vue").then(m => m.default || m)
  },
  {
    name: region_45selectcWhdu2mkpwMeta?.name ?? "toc-component-region-select",
    path: region_45selectcWhdu2mkpwMeta?.path ?? "component/region-select",
    meta: region_45selectcWhdu2mkpwMeta || {},
    alias: region_45selectcWhdu2mkpwMeta?.alias || [],
    redirect: region_45selectcWhdu2mkpwMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/region-select.vue").then(m => m.default || m)
  },
  {
    name: search_45boxq6i2siG9CfMeta?.name ?? "toc-component-search-box",
    path: search_45boxq6i2siG9CfMeta?.path ?? "component/search-box",
    meta: search_45boxq6i2siG9CfMeta || {},
    alias: search_45boxq6i2siG9CfMeta?.alias || [],
    redirect: search_45boxq6i2siG9CfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/search-box.vue").then(m => m.default || m)
  },
  {
    name: table_45pagingej9y3PG55DMeta?.name ?? "toc-component-table-paging",
    path: table_45pagingej9y3PG55DMeta?.path ?? "component/table-paging",
    meta: table_45pagingej9y3PG55DMeta || {},
    alias: table_45pagingej9y3PG55DMeta?.alias || [],
    redirect: table_45pagingej9y3PG55DMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/table-paging.vue").then(m => m.default || m)
  },
  {
    name: toc_45templateXyVKzG9CBtMeta?.name ?? "toc-component-template-toc-template",
    path: toc_45templateXyVKzG9CBtMeta?.path ?? "component/template/toc-template",
    meta: toc_45templateXyVKzG9CBtMeta || {},
    alias: toc_45templateXyVKzG9CBtMeta?.alias || [],
    redirect: toc_45templateXyVKzG9CBtMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/template/toc-template.vue").then(m => m.default || m)
  },
  {
    name: test_45griddWFNOCvegDMeta?.name ?? "toc-component-test-grid",
    path: test_45griddWFNOCvegDMeta?.path ?? "component/test-grid",
    meta: test_45griddWFNOCvegDMeta || {},
    alias: test_45griddWFNOCvegDMeta?.alias || [],
    redirect: test_45griddWFNOCvegDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/component/test-grid.vue").then(m => m.default || m)
  },
  {
    name: customerLdI5xojgyBMeta?.name ?? "toc-customer",
    path: customerLdI5xojgyBMeta?.path ?? "customer",
    children: [
  {
    name: _91id_93Scb4mVHiSsMeta?.name ?? "toc-customer-faq-id",
    path: _91id_93Scb4mVHiSsMeta?.path ?? "faq/:id",
    meta: _91id_93Scb4mVHiSsMeta || {},
    alias: _91id_93Scb4mVHiSsMeta?.alias || [],
    redirect: _91id_93Scb4mVHiSsMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/faq/[id].vue").then(m => m.default || m)
  },
  {
    name: indexmTVz54HXAxMeta?.name ?? "toc-customer-faq",
    path: indexmTVz54HXAxMeta?.path ?? "faq",
    meta: indexmTVz54HXAxMeta || {},
    alias: indexmTVz54HXAxMeta?.alias || [],
    redirect: indexmTVz54HXAxMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/faq/index.vue").then(m => m.default || m)
  },
  {
    name: faq_companyUYgmKj36I6Meta?.name ?? "toc-customer-faq_company",
    path: faq_companyUYgmKj36I6Meta?.path ?? "faq_company",
    meta: faq_companyUYgmKj36I6Meta || {},
    alias: faq_companyUYgmKj36I6Meta?.alias || [],
    redirect: faq_companyUYgmKj36I6Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/faq_company.vue").then(m => m.default || m)
  },
  {
    name: login_45termsOg85xV1MXPMeta?.name ?? "toc-customer-login-terms",
    path: login_45termsOg85xV1MXPMeta?.path ?? "login-terms",
    meta: login_45termsOg85xV1MXPMeta || {},
    alias: login_45termsOg85xV1MXPMeta?.alias || [],
    redirect: login_45termsOg85xV1MXPMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/login-terms.vue").then(m => m.default || m)
  },
  {
    path: noticerroWhTqmTcMeta?.path ?? "notice",
    children: [
  {
    name: _91id_93W5xXCrnMS6Meta?.name ?? "toc-customer-notice-id",
    path: _91id_93W5xXCrnMS6Meta?.path ?? ":id",
    meta: _91id_93W5xXCrnMS6Meta || {},
    alias: _91id_93W5xXCrnMS6Meta?.alias || [],
    redirect: _91id_93W5xXCrnMS6Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: indexwPgqHgYgGmMeta?.name ?? "toc-customer-notice",
    path: indexwPgqHgYgGmMeta?.path ?? "",
    meta: indexwPgqHgYgGmMeta || {},
    alias: indexwPgqHgYgGmMeta?.alias || [],
    redirect: indexwPgqHgYgGmMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/notice/index.vue").then(m => m.default || m)
  }
],
    name: noticerroWhTqmTcMeta?.name ?? undefined,
    meta: noticerroWhTqmTcMeta || {},
    alias: noticerroWhTqmTcMeta?.alias || [],
    redirect: noticerroWhTqmTcMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/notice.vue").then(m => m.default || m)
  },
  {
    path: qnaw3lRppSKZ8Meta?.path ?? "qna",
    children: [
  {
    name: _91id_93TCjwxQ7MEqMeta?.name ?? "toc-customer-qna-id",
    path: _91id_93TCjwxQ7MEqMeta?.path ?? ":id",
    meta: _91id_93TCjwxQ7MEqMeta || {},
    alias: _91id_93TCjwxQ7MEqMeta?.alias || [],
    redirect: _91id_93TCjwxQ7MEqMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/qna/[id].vue").then(m => m.default || m)
  },
  {
    name: add5XhPABBv17Meta?.name ?? "toc-customer-qna-add",
    path: add5XhPABBv17Meta?.path ?? "add",
    meta: add5XhPABBv17Meta || {},
    alias: add5XhPABBv17Meta?.alias || [],
    redirect: add5XhPABBv17Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/qna/add.vue").then(m => m.default || m)
  },
  {
    name: indexv6cLonrqYCMeta?.name ?? "toc-customer-qna",
    path: indexv6cLonrqYCMeta?.path ?? "",
    meta: indexv6cLonrqYCMeta || {},
    alias: indexv6cLonrqYCMeta?.alias || [],
    redirect: indexv6cLonrqYCMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/qna/index.vue").then(m => m.default || m)
  }
],
    name: qnaw3lRppSKZ8Meta?.name ?? undefined,
    meta: qnaw3lRppSKZ8Meta || {},
    alias: qnaw3lRppSKZ8Meta?.alias || [],
    redirect: qnaw3lRppSKZ8Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/qna.vue").then(m => m.default || m)
  },
  {
    path: referenceXkx12jlGkcMeta?.path ?? "reference",
    children: [
  {
    name: _91id_93hg8qgCe0cxMeta?.name ?? "toc-customer-reference-id",
    path: _91id_93hg8qgCe0cxMeta?.path ?? ":id",
    meta: _91id_93hg8qgCe0cxMeta || {},
    alias: _91id_93hg8qgCe0cxMeta?.alias || [],
    redirect: _91id_93hg8qgCe0cxMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/reference/[id].vue").then(m => m.default || m)
  },
  {
    name: index23OSzsfyhvMeta?.name ?? "toc-customer-reference",
    path: index23OSzsfyhvMeta?.path ?? "",
    meta: index23OSzsfyhvMeta || {},
    alias: index23OSzsfyhvMeta?.alias || [],
    redirect: index23OSzsfyhvMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/reference/index.vue").then(m => m.default || m)
  }
],
    name: referenceXkx12jlGkcMeta?.name ?? undefined,
    meta: referenceXkx12jlGkcMeta || {},
    alias: referenceXkx12jlGkcMeta?.alias || [],
    redirect: referenceXkx12jlGkcMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/reference.vue").then(m => m.default || m)
  },
  {
    name: terms16bO7O09xAyMeta?.name ?? "toc-customer-terms-list-terms1",
    path: terms16bO7O09xAyMeta?.path ?? "terms-list/terms1",
    meta: terms16bO7O09xAyMeta || {},
    alias: terms16bO7O09xAyMeta?.alias || [],
    redirect: terms16bO7O09xAyMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/terms-list/terms1.vue").then(m => m.default || m)
  },
  {
    name: terms2RK0G88rOjyMeta?.name ?? "toc-customer-terms-list-terms2",
    path: terms2RK0G88rOjyMeta?.path ?? "terms-list/terms2",
    meta: terms2RK0G88rOjyMeta || {},
    alias: terms2RK0G88rOjyMeta?.alias || [],
    redirect: terms2RK0G88rOjyMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/terms-list/terms2.vue").then(m => m.default || m)
  },
  {
    name: terms1Azp7POQxqMeta?.name ?? "toc-customer-terms",
    path: terms1Azp7POQxqMeta?.path ?? "terms",
    meta: terms1Azp7POQxqMeta || {},
    alias: terms1Azp7POQxqMeta?.alias || [],
    redirect: terms1Azp7POQxqMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer/terms.vue").then(m => m.default || m)
  }
],
    meta: customerLdI5xojgyBMeta || {},
    alias: customerLdI5xojgyBMeta?.alias || [],
    redirect: customerLdI5xojgyBMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/customer.vue").then(m => m.default || m)
  },
  {
    name: equipxdBgeZS5YPMeta?.name ?? "toc-equip",
    path: equipxdBgeZS5YPMeta?.path ?? "equip",
    children: [
  {
    name: equip_45detailJUlE1xlaxLMeta?.name ?? "toc-equip-pathinfo-equip-detail",
    path: equip_45detailJUlE1xlaxLMeta?.path ?? ":pathinfo/equip-detail",
    children: [
  {
    name: _91cid_93usIRYI3jjtMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid",
    path: _91cid_93usIRYI3jjtMeta?.path ?? ":cid",
    children: [
  {
    name: geo_45prod_45cardHTekrpUtwZMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-geo-prod-card",
    path: geo_45prod_45cardHTekrpUtwZMeta?.path ?? "component/geo-prod-card",
    meta: geo_45prod_45cardHTekrpUtwZMeta || {},
    alias: geo_45prod_45cardHTekrpUtwZMeta?.alias || [],
    redirect: geo_45prod_45cardHTekrpUtwZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-prod-card.vue").then(m => m.default || m)
  },
  {
    name: geo_45prod_45chart3rJaRBBxkRMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-geo-prod-chart",
    path: geo_45prod_45chart3rJaRBBxkRMeta?.path ?? "component/geo-prod-chart",
    meta: geo_45prod_45chart3rJaRBBxkRMeta || {},
    alias: geo_45prod_45chart3rJaRBBxkRMeta?.alias || [],
    redirect: geo_45prod_45chart3rJaRBBxkRMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-prod-chart.vue").then(m => m.default || m)
  },
  {
    name: geo_45usage_45card1IvlCgwBlNMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-geo-usage-card",
    path: geo_45usage_45card1IvlCgwBlNMeta?.path ?? "component/geo-usage-card",
    meta: geo_45usage_45card1IvlCgwBlNMeta || {},
    alias: geo_45usage_45card1IvlCgwBlNMeta?.alias || [],
    redirect: geo_45usage_45card1IvlCgwBlNMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-usage-card.vue").then(m => m.default || m)
  },
  {
    name: geo_45usage_45chartJhaAM1hjKtMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-geo-usage-chart",
    path: geo_45usage_45chartJhaAM1hjKtMeta?.path ?? "component/geo-usage-chart",
    meta: geo_45usage_45chartJhaAM1hjKtMeta || {},
    alias: geo_45usage_45chartJhaAM1hjKtMeta?.alias || [],
    redirect: geo_45usage_45chartJhaAM1hjKtMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-usage-chart.vue").then(m => m.default || m)
  },
  {
    name: raw_45data_45chartxYcPE4Cn48Meta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-raw-data-chart",
    path: raw_45data_45chartxYcPE4Cn48Meta?.path ?? "component/raw-data-chart",
    meta: raw_45data_45chartxYcPE4Cn48Meta || {},
    alias: raw_45data_45chartxYcPE4Cn48Meta?.alias || [],
    redirect: raw_45data_45chartxYcPE4Cn48Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/raw-data-chart.vue").then(m => m.default || m)
  },
  {
    name: raw_45data_45infoCKhniqmzY2Meta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-raw-data-info",
    path: raw_45data_45infoCKhniqmzY2Meta?.path ?? "component/raw-data-info",
    meta: raw_45data_45infoCKhniqmzY2Meta || {},
    alias: raw_45data_45infoCKhniqmzY2Meta?.alias || [],
    redirect: raw_45data_45infoCKhniqmzY2Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/raw-data-info.vue").then(m => m.default || m)
  },
  {
    name: solarheat_45prod_45cardChX8WgF1dhMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-solarheat-prod-card",
    path: solarheat_45prod_45cardChX8WgF1dhMeta?.path ?? "component/solarheat-prod-card",
    meta: solarheat_45prod_45cardChX8WgF1dhMeta || {},
    alias: solarheat_45prod_45cardChX8WgF1dhMeta?.alias || [],
    redirect: solarheat_45prod_45cardChX8WgF1dhMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-prod-card.vue").then(m => m.default || m)
  },
  {
    name: solarheat_45prod_45chartRqbCLneJ66Meta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-solarheat-prod-chart",
    path: solarheat_45prod_45chartRqbCLneJ66Meta?.path ?? "component/solarheat-prod-chart",
    meta: solarheat_45prod_45chartRqbCLneJ66Meta || {},
    alias: solarheat_45prod_45chartRqbCLneJ66Meta?.alias || [],
    redirect: solarheat_45prod_45chartRqbCLneJ66Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-prod-chart.vue").then(m => m.default || m)
  },
  {
    name: solarheat_45usage_45carduhN2nwx8zrMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-solarheat-usage-card",
    path: solarheat_45usage_45carduhN2nwx8zrMeta?.path ?? "component/solarheat-usage-card",
    meta: solarheat_45usage_45carduhN2nwx8zrMeta || {},
    alias: solarheat_45usage_45carduhN2nwx8zrMeta?.alias || [],
    redirect: solarheat_45usage_45carduhN2nwx8zrMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-usage-card.vue").then(m => m.default || m)
  },
  {
    name: solarheat_45usage_45chart539gqFY364Meta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-solarheat-usage-chart",
    path: solarheat_45usage_45chart539gqFY364Meta?.path ?? "component/solarheat-usage-chart",
    meta: solarheat_45usage_45chart539gqFY364Meta || {},
    alias: solarheat_45usage_45chart539gqFY364Meta?.alias || [],
    redirect: solarheat_45usage_45chart539gqFY364Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-usage-chart.vue").then(m => m.default || m)
  },
  {
    name: geo_45prodOjJCoLhIpDMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-geo-prod",
    path: geo_45prodOjJCoLhIpDMeta?.path ?? "geo-prod",
    meta: geo_45prodOjJCoLhIpDMeta || {},
    alias: geo_45prodOjJCoLhIpDMeta?.alias || [],
    redirect: geo_45prodOjJCoLhIpDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo-prod.vue").then(m => m.default || m)
  },
  {
    name: geo_45usageuRo1CQK5zTMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-geo-usage",
    path: geo_45usageuRo1CQK5zTMeta?.path ?? "geo-usage",
    meta: geo_45usageuRo1CQK5zTMeta || {},
    alias: geo_45usageuRo1CQK5zTMeta?.alias || [],
    redirect: geo_45usageuRo1CQK5zTMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo-usage.vue").then(m => m.default || m)
  },
  {
    name: geoRmv26WCKQaMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-geo",
    path: geoRmv26WCKQaMeta?.path ?? "geo",
    meta: geoRmv26WCKQaMeta || {},
    alias: geoRmv26WCKQaMeta?.alias || [],
    redirect: geoRmv26WCKQaMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo.vue").then(m => m.default || m)
  },
  {
    name: pvINc1RfOSbGMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-pv",
    path: pvINc1RfOSbGMeta?.path ?? "pv",
    meta: pvINc1RfOSbGMeta || {},
    alias: pvINc1RfOSbGMeta?.alias || [],
    redirect: pvINc1RfOSbGMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatYKop1msxV7Meta?.name ?? "toc-equip-pathinfo-equip-detail-cid-solarheat",
    path: solarheatYKop1msxV7Meta?.path ?? "solarheat",
    meta: solarheatYKop1msxV7Meta || {},
    alias: solarheatYKop1msxV7Meta?.alias || [],
    redirect: solarheatYKop1msxV7Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/solarheat.vue").then(m => m.default || m)
  }
],
    meta: _91cid_93usIRYI3jjtMeta || {},
    alias: _91cid_93usIRYI3jjtMeta?.alias || [],
    redirect: _91cid_93usIRYI3jjtMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid].vue").then(m => m.default || m)
  }
],
    meta: equip_45detailJUlE1xlaxLMeta || {},
    alias: equip_45detailJUlE1xlaxLMeta?.alias || [],
    redirect: equip_45detailJUlE1xlaxLMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail.vue").then(m => m.default || m)
  },
  {
    name: equip_45list5IPsRQeTDEMeta?.name ?? "toc-equip-pathinfo-equip-list",
    path: equip_45list5IPsRQeTDEMeta?.path ?? ":pathinfo/equip-list",
    meta: equip_45list5IPsRQeTDEMeta || {},
    alias: equip_45list5IPsRQeTDEMeta?.alias || [],
    redirect: equip_45list5IPsRQeTDEMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-list.vue").then(m => m.default || m)
  },
  {
    name: equip_45map0qNu7bjznOMeta?.name ?? "toc-equip-pathinfo-equip-map",
    path: equip_45map0qNu7bjznOMeta?.path ?? ":pathinfo/equip-map",
    meta: equip_45map0qNu7bjznOMeta || {},
    alias: equip_45map0qNu7bjznOMeta?.alias || [],
    redirect: equip_45map0qNu7bjznOMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-map.vue").then(m => m.default || m)
  },
  {
    name: measure9eAynuFxd7Meta?.name ?? "toc-equip-pathinfo-measure",
    path: measure9eAynuFxd7Meta?.path ?? ":pathinfo/measure",
    meta: measure9eAynuFxd7Meta || {},
    alias: measure9eAynuFxd7Meta?.alias || [],
    redirect: measure9eAynuFxd7Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/measure.vue").then(m => m.default || m)
  },
  {
    name: monitoring_45mapPvbez8sIIZMeta?.name ?? "toc-equip-pathinfo-monitoring-map",
    path: monitoring_45mapPvbez8sIIZMeta?.path ?? ":pathinfo/monitoring-map",
    meta: monitoring_45mapPvbez8sIIZMeta || {},
    alias: monitoring_45mapPvbez8sIIZMeta?.alias || [],
    redirect: monitoring_45mapPvbez8sIIZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/monitoring-map.vue").then(m => m.default || m)
  },
  {
    path: power_45generateyc3sp04o3NMeta?.path ?? ":pathinfo/power-generate",
    children: [
  {
    name: indexJhbUpN8z3iMeta?.name ?? "toc-equip-pathinfo-power-generate",
    path: indexJhbUpN8z3iMeta?.path ?? "",
    meta: indexJhbUpN8z3iMeta || {},
    alias: indexJhbUpN8z3iMeta?.alias || [],
    redirect: indexJhbUpN8z3iMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/power-generate/index.vue").then(m => m.default || m)
  }
],
    name: power_45generateyc3sp04o3NMeta?.name ?? undefined,
    meta: power_45generateyc3sp04o3NMeta || {},
    alias: power_45generateyc3sp04o3NMeta?.alias || [],
    redirect: power_45generateyc3sp04o3NMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/power-generate.vue").then(m => m.default || m)
  },
  {
    name: rtuErL3UqQg2UMeta?.name ?? "toc-equip-pathinfo-rtu",
    path: rtuErL3UqQg2UMeta?.path ?? ":pathinfo/rtu",
    meta: rtuErL3UqQg2UMeta || {},
    alias: rtuErL3UqQg2UMeta?.alias || [],
    redirect: rtuErL3UqQg2UMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/rtu.vue").then(m => m.default || m)
  },
  {
    path: sitepBvApbNQ09Meta?.path ?? ":pathinfo/site",
    children: [
  {
    name: geo0JQjAA1Eb6Meta?.name ?? "toc-equip-pathinfo-site-newsitename-geo",
    path: geo0JQjAA1Eb6Meta?.path ?? ":newsitename/geo",
    meta: geo0JQjAA1Eb6Meta || {},
    alias: geo0JQjAA1Eb6Meta?.alias || [],
    redirect: geo0JQjAA1Eb6Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[newsitename]/geo.vue").then(m => m.default || m)
  },
  {
    name: pvTW3q7nu06QMeta?.name ?? "toc-equip-pathinfo-site-newsitename-pv",
    path: pvTW3q7nu06QMeta?.path ?? ":newsitename/pv",
    meta: pvTW3q7nu06QMeta || {},
    alias: pvTW3q7nu06QMeta?.alias || [],
    redirect: pvTW3q7nu06QMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[newsitename]/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatNUzEl1GRHZMeta?.name ?? "toc-equip-pathinfo-site-newsitename-solarheat",
    path: solarheatNUzEl1GRHZMeta?.path ?? ":newsitename/solarheat",
    meta: solarheatNUzEl1GRHZMeta || {},
    alias: solarheatNUzEl1GRHZMeta?.alias || [],
    redirect: solarheatNUzEl1GRHZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[newsitename]/solarheat.vue").then(m => m.default || m)
  },
  {
    name: _91sitename_93AT8O9SEt3JMeta?.name ?? "toc-equip-pathinfo-site-sitename",
    path: _91sitename_93AT8O9SEt3JMeta?.path ?? ":sitename",
    children: [
  {
    name: geoWiDmzzD8anMeta?.name ?? "toc-equip-pathinfo-site-sitename-geo",
    path: geoWiDmzzD8anMeta?.path ?? "geo",
    meta: geoWiDmzzD8anMeta || {},
    alias: geoWiDmzzD8anMeta?.alias || [],
    redirect: geoWiDmzzD8anMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename]/geo.vue").then(m => m.default || m)
  },
  {
    name: pv6aITLOARqdMeta?.name ?? "toc-equip-pathinfo-site-sitename-pv",
    path: pv6aITLOARqdMeta?.path ?? "pv",
    meta: pv6aITLOARqdMeta || {},
    alias: pv6aITLOARqdMeta?.alias || [],
    redirect: pv6aITLOARqdMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename]/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheat6VU7SYKQYoMeta?.name ?? "toc-equip-pathinfo-site-sitename-solarheat",
    path: solarheat6VU7SYKQYoMeta?.path ?? "solarheat",
    meta: solarheat6VU7SYKQYoMeta || {},
    alias: solarheat6VU7SYKQYoMeta?.alias || [],
    redirect: solarheat6VU7SYKQYoMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename]/solarheat.vue").then(m => m.default || m)
  }
],
    meta: _91sitename_93AT8O9SEt3JMeta || {},
    alias: _91sitename_93AT8O9SEt3JMeta?.alias || [],
    redirect: _91sitename_93AT8O9SEt3JMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename].vue").then(m => m.default || m)
  },
  {
    name: indexVRF6XkuYN7Meta?.name ?? "toc-equip-pathinfo-site",
    path: indexVRF6XkuYN7Meta?.path ?? "",
    meta: indexVRF6XkuYN7Meta || {},
    alias: indexVRF6XkuYN7Meta?.alias || [],
    redirect: indexVRF6XkuYN7Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/index.vue").then(m => m.default || m)
  },
  {
    name: _91newsitename_93P7x3gGNMmCMeta?.name ?? "toc-equip-pathinfo-site-test-newsitename",
    path: _91newsitename_93P7x3gGNMmCMeta?.path ?? "test/:newsitename",
    meta: _91newsitename_93P7x3gGNMmCMeta || {},
    alias: _91newsitename_93P7x3gGNMmCMeta?.alias || [],
    redirect: _91newsitename_93P7x3gGNMmCMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/test/[newsitename].vue").then(m => m.default || m)
  }
],
    name: sitepBvApbNQ09Meta?.name ?? undefined,
    meta: sitepBvApbNQ09Meta || {},
    alias: sitepBvApbNQ09Meta?.alias || [],
    redirect: sitepBvApbNQ09Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site.vue").then(m => m.default || m)
  }
],
    meta: equipxdBgeZS5YPMeta || {},
    alias: equipxdBgeZS5YPMeta?.alias || [],
    redirect: equipxdBgeZS5YPMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equip.vue").then(m => m.default || m)
  },
  {
    name: equipinfosxgBQRIjwYMeta?.name ?? "toc-equipinfo",
    path: equipinfosxgBQRIjwYMeta?.path ?? "equipinfo",
    meta: equipinfosxgBQRIjwYMeta || {},
    alias: equipinfosxgBQRIjwYMeta?.alias || [],
    redirect: equipinfosxgBQRIjwYMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/equipinfo.vue").then(m => m.default || m)
  },
  {
    name: error6cZOdA7NlsMeta?.name ?? "toc-error",
    path: error6cZOdA7NlsMeta?.path ?? "error",
    meta: error6cZOdA7NlsMeta || {},
    alias: error6cZOdA7NlsMeta?.alias || [],
    redirect: error6cZOdA7NlsMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/error.vue").then(m => m.default || m)
  },
  {
    path: eventTGNnombWZYMeta?.path ?? "event",
    children: [
  {
    name: event_45list9hFEKuPqz4Meta?.name ?? "toc-event-event-list",
    path: event_45list9hFEKuPqz4Meta?.path ?? "event-list",
    children: [
  {
    name: _91eventId_93bdjW5QdxiYMeta?.name ?? "toc-event-event-list-eventId",
    path: _91eventId_93bdjW5QdxiYMeta?.path ?? ":eventId",
    meta: _91eventId_93bdjW5QdxiYMeta || {},
    alias: _91eventId_93bdjW5QdxiYMeta?.alias || [],
    redirect: _91eventId_93bdjW5QdxiYMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/event/event-list/[eventId].vue").then(m => m.default || m)
  }
],
    meta: event_45list9hFEKuPqz4Meta || {},
    alias: event_45list9hFEKuPqz4Meta?.alias || [],
    redirect: event_45list9hFEKuPqz4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/event/event-list.vue").then(m => m.default || m)
  },
  {
    name: indexH0YbqBaCGbMeta?.name ?? "toc-event",
    path: indexH0YbqBaCGbMeta?.path ?? "",
    meta: indexH0YbqBaCGbMeta || {},
    alias: indexH0YbqBaCGbMeta?.alias || [],
    redirect: indexH0YbqBaCGbMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/event/index.vue").then(m => m.default || m)
  }
],
    name: eventTGNnombWZYMeta?.name ?? undefined,
    meta: eventTGNnombWZYMeta || {},
    alias: eventTGNnombWZYMeta?.alias || [],
    redirect: eventTGNnombWZYMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/event.vue").then(m => m.default || m)
  },
  {
    name: installrn9zNpNyVdMeta?.name ?? "toc-install",
    path: installrn9zNpNyVdMeta?.path ?? "install",
    meta: installrn9zNpNyVdMeta || {},
    alias: installrn9zNpNyVdMeta?.alias || [],
    redirect: installrn9zNpNyVdMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/install.vue").then(m => m.default || m)
  },
  {
    name: inventoryGEP1749Fl4Meta?.name ?? "toc-inventory",
    path: inventoryGEP1749Fl4Meta?.path ?? "inventory",
    children: [
  {
    name: addyTFOkw70q3Meta?.name ?? "toc-inventory-equip-company-add",
    path: addyTFOkw70q3Meta?.path ?? "equip-company/add",
    meta: addyTFOkw70q3Meta || {},
    alias: addyTFOkw70q3Meta?.alias || [],
    redirect: addyTFOkw70q3Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/inventory/equip-company/add.vue").then(m => m.default || m)
  },
  {
    name: productfqq2KMufIvMeta?.name ?? "toc-inventory-equip-company-product",
    path: productfqq2KMufIvMeta?.path ?? "equip-company/product",
    meta: productfqq2KMufIvMeta || {},
    alias: productfqq2KMufIvMeta?.alias || [],
    redirect: productfqq2KMufIvMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/inventory/equip-company/product.vue").then(m => m.default || m)
  }
],
    meta: inventoryGEP1749Fl4Meta || {},
    alias: inventoryGEP1749Fl4Meta?.alias || [],
    redirect: inventoryGEP1749Fl4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/inventory.vue").then(m => m.default || m)
  },
  {
    name: maintainenceYkCNoriGMkMeta?.name ?? "toc-maintainence",
    path: maintainenceYkCNoriGMkMeta?.path ?? "maintainence",
    meta: maintainenceYkCNoriGMkMeta || {},
    alias: maintainenceYkCNoriGMkMeta?.alias || [],
    redirect: maintainenceYkCNoriGMkMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/maintainence.vue").then(m => m.default || m)
  },
  {
    path: companycazLMGpueVMeta?.path ?? "management/company",
    children: [
  {
    name: addL6pj7nqV7DMeta?.name ?? "toc-management-company-add",
    path: addL6pj7nqV7DMeta?.path ?? "add",
    meta: addL6pj7nqV7DMeta || {},
    alias: addL6pj7nqV7DMeta?.alias || [],
    redirect: addL6pj7nqV7DMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/company/add.vue").then(m => m.default || m)
  },
  {
    name: indexLYVvGEGkryMeta?.name ?? "toc-management-company",
    path: indexLYVvGEGkryMeta?.path ?? "",
    meta: indexLYVvGEGkryMeta || {},
    alias: indexLYVvGEGkryMeta?.alias || [],
    redirect: indexLYVvGEGkryMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/company/index.vue").then(m => m.default || m)
  }
],
    name: companycazLMGpueVMeta?.name ?? undefined,
    meta: companycazLMGpueVMeta || {},
    alias: companycazLMGpueVMeta?.alias || [],
    redirect: companycazLMGpueVMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/company.vue").then(m => m.default || m)
  },
  {
    name: equip_45modelYouxvUmxxSMeta?.name ?? "toc-management-equip-model",
    path: equip_45modelYouxvUmxxSMeta?.path ?? "management/equip-model",
    meta: equip_45modelYouxvUmxxSMeta || {},
    alias: equip_45modelYouxvUmxxSMeta?.alias || [],
    redirect: equip_45modelYouxvUmxxSMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/equip-model.vue").then(m => m.default || m)
  },
  {
    path: equipN2RlGWCrgvMeta?.path ?? "management/equip",
    children: [
  {
    name: addQXbJKVQGfHMeta?.name ?? "toc-management-equip-add",
    path: addQXbJKVQGfHMeta?.path ?? "add",
    children: [
  {
    name: find_45ownerS1yPARnW0lMeta?.name ?? "toc-management-equip-add-find-owner",
    path: find_45ownerS1yPARnW0lMeta?.path ?? "find-owner",
    meta: find_45ownerS1yPARnW0lMeta || {},
    alias: find_45ownerS1yPARnW0lMeta?.alias || [],
    redirect: find_45ownerS1yPARnW0lMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/equip/add/find-owner.vue").then(m => m.default || m)
  },
  {
    name: geo_45specRwyS4og8hpMeta?.name ?? "toc-management-equip-add-geo-spec",
    path: geo_45specRwyS4og8hpMeta?.path ?? "geo-spec",
    meta: geo_45specRwyS4og8hpMeta || {},
    alias: geo_45specRwyS4og8hpMeta?.alias || [],
    redirect: geo_45specRwyS4og8hpMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/equip/add/geo-spec.vue").then(m => m.default || m)
  },
  {
    name: pv_45specmMBBIWaIqnMeta?.name ?? "toc-management-equip-add-pv-spec",
    path: pv_45specmMBBIWaIqnMeta?.path ?? "pv-spec",
    meta: pv_45specmMBBIWaIqnMeta || {},
    alias: pv_45specmMBBIWaIqnMeta?.alias || [],
    redirect: pv_45specmMBBIWaIqnMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/equip/add/pv-spec.vue").then(m => m.default || m)
  },
  {
    name: solarheat_45spec4ZVk9SVPyLMeta?.name ?? "toc-management-equip-add-solarheat-spec",
    path: solarheat_45spec4ZVk9SVPyLMeta?.path ?? "solarheat-spec",
    meta: solarheat_45spec4ZVk9SVPyLMeta || {},
    alias: solarheat_45spec4ZVk9SVPyLMeta?.alias || [],
    redirect: solarheat_45spec4ZVk9SVPyLMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/equip/add/solarheat-spec.vue").then(m => m.default || m)
  }
],
    meta: addQXbJKVQGfHMeta || {},
    alias: addQXbJKVQGfHMeta?.alias || [],
    redirect: addQXbJKVQGfHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/equip/add.vue").then(m => m.default || m)
  },
  {
    name: indexNDNwS1z8XbMeta?.name ?? "toc-management-equip",
    path: indexNDNwS1z8XbMeta?.path ?? "",
    meta: indexNDNwS1z8XbMeta || {},
    alias: indexNDNwS1z8XbMeta?.alias || [],
    redirect: indexNDNwS1z8XbMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/equip/index.vue").then(m => m.default || m)
  }
],
    name: equipN2RlGWCrgvMeta?.name ?? undefined,
    meta: equipN2RlGWCrgvMeta || {},
    alias: equipN2RlGWCrgvMeta?.alias || [],
    redirect: equipN2RlGWCrgvMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/equip.vue").then(m => m.default || m)
  },
  {
    name: managementqA4kOkt7pjMeta?.name ?? "toc-management-management",
    path: managementqA4kOkt7pjMeta?.path ?? "management/management",
    meta: managementqA4kOkt7pjMeta || {},
    alias: managementqA4kOkt7pjMeta?.alias || [],
    redirect: managementqA4kOkt7pjMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/management.vue").then(m => m.default || m)
  },
  {
    name: indexhrPDaN8qArMeta?.name ?? "toc-management-manager",
    path: indexhrPDaN8qArMeta?.path ?? "management/manager",
    meta: indexhrPDaN8qArMeta || {},
    alias: indexhrPDaN8qArMeta?.alias || [],
    redirect: indexhrPDaN8qArMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/manager/index.vue").then(m => m.default || m)
  },
  {
    name: noticefh0JIMfIAwMeta?.name ?? "toc-management-notice",
    path: noticefh0JIMfIAwMeta?.path ?? "management/notice",
    meta: noticefh0JIMfIAwMeta || {},
    alias: noticefh0JIMfIAwMeta?.alias || [],
    redirect: noticefh0JIMfIAwMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/notice.vue").then(m => m.default || m)
  },
  {
    path: ownerU5HZVWjUzMMeta?.path ?? "management/owner",
    children: [
  {
    name: addSYQgR6ztr3Meta?.name ?? "toc-management-owner-add",
    path: addSYQgR6ztr3Meta?.path ?? "add",
    meta: addSYQgR6ztr3Meta || {},
    alias: addSYQgR6ztr3Meta?.alias || [],
    redirect: addSYQgR6ztr3Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/owner/add.vue").then(m => m.default || m)
  },
  {
    name: indexACUh5ERKVhMeta?.name ?? "toc-management-owner",
    path: indexACUh5ERKVhMeta?.path ?? "",
    meta: indexACUh5ERKVhMeta || {},
    alias: indexACUh5ERKVhMeta?.alias || [],
    redirect: indexACUh5ERKVhMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/owner/index.vue").then(m => m.default || m)
  }
],
    name: ownerU5HZVWjUzMMeta?.name ?? undefined,
    meta: ownerU5HZVWjUzMMeta || {},
    alias: ownerU5HZVWjUzMMeta?.alias || [],
    redirect: ownerU5HZVWjUzMMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/owner.vue").then(m => m.default || m)
  },
  {
    path: permitdIEz6UeXLyMeta?.path ?? "management/permit",
    children: [
  {
    name: indexWmsfCWai42Meta?.name ?? "toc-management-permit",
    path: indexWmsfCWai42Meta?.path ?? "",
    meta: indexWmsfCWai42Meta || {},
    alias: indexWmsfCWai42Meta?.alias || [],
    redirect: indexWmsfCWai42Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/permit/index.vue").then(m => m.default || m)
  }
],
    name: permitdIEz6UeXLyMeta?.name ?? undefined,
    meta: permitdIEz6UeXLyMeta || {},
    alias: permitdIEz6UeXLyMeta?.alias || [],
    redirect: permitdIEz6UeXLyMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/management/permit.vue").then(m => m.default || m)
  },
  {
    name: memberh4S5VokkLWMeta?.name ?? "toc-member",
    path: memberh4S5VokkLWMeta?.path ?? "member",
    children: [
  {
    name: operatorm97brQ6JYpMeta?.name ?? "toc-member-operator",
    path: operatorm97brQ6JYpMeta?.path ?? "operator",
    meta: operatorm97brQ6JYpMeta || {},
    alias: operatorm97brQ6JYpMeta?.alias || [],
    redirect: operatorm97brQ6JYpMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/member/operator.vue").then(m => m.default || m)
  },
  {
    name: _91id_937EKAAXlzo9Meta?.name ?? "toc-member-owner-member-id",
    path: _91id_937EKAAXlzo9Meta?.path ?? "owner-member/:id",
    children: [
  {
    name: geo4oqtxC5s9FMeta?.name ?? "toc-member-owner-member-id-geo",
    path: geo4oqtxC5s9FMeta?.path ?? "geo",
    meta: geo4oqtxC5s9FMeta || {},
    alias: geo4oqtxC5s9FMeta?.alias || [],
    redirect: geo4oqtxC5s9FMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id]/geo.vue").then(m => m.default || m)
  },
  {
    name: pvUqe2e3tK8UMeta?.name ?? "toc-member-owner-member-id-pv",
    path: pvUqe2e3tK8UMeta?.path ?? "pv",
    meta: pvUqe2e3tK8UMeta || {},
    alias: pvUqe2e3tK8UMeta?.alias || [],
    redirect: pvUqe2e3tK8UMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id]/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatWdTlMqfd1HMeta?.name ?? "toc-member-owner-member-id-solarheat",
    path: solarheatWdTlMqfd1HMeta?.path ?? "solarheat",
    meta: solarheatWdTlMqfd1HMeta || {},
    alias: solarheatWdTlMqfd1HMeta?.alias || [],
    redirect: solarheatWdTlMqfd1HMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id]/solarheat.vue").then(m => m.default || m)
  }
],
    meta: _91id_937EKAAXlzo9Meta || {},
    alias: _91id_937EKAAXlzo9Meta?.alias || [],
    redirect: _91id_937EKAAXlzo9Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id].vue").then(m => m.default || m)
  },
  {
    name: owner_45registWAJLlzgsbNMeta?.name ?? "toc-member-owner-member-owner-regist",
    path: owner_45registWAJLlzgsbNMeta?.path ?? "owner-member/owner-regist",
    meta: owner_45registWAJLlzgsbNMeta || {},
    alias: owner_45registWAJLlzgsbNMeta?.alias || [],
    redirect: owner_45registWAJLlzgsbNMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/member/owner-member/owner-regist.vue").then(m => m.default || m)
  },
  {
    name: owner_45searchKUQC8mEoYzMeta?.name ?? "toc-member-owner-member-owner-search",
    path: owner_45searchKUQC8mEoYzMeta?.path ?? "owner-member/owner-search",
    meta: owner_45searchKUQC8mEoYzMeta || {},
    alias: owner_45searchKUQC8mEoYzMeta?.alias || [],
    redirect: owner_45searchKUQC8mEoYzMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/member/owner-member/owner-search.vue").then(m => m.default || m)
  },
  {
    name: ownerzaVRzNd3wQMeta?.name ?? "toc-member-owner",
    path: ownerzaVRzNd3wQMeta?.path ?? "owner",
    meta: ownerzaVRzNd3wQMeta || {},
    alias: ownerzaVRzNd3wQMeta?.alias || [],
    redirect: ownerzaVRzNd3wQMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/member/owner.vue").then(m => m.default || m)
  },
  {
    name: systemux2qfivpVQMeta?.name ?? "toc-member-system",
    path: systemux2qfivpVQMeta?.path ?? "system",
    meta: systemux2qfivpVQMeta || {},
    alias: systemux2qfivpVQMeta?.alias || [],
    redirect: systemux2qfivpVQMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/member/system.vue").then(m => m.default || m)
  }
],
    meta: memberh4S5VokkLWMeta || {},
    alias: memberh4S5VokkLWMeta?.alias || [],
    redirect: memberh4S5VokkLWMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/member.vue").then(m => m.default || m)
  },
  {
    name: messageW3DOZuu0PcMeta?.name ?? "toc-message",
    path: messageW3DOZuu0PcMeta?.path ?? "message",
    children: [
  {
    name: alarm_45sendingabGdPtFZrpMeta?.name ?? "toc-message-alarm-sending",
    path: alarm_45sendingabGdPtFZrpMeta?.path ?? "alarm-sending",
    meta: alarm_45sendingabGdPtFZrpMeta || {},
    alias: alarm_45sendingabGdPtFZrpMeta?.alias || [],
    redirect: alarm_45sendingabGdPtFZrpMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/message/alarm-sending.vue").then(m => m.default || m)
  },
  {
    name: email_45sendingXZYnFx0MIWMeta?.name ?? "toc-message-email-sending",
    path: email_45sendingXZYnFx0MIWMeta?.path ?? "email-sending",
    meta: email_45sendingXZYnFx0MIWMeta || {},
    alias: email_45sendingXZYnFx0MIWMeta?.alias || [],
    redirect: email_45sendingXZYnFx0MIWMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/message/email-sending.vue").then(m => m.default || m)
  }
],
    meta: messageW3DOZuu0PcMeta || {},
    alias: messageW3DOZuu0PcMeta?.alias || [],
    redirect: messageW3DOZuu0PcMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/message.vue").then(m => m.default || m)
  },
  {
    name: monitoringoTfaEprwfpMeta?.name ?? "toc-monitoring",
    path: monitoringoTfaEprwfpMeta?.path ?? "monitoring",
    children: [
  {
    name: comprehensive_45slide5F2cBbLxbfMeta?.name ?? "toc-monitoring-comprehensive-slide",
    path: comprehensive_45slide5F2cBbLxbfMeta?.path ?? "comprehensive-slide",
    meta: comprehensive_45slide5F2cBbLxbfMeta || {},
    alias: comprehensive_45slide5F2cBbLxbfMeta?.alias || [],
    redirect: comprehensive_45slide5F2cBbLxbfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/monitoring/comprehensive-slide.vue").then(m => m.default || m)
  },
  {
    name: dashboard6j2lHCPiyjMeta?.name ?? "toc-monitoring-dashboard",
    path: dashboard6j2lHCPiyjMeta?.path ?? "dashboard",
    meta: dashboard6j2lHCPiyjMeta || {},
    alias: dashboard6j2lHCPiyjMeta?.alias || [],
    redirect: dashboard6j2lHCPiyjMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/monitoring/dashboard.vue").then(m => m.default || m)
  },
  {
    name: equip_dashboardl9WEF69DKAMeta?.name ?? "toc-monitoring-equip_dashboard",
    path: equip_dashboardl9WEF69DKAMeta?.path ?? "equip_dashboard",
    meta: equip_dashboardl9WEF69DKAMeta || {},
    alias: equip_dashboardl9WEF69DKAMeta?.alias || [],
    redirect: equip_dashboardl9WEF69DKAMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/monitoring/equip_dashboard.vue").then(m => m.default || m)
  },
  {
    name: newDashboardZwb3OcqB2HMeta?.name ?? "toc-monitoring-newDashboard",
    path: newDashboardZwb3OcqB2HMeta?.path ?? "newDashboard",
    meta: newDashboardZwb3OcqB2HMeta || {},
    alias: newDashboardZwb3OcqB2HMeta?.alias || [],
    redirect: newDashboardZwb3OcqB2HMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/monitoring/newDashboard.vue").then(m => m.default || m)
  },
  {
    name: prod_45slide7PicKSCFNMMeta?.name ?? "toc-monitoring-prod-slide",
    path: prod_45slide7PicKSCFNMMeta?.path ?? "prod-slide",
    meta: prod_45slide7PicKSCFNMMeta || {},
    alias: prod_45slide7PicKSCFNMMeta?.alias || [],
    redirect: prod_45slide7PicKSCFNMMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/monitoring/prod-slide.vue").then(m => m.default || m)
  },
  {
    name: temp_newDashboardzF1BNErXNYMeta?.name ?? "toc-monitoring-temp_newDashboard",
    path: temp_newDashboardzF1BNErXNYMeta?.path ?? "temp_newDashboard",
    meta: temp_newDashboardzF1BNErXNYMeta || {},
    alias: temp_newDashboardzF1BNErXNYMeta?.alias || [],
    redirect: temp_newDashboardzF1BNErXNYMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/monitoring/temp_newDashboard.vue").then(m => m.default || m)
  }
],
    meta: monitoringoTfaEprwfpMeta || {},
    alias: monitoringoTfaEprwfpMeta?.alias || [],
    redirect: monitoringoTfaEprwfpMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/monitoring.vue").then(m => m.default || m)
  },
  {
    name: not_45foundaWB2Nt3WNUMeta?.name ?? "toc-not-found",
    path: not_45foundaWB2Nt3WNUMeta?.path ?? "not-found",
    meta: not_45foundaWB2Nt3WNUMeta || {},
    alias: not_45foundaWB2Nt3WNUMeta?.alias || [],
    redirect: not_45foundaWB2Nt3WNUMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/not-found.vue").then(m => m.default || m)
  },
  {
    name: operateh5g4gFKFu8Meta?.name ?? "toc-operate",
    path: operateh5g4gFKFu8Meta?.path ?? "operate",
    meta: operateh5g4gFKFu8Meta || {},
    alias: operateh5g4gFKFu8Meta?.alias || [],
    redirect: operateh5g4gFKFu8Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/operate.vue").then(m => m.default || m)
  },
  {
    name: reportgHonmib4dSMeta?.name ?? "toc-report",
    path: reportgHonmib4dSMeta?.path ?? "report",
    children: [
  {
    name: analyze_45efficientHxQv8LLfqmMeta?.name ?? "toc-report-analyze-efficient",
    path: analyze_45efficientHxQv8LLfqmMeta?.path ?? "analyze-efficient",
    children: [
  {
    name: ownersgsK8Oz99eFMeta?.name ?? "toc-report-analyze-efficient-owners",
    path: ownersgsK8Oz99eFMeta?.path ?? "owners",
    meta: ownersgsK8Oz99eFMeta || {},
    alias: ownersgsK8Oz99eFMeta?.alias || [],
    redirect: ownersgsK8Oz99eFMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient/owners.vue").then(m => m.default || m)
  },
  {
    name: prjkindsqyiWPc8LRJMeta?.name ?? "toc-report-analyze-efficient-prjkinds",
    path: prjkindsqyiWPc8LRJMeta?.path ?? "prjkinds",
    meta: prjkindsqyiWPc8LRJMeta || {},
    alias: prjkindsqyiWPc8LRJMeta?.alias || [],
    redirect: prjkindsqyiWPc8LRJMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient/prjkinds.vue").then(m => m.default || m)
  },
  {
    name: regionsgTzssJpPXvMeta?.name ?? "toc-report-analyze-efficient-regions",
    path: regionsgTzssJpPXvMeta?.path ?? "regions",
    meta: regionsgTzssJpPXvMeta || {},
    alias: regionsgTzssJpPXvMeta?.alias || [],
    redirect: regionsgTzssJpPXvMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient/regions.vue").then(m => m.default || m)
  }
],
    meta: analyze_45efficientHxQv8LLfqmMeta || {},
    alias: analyze_45efficientHxQv8LLfqmMeta?.alias || [],
    redirect: analyze_45efficientHxQv8LLfqmMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient.vue").then(m => m.default || m)
  },
  {
    name: analyze_45product2Xurh1hWynMeta?.name ?? "toc-report-analyze-product",
    path: analyze_45product2Xurh1hWynMeta?.path ?? "analyze-product",
    children: [
  {
    name: ownersivZ8YEnBy0Meta?.name ?? "toc-report-analyze-product-owners",
    path: ownersivZ8YEnBy0Meta?.path ?? "owners",
    meta: ownersivZ8YEnBy0Meta || {},
    alias: ownersivZ8YEnBy0Meta?.alias || [],
    redirect: ownersivZ8YEnBy0Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/analyze-product/owners.vue").then(m => m.default || m)
  },
  {
    name: prjkindsy1xtTSNrZOMeta?.name ?? "toc-report-analyze-product-prjkinds",
    path: prjkindsy1xtTSNrZOMeta?.path ?? "prjkinds",
    meta: prjkindsy1xtTSNrZOMeta || {},
    alias: prjkindsy1xtTSNrZOMeta?.alias || [],
    redirect: prjkindsy1xtTSNrZOMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/analyze-product/prjkinds.vue").then(m => m.default || m)
  },
  {
    name: regionssKmJtAKjrxMeta?.name ?? "toc-report-analyze-product-regions",
    path: regionssKmJtAKjrxMeta?.path ?? "regions",
    meta: regionssKmJtAKjrxMeta || {},
    alias: regionssKmJtAKjrxMeta?.alias || [],
    redirect: regionssKmJtAKjrxMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/analyze-product/regions.vue").then(m => m.default || m)
  }
],
    meta: analyze_45product2Xurh1hWynMeta || {},
    alias: analyze_45product2Xurh1hWynMeta?.alias || [],
    redirect: analyze_45product2Xurh1hWynMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/analyze-product.vue").then(m => m.default || m)
  },
  {
    name: env_45factor1asZjtSMd7Meta?.name ?? "toc-report-env-factor",
    path: env_45factor1asZjtSMd7Meta?.path ?? "env-factor",
    meta: env_45factor1asZjtSMd7Meta || {},
    alias: env_45factor1asZjtSMd7Meta?.alias || [],
    redirect: env_45factor1asZjtSMd7Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/env-factor.vue").then(m => m.default || m)
  },
  {
    name: event4c8sALwxrPMeta?.name ?? "toc-report-event",
    path: event4c8sALwxrPMeta?.path ?? "event",
    meta: event4c8sALwxrPMeta || {},
    alias: event4c8sALwxrPMeta?.alias || [],
    redirect: event4c8sALwxrPMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/event.vue").then(m => m.default || m)
  },
  {
    name: predictVeWoFHoTdtMeta?.name ?? "toc-report-predict",
    path: predictVeWoFHoTdtMeta?.path ?? "predict",
    meta: predictVeWoFHoTdtMeta || {},
    alias: predictVeWoFHoTdtMeta?.alias || [],
    redirect: predictVeWoFHoTdtMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/predict.vue").then(m => m.default || m)
  },
  {
    name: productljYkeyQgcZMeta?.name ?? "toc-report-product",
    path: productljYkeyQgcZMeta?.path ?? "product",
    meta: productljYkeyQgcZMeta || {},
    alias: productljYkeyQgcZMeta?.alias || [],
    redirect: productljYkeyQgcZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report/product.vue").then(m => m.default || m)
  }
],
    meta: reportgHonmib4dSMeta || {},
    alias: reportgHonmib4dSMeta?.alias || [],
    redirect: reportgHonmib4dSMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/report.vue").then(m => m.default || m)
  },
  {
    name: service_45infobdDCmdWzGuMeta?.name ?? "toc-service-info",
    path: service_45infobdDCmdWzGuMeta?.path ?? "service-info",
    children: [
  {
    name: app_45versionnGHCzApvuEMeta?.name ?? "toc-service-info-app-version",
    path: app_45versionnGHCzApvuEMeta?.path ?? "app-version",
    meta: app_45versionnGHCzApvuEMeta || {},
    alias: app_45versionnGHCzApvuEMeta?.alias || [],
    redirect: app_45versionnGHCzApvuEMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/service-info/app-version.vue").then(m => m.default || m)
  }
],
    meta: service_45infobdDCmdWzGuMeta || {},
    alias: service_45infobdDCmdWzGuMeta?.alias || [],
    redirect: service_45infobdDCmdWzGuMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/service-info.vue").then(m => m.default || m)
  },
  {
    name: testqj7t5VVLcnMeta?.name ?? "toc-test",
    path: testqj7t5VVLcnMeta?.path ?? "test",
    meta: testqj7t5VVLcnMeta || {},
    alias: testqj7t5VVLcnMeta?.alias || [],
    redirect: testqj7t5VVLcnMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/test.vue").then(m => m.default || m)
  },
  {
    name: user_45servicewpOfCxmWWTMeta?.name ?? "toc-user-service",
    path: user_45servicewpOfCxmWWTMeta?.path ?? "user-service",
    children: [
  {
    name: manager_45infoTbWDPChZ1IMeta?.name ?? "toc-user-service-manager-info",
    path: manager_45infoTbWDPChZ1IMeta?.path ?? "manager-info",
    meta: manager_45infoTbWDPChZ1IMeta || {},
    alias: manager_45infoTbWDPChZ1IMeta?.alias || [],
    redirect: manager_45infoTbWDPChZ1IMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/manager-info.vue").then(m => m.default || m)
  },
  {
    name: confirm_45modalJbiXsdnZcjMeta?.name ?? "toc-user-service-modal-confirm-modal",
    path: confirm_45modalJbiXsdnZcjMeta?.path ?? "modal/confirm-modal",
    meta: confirm_45modalJbiXsdnZcjMeta || {},
    alias: confirm_45modalJbiXsdnZcjMeta?.alias || [],
    redirect: confirm_45modalJbiXsdnZcjMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/modal/confirm-modal.vue").then(m => m.default || m)
  },
  {
    name: manager_45add_45modalvQzH26Sh8AMeta?.name ?? "toc-user-service-modal-manager-add-modal",
    path: manager_45add_45modalvQzH26Sh8AMeta?.path ?? "modal/manager-add-modal",
    meta: manager_45add_45modalvQzH26Sh8AMeta || {},
    alias: manager_45add_45modalvQzH26Sh8AMeta?.alias || [],
    redirect: manager_45add_45modalvQzH26Sh8AMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/modal/manager-add-modal.vue").then(m => m.default || m)
  },
  {
    name: manager_45modify_45modalbFrMZTHUGnMeta?.name ?? "toc-user-service-modal-manager-modify-modal",
    path: manager_45modify_45modalbFrMZTHUGnMeta?.path ?? "modal/manager-modify-modal",
    meta: manager_45modify_45modalbFrMZTHUGnMeta || {},
    alias: manager_45modify_45modalbFrMZTHUGnMeta?.alias || [],
    redirect: manager_45modify_45modalbFrMZTHUGnMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/modal/manager-modify-modal.vue").then(m => m.default || m)
  },
  {
    name: set_45manager_45all_45modalguvMPNhGxAMeta?.name ?? "toc-user-service-modal-set-manager-all-modal",
    path: set_45manager_45all_45modalguvMPNhGxAMeta?.path ?? "modal/set-manager-all-modal",
    meta: set_45manager_45all_45modalguvMPNhGxAMeta || {},
    alias: set_45manager_45all_45modalguvMPNhGxAMeta?.alias || [],
    redirect: set_45manager_45all_45modalguvMPNhGxAMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/modal/set-manager-all-modal.vue").then(m => m.default || m)
  },
  {
    name: set_45manager_45select_45modalS5XRWdxSucMeta?.name ?? "toc-user-service-modal-set-manager-select-modal",
    path: set_45manager_45select_45modalS5XRWdxSucMeta?.path ?? "modal/set-manager-select-modal",
    meta: set_45manager_45select_45modalS5XRWdxSucMeta || {},
    alias: set_45manager_45select_45modalS5XRWdxSucMeta?.alias || [],
    redirect: set_45manager_45select_45modalS5XRWdxSucMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/modal/set-manager-select-modal.vue").then(m => m.default || m)
  },
  {
    name: set_45managerG78tZENVZKMeta?.name ?? "toc-user-service-set-manager",
    path: set_45managerG78tZENVZKMeta?.path ?? "set-manager",
    meta: set_45managerG78tZENVZKMeta || {},
    alias: set_45managerG78tZENVZKMeta?.alias || [],
    redirect: set_45managerG78tZENVZKMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/set-manager.vue").then(m => m.default || m)
  },
  {
    path: user_45as_45managementtQtrenodYBMeta?.path ?? "user-as-management",
    children: [
  {
    name: indexeLBkymWUFoMeta?.name ?? "toc-user-service-user-as-management",
    path: indexeLBkymWUFoMeta?.path ?? "",
    meta: indexeLBkymWUFoMeta || {},
    alias: indexeLBkymWUFoMeta?.alias || [],
    redirect: indexeLBkymWUFoMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/user-as-management/index.vue").then(m => m.default || m)
  },
  {
    name: infoa3YAGevWqHMeta?.name ?? "toc-user-service-user-as-management-info",
    path: infoa3YAGevWqHMeta?.path ?? "info",
    meta: infoa3YAGevWqHMeta || {},
    alias: infoa3YAGevWqHMeta?.alias || [],
    redirect: infoa3YAGevWqHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/user-as-management/info.vue").then(m => m.default || m)
  }
],
    name: user_45as_45managementtQtrenodYBMeta?.name ?? undefined,
    meta: user_45as_45managementtQtrenodYBMeta || {},
    alias: user_45as_45managementtQtrenodYBMeta?.alias || [],
    redirect: user_45as_45managementtQtrenodYBMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/user-as-management.vue").then(m => m.default || m)
  },
  {
    path: user_45as_45requesthgbOywvUB6Meta?.path ?? "user-as-request",
    children: [
  {
    name: indexwreLD1VCRuMeta?.name ?? "toc-user-service-user-as-request",
    path: indexwreLD1VCRuMeta?.path ?? "",
    meta: indexwreLD1VCRuMeta || {},
    alias: indexwreLD1VCRuMeta?.alias || [],
    redirect: indexwreLD1VCRuMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/user-as-request/index.vue").then(m => m.default || m)
  },
  {
    name: infoxPN7VngmV7Meta?.name ?? "toc-user-service-user-as-request-info",
    path: infoxPN7VngmV7Meta?.path ?? "info",
    meta: infoxPN7VngmV7Meta || {},
    alias: infoxPN7VngmV7Meta?.alias || [],
    redirect: infoxPN7VngmV7Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/user-as-request/info.vue").then(m => m.default || m)
  }
],
    name: user_45as_45requesthgbOywvUB6Meta?.name ?? undefined,
    meta: user_45as_45requesthgbOywvUB6Meta || {},
    alias: user_45as_45requesthgbOywvUB6Meta?.alias || [],
    redirect: user_45as_45requesthgbOywvUB6Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/user-as-request.vue").then(m => m.default || m)
  },
  {
    name: user_45infobKri70uBqvMeta?.name ?? "toc-user-service-user-info",
    path: user_45infobKri70uBqvMeta?.path ?? "user-info",
    meta: user_45infobKri70uBqvMeta || {},
    alias: user_45infobKri70uBqvMeta?.alias || [],
    redirect: user_45infobKri70uBqvMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/user-info.vue").then(m => m.default || m)
  },
  {
    name: user_45settingKJGdUu0rSBMeta?.name ?? "toc-user-service-user-setting",
    path: user_45settingKJGdUu0rSBMeta?.path ?? "user-setting",
    meta: user_45settingKJGdUu0rSBMeta || {},
    alias: user_45settingKJGdUu0rSBMeta?.alias || [],
    redirect: user_45settingKJGdUu0rSBMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service/user-setting.vue").then(m => m.default || m)
  }
],
    meta: user_45servicewpOfCxmWWTMeta || {},
    alias: user_45servicewpOfCxmWWTMeta?.alias || [],
    redirect: user_45servicewpOfCxmWWTMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user-service.vue").then(m => m.default || m)
  },
  {
    name: find_45id8t01s3Nqf7Meta?.name ?? "toc-user-find-id",
    path: find_45id8t01s3Nqf7Meta?.path ?? "user/find-id",
    meta: find_45id8t01s3Nqf7Meta || {},
    alias: find_45id8t01s3Nqf7Meta?.alias || [],
    redirect: find_45id8t01s3Nqf7Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user/find-id.vue").then(m => m.default || m)
  },
  {
    name: indexNtfpJRXKkVMeta?.name ?? "toc-user",
    path: indexNtfpJRXKkVMeta?.path ?? "user",
    meta: indexNtfpJRXKkVMeta || {},
    alias: indexNtfpJRXKkVMeta?.alias || [],
    redirect: indexNtfpJRXKkVMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user/index.vue").then(m => m.default || m)
  },
  {
    name: login9gYaMVyk7ZMeta?.name ?? "toc-user-login",
    path: login9gYaMVyk7ZMeta?.path ?? "user/login",
    meta: login9gYaMVyk7ZMeta || {},
    alias: login9gYaMVyk7ZMeta?.alias || [],
    redirect: login9gYaMVyk7ZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc/user/login.vue").then(m => m.default || m)
  }
],
    meta: tocYDOohQW1wLMeta || {},
    alias: tocYDOohQW1wLMeta?.alias || [],
    redirect: tocYDOohQW1wLMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/install/kie-rems-mweb/src/client/pages/toc.vue").then(m => m.default || m)
  }
]